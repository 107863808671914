import _ from "lodash"
import { updateExcludeContactsWithNoConfirmedName, updateExcludeContactsWithNoEmail, updateExcludeMailed, updateIsCompanyChecked, updateIsContactChecked, updateIsProjectChecked } from "./reducer"

export function getSelectFieldsValue(fields, checkMandatory = true) {
  let options = []
  fields?.map(function (field, index) {
    if (field.active) {
      options.push(field.value)
    }
  })
  return options
}

export function getSavedSearchList(savedSearchList) {
  let sortedList = {}
  let sortedProjectSaveSearchList = []
  let sortedCompanySaveSearchList = []
  let sortedContactsSaveSearchList = []

  if (savedSearchList?.length > 0) {
    savedSearchList?.sort(function (record1, record2) {
      var textA = record1.saved_search_name ? record1.saved_search_name.toUpperCase() : '';
      var textB = record2.saved_search_name ? record2.saved_search_name.toUpperCase() : '';
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
  }

  savedSearchList.map(function (savedSearch, index) {
    if (savedSearch.object_type === "project" || (savedSearch.filters && savedSearch.filters.object_type === "project")) {
      sortedProjectSaveSearchList.push(savedSearch)
    } else if (savedSearch.object_type === "company" || (savedSearch.filters && savedSearch.filters.object_type === "company")) {
      sortedCompanySaveSearchList.push(savedSearch)
    } else if (savedSearch.object_type === "people" || savedSearch.filters && savedSearch.filters.object_type === "people") {
      sortedContactsSaveSearchList.push(savedSearch)
    }
  })
  sortedList["project"] = sortedProjectSaveSearchList
  sortedList["company"] = sortedCompanySaveSearchList
  sortedList['people'] = sortedContactsSaveSearchList

  return sortedList
}

export function getSavedSearch(savedSearchList, id) {
  let savedSearch = _.filter(savedSearchList, (savedsearch) => {
    return savedsearch.saved_search_id === id
  })
  savedSearch = {
    ...savedSearch[0],
    key: 'saved_search'
  }
  return savedSearch;
}

export function getTag(tagList, id, type) {
  let tag = _.filter(tagList, (tagItem) => {
    return tagItem.tag_id === id
  })
  let objectType = type.toLowerCase() === 'projects' ? 'project' : 'company'
  tag = {
    ...tag[0],
    key: 'tags',
    object_type: objectType
  }
  return tag;
}

export function getExportTemplateStaticFields(fields) {
  let options = []

  fields.map(function (field, index) {
    let fieldJson = {}
    fieldJson.value = field
    fieldJson.id = field["field_id"]
    fieldJson.seq_no = field["seqno"]
    fieldJson.value.mandatory = field["is_mandatory"] ? field["is_mandatory"] : ""
    fieldJson.name = field["display_name"]
    fieldJson.active = field["is_selected"]
    fieldJson.mandatory = field["is_mandatory"] ? field["is_mandatory"] : ""
    fieldJson.export_type = field["object_type"]
    fieldJson.display = true
    fieldJson.display_value = field["is_selected"]

    options.push(fieldJson)
  })
  return options
}

export const updateCheckedFlags = (payload, fieldTypePlural, type, exportCriteria, dispatch) => {
  let newPayload = payload;
  newPayload['displayType'] = exportCriteria.setting.type.toLowerCase();

  if (fieldTypePlural === "projects") {
    dispatch(updateIsProjectChecked(payload));

    if (!payload.isProjectsChecked) {
      if (exportCriteria.setting.type.toLowerCase() === 'projects') {
        dispatch(updateExcludeMailed({
          excludeMailedProjects: false,
          excludeMailedProjectsInterval: false,
          excludeMailedCompanies: false,
          excludeMailedCompaniesInterval: false,
          excludeMailedContacts: false,
          excludeMailedContactsInterval: false,
          avoidDuplicates: false
        }))
        dispatch(updateExcludeContactsWithNoConfirmedName(false));
        dispatch(updateExcludeContactsWithNoEmail(false));
      } else {
        dispatch(updateExcludeMailed({
          excludeMailedProjects: false,
          excludeMailedProjectsInterval: false,
          avoidDuplicates: false
        }));
      }
    }
  } else if (fieldTypePlural === "companies") {
    dispatch(updateIsCompanyChecked(payload));

    if (!payload.isCompaniesChecked) {
      if (exportCriteria.setting.type.toLowerCase() === 'projects') {
        dispatch(updateExcludeMailed({
          excludeMailedCompanies: false,
          excludeMailedCompaniesInterval: false,
          avoidDuplicates: false
        }));
        dispatch(updateExcludeContactsWithNoConfirmedName(false));
        dispatch(updateExcludeContactsWithNoEmail(false));
      } else {
        dispatch(updateExcludeMailed({
          excludeMailedProjects: false,
          excludeMailedProjectsInterval: false,
          excludeMailedCompanies: false,
          excludeMailedCompaniesInterval: false,
          excludeMailedContacts: false,
          excludeMailedContactsInterval: false,
          avoidDuplicates: false
        }))
        dispatch(updateExcludeContactsWithNoConfirmedName(false));
        dispatch(updateExcludeContactsWithNoEmail(false));
      }
    }
  } else {
    dispatch(updateIsContactChecked(payload));

    if (!payload.isContactsChecked) {
      if (exportCriteria.setting.type.toLowerCase() === 'projects') {
        dispatch(updateExcludeContactsWithNoConfirmedName(false));
        dispatch(updateExcludeContactsWithNoEmail(false));
        dispatch(updateExcludeMailed({
          excludeMailedContacts: false,
          excludeMailedContactsInterval: false,
          avoidDuplicates: false
        }));
      } else {
        dispatch(updateExcludeContactsWithNoConfirmedName(false));
        dispatch(updateExcludeContactsWithNoEmail(false));
        dispatch(updateExcludeMailed({
          avoidDuplicates: false,
          excludeMailedProjects: false,
          excludeMailedProjectsInterval: false
        }));
      }
    }
  }
};

export function appendFieldDisplayNames(fields, isSummaryList = true) {
  let fieldsText = ""
  let activeCount = 0
  let counter = isSummaryList ? 100 : 3
  if (fields) {
    fields?.map(function (option, index) {
      if (option.active) {
        if (activeCount < counter) {
          if (fieldsText.length == 0) {
            fieldsText += option.name
          } else {
            fieldsText = fieldsText + ", " + option.name
          }
        }
        activeCount += 1
      }
    })
  }

  if (!isSummaryList) {
    if (activeCount > counter) {
      activeCount = activeCount - 3
      fieldsText = fieldsText + " +" + activeCount.toString()
    }
  }
  return fieldsText
}

export function getSelectedFieldsFalse(options) {
  let itemActive = []
  options.map(item =>
    !item.active ? itemActive.push(item) : '')
  return itemActive.length === options.length
}

export function getSelectedFields(options) {
  let itemActive = []
  options.map(item =>
    item.active ? itemActive.push(item) : '')
  return itemActive.length === options.length
}

export function extractPayloadForRolesAndMailedExportCount(exports, value) {
  if (exports?.setting?.includeRoles?.length > 0) {
    value = {
      ...value,
      query: {
        ...value.query,
        project_roles: { 'operator': '=', 'value': exports?.setting?.includeRoles }
      }
    };
  }
  if (exports?.setting?.excludeContactWithNoConfirmedName) {
    value = {
      ...value,
      query: {
        ...value.query,
        person_full_name: { 'operator': '!0' },
        person_first_name: { 'operator': '!0' },
        person_last_name: { 'operator': '!0' }
      }
    };
  } else if (value.query) {
      const { person_full_name, person_first_name, person_last_name, ...restQuery } = value.query;
      value = {
        ...value,
        query: restQuery
      };
  }
  if (exports?.setting?.excludeContactWithNoEmail) {
    value = {
      ...value,
      query: {
        ...value.query,
        person_email: { 'operator': '!=', 'value': '' }
      }
    };
  } else if (value.query) {
    const { person_email, ...restQuery } = value.query;
    value = {
      ...value,
      query: restQuery
    };
  }
  if (exports?.setting?.excludeMailedProjects && exports?.setting?.excludeMailedProjects === true
    && exports?.setting?.excludeMailedProjectsInterval) {
    value['excludeMailedProjects'] = true;
    value['excludeMailedProjectsInterval'] = exports?.setting?.excludeMailedProjectsInterval;
  } else {
    const { excludeMailedProjects, excludeMailedProjectsInterval, ...restValue } = value;
    value = restValue;
  }
  if (exports?.setting?.excludeMailedCompanies && exports?.setting?.excludeMailedCompanies === true
    && exports?.setting?.excludeMailedCompaniesInterval) {
    value['excludeMailedCompanies'] = true;
    value['excludeMailedCompaniesInterval'] = exports?.setting?.excludeMailedCompaniesInterval;
  } else {
    const { excludeMailedCompanies, excludeMailedCompaniesInterval, ...restValue } = value;
    value = restValue;
  }
  if (exports?.setting?.excludeMailedContacts && exports?.setting?.excludeMailedContacts === true
    && exports?.setting?.excludeMailedContactsInterval) {
    value['excludeMailedContacts'] = true;
    value['excludeMailedContactsInterval'] = exports?.setting?.excludeMailedContactsInterval;
  } else {
    const { excludeMailedContacts, excludeMailedContactsInterval, ...restValue } = value;
    value = restValue;
  }
  return value;
}

export function extractPayloadForDatesExportCount(value, momentFromFormat, momentToFormat) {
  value = {
    ...value,
    query: {
      ...value.query,
      project_last_published: { 'operator': '..', 'value1': momentFromFormat, 'value2': momentToFormat }
    }
  };
  return value;
}

export function createDownloadPdfPayload(feedname, objectIds, type, user) {
  return {
      "feedname": feedname,
      "setting": {
          "collection": {
              "key": objectIds,
              "name": "",
              "type": "sc"
          },
          "exportRecordsCount": objectIds.length,
          "mediaType": "pdf",
          "schedule": {
              "frequency": "once"
          },
          "sizeOfDelivery": objectIds.length,
          "sortBy": "Latest",
          "type": type
      },
      "user_no": user ? user.user_id : 0
  }
}
