import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import Icon from '../../common/js/icon';
import { deleteSaveSearch, fetchShareForObjectId, editDefaultSavedSearch, updateFeedSaveSearch, deleteFeedSaveSearch, fetchSaveSearchResults } from '../apiclient';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import DeleteModal from '../deleteModal';
import ShareModal from '../shareModal';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { capitalizeFirstLetter } from '../../../utils/commonFunctions';
import FeedsModal from '../feedsModal';
import { updateSavedSearchOffsetLimitValue } from '../redux/reducer';
import { MIN_MILES } from '../../../utils/settings'
import { checkSaveSearchById, getDisplayProjectValue, getRelativeTimingMessage, getRelativeUpdatedMessage } from '../../../utils';
import { TOURSAVEDSEARCH } from '../../../utils/tour';
import { Navigate } from 'react-router';
import { initializeSavedSearchFilterState } from './utils';
import { updateAppliedContext, updateSaveSeachRedirectionApplied, updatedSelectedSavedSearch } from '../../search/redux/searchPeristReducer';
import { resetFilterState, updateFilterState } from '../../search/filters/redux/reducer';
import { initializeFilterState } from '../../search/filters/utils';
import { updateSearchListSelection, updateSelectedSelectorControlValue } from '../../search/redux/reducer';
import _ from 'lodash';

function MySavedSearches() {
    let navigate = useNavigate()
    const savedsearch = useSelector((state) => state.savedsearch)
    const userData = useSelector(state => state.user.user)
    const shareState = useSelector(state => state.user)
    const filter = useSelector(state => state.filter)
    const searchPersist = useSelector(state => state.searchPersist)
    const dispatch = useDispatch();
    const [savesearch, setsaveSearch] = useState(savedsearch?.detailsPage);
    const [isOpen, setIsOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const [label, setLabel] = useState('')
    const [shareDetail, setShareDetail] = useState({})
    const [activeSavedSearch, setActiveSavedSearch] = useState({})
    const [defaultPayload, setDefaultPayload] = useState({})
    const [defaultApiCall, setDefaultApiCall] = useState(false)
    const [isDeleteApiCall, setIsDeleteApiCall] = useState(false)
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    const [defaultSaveSearchId, setDefaultSaveSearchId] = useState('')
    const [customerProfile, setCustomerProfile] = useState(searchPersist?.customerProfile)
    const settings = useSelector((state) => state.settings)
    const search = useSelector((state) => state.search)

    useEffect(() => {
        setsaveSearch(savedsearch?.detailsPage)
        if (document.getElementsByClassName("saved-search-tag-text").length > 0) {
            for (var i = 0; i < document.getElementsByClassName("saved-search-tag-text").length; i++) {
                document.getElementsByClassName("saved-search-tag-text")[i].title = document.getElementsByClassName("saved-search-tag-text")[i].innerText
            }
        }
    }, [savedsearch.detailsPage])
    useEffect(() => {
        if (showMoreClicked) {
            fetchSaveSearchResults(dispatch, savedsearch)
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);

    useEffect(() => {
        setCustomerProfile(searchPersist?.customerProfile)
    }, [searchPersist?.customerProfile])


    useEffect(() => {
        if (isDeleteApiCall) {
            deleteSaveSearch(deleteId, deleteName, dispatch, savedsearch)
            setIsDeleteApiCall(false)
            setDeleteId('')
            setDeleteName('')
            setIsOpen(false)
            setLabel("")
        }
    }, [isDeleteApiCall])

    useEffect(() => {
        if (defaultApiCall) {
            editDefaultSavedSearch(defaultPayload, defaultSaveSearchId, dispatch, savedsearch, shareState);
            setDefaultApiCall(false)
        }
    }, [defaultApiCall])


    /**** Delete  ******/
    const handleDelete = (id, name) => {
        setLabel("delete")
        setIsOpen(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const handleCancelDelete = () => {
        setIsOpen(false)
        setDeleteId('')
        setDeleteName('')
        setLabel("")
        setShareDetail({})
    }

    const handleDeleteYes = () => {
        if (deleteId) {
            dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
            setIsDeleteApiCall(true)
        }
    }

    /***** Share *****/
    const handleShare = (item) => {
        fetchShareForObjectId(item.saved_search_id, dispatch, shareState, userData)
        setIsOpen(true)
        setLabel("share")
        setShareDetail(item)
    }

    const handleSwitchDefault = (savedSearch, id) => {
        let payload = { 'saved_search_name': savedSearch.saved_search_name, 'is_default': !savedSearch.is_default }
        setDefaultPayload(payload)
        setDefaultSaveSearchId(id)
        dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
        setDefaultApiCall(true)

    }

    const handleFeeds = (item) => {
        setIsOpen(true)
        setLabel('feeds')
        setActiveSavedSearch(item)
    }
    const handleShowMore = () => {
        let newOffset = savedsearch?.offsetLimitValue?.offset + 20;
        dispatch(updateSavedSearchOffsetLimitValue({ offset: newOffset, records: savedsearch?.offsetLimitValue?.records }));
        setShowMoreClicked(true)
    }
    const renderDetails = (savedSearch) => {
        let
            devTypes = '',
            includeRolesCount = false,
            includeCategoriesCount = false,
            mProjectFloorArea = false,
            mProjectStoreysBelow = false,
            mProjectStoreysAbove = false,
            mProjectSiteArea = false,
            companyId = false,
            projectScheme = false,
            includeStagesCount = false,
            projectTitle = false,
            projectId = false,
            projectBuildPhase = false,
            completedProject = false,
            mProjectPlanArea = false,
            mProjectParking = false,
            mProjectUnits = false,
            personJobTitle = false,
            calendarTiming = false,
            relativeTiming = false,
            projectValue = false,
            inclusions = false,
            projectUpdatedSince = false,
            companyTown = false,
            projectTown = false,
            companyPostcode = false,
            projectPostcode = false,
            personName = false,
            companyLocation = false,
            companyName = false,
            companyText = false,
            personText = false,
            companyRegions = false,
            projectRegions = false,
            materialsCount = false,
            projectFramework = false,
            projectBrands = false,
            projectLocation = false,
            roleAdded = false;

        if (savedSearch?.query) {
            let defalutExcludeStages = [19, 20]
            //Filter tiles started
            if (savedSearch?.query?.project_text) {
                let message = Array.isArray(savedSearch?.query?.project_text?.value) &&
                    savedSearch?.query?.project_text?.value?.length > 1 ?
                    savedSearch.query.project_text.value.slice(0, -1).join(', ') + ', ' + savedSearch.query.project_text.value.slice(-1) :
                    savedSearch.query.project_text.value

                if (Array.isArray(savedSearch?.query?.project_text?.value) &&
                    savedSearch?.query?.project_text?.value?.length > 1) {
                    message += " (" + (savedSearch?.project_search === "all" ? "All" : "Any") + ")"
                }
                projectTitle = (<div className="saved-search-tag tile-project-info">
                    <div className="saved-search-tag-lable">
                        {"Project Information"}
                    </div>
                    <div className="saved-search-tag-text">
                        {message}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_units) {
                let value1 = savedSearch?.query?.project_units?.operator === ".." ? savedSearch.query.project_units.value1 : savedSearch?.query?.project_units?.operator === "<=" || savedSearch?.query?.project_units?.operator === "<" ? 0 : savedSearch?.query?.project_units.value
                let value2 = savedSearch?.query?.project_units?.operator === ".." ? savedSearch.query.project_units.value2 : savedSearch?.query?.project_units?.operator === ">=" || savedSearch?.query?.project_units?.operator === ">" ? "No limit" : savedSearch?.query?.project_units.value

                mProjectUnits = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Number of units"}
                    </div>

                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>

                </div>)
            }

            if (savedSearch?.query?.project_roles?.length > 0 || savedSearch?.query?.role_code?.length > 0) {
                let projectRoles = customerProfile?.["project_roles"]?.values;
                let include = []
                let exclude = []
                let roleValues = savedSearch?.query?.project_roles?.length > 0 ? savedSearch?.query?.project_roles : savedSearch?.query?.role_code
                roleValues.map(projectRolesTypes => {
                    if (projectRolesTypes.operator === "=") {
                        projectRoles?.map(item => {
                            if (projectRolesTypes.value.includes(item.value)) {
                                include.push(item.label)
                            }
                        })
                    }
                    if (projectRolesTypes.operator === "!=") {
                        projectRoles?.map(item => {
                            if (projectRolesTypes.value.includes(item.value)) {
                                exclude.push(item.label)
                            }
                        })
                    }
                })

                let includeMsg = include.length > 0 ? "Includes " : ""
                include.length > 0 && include.map(item => {
                    includeMsg += item
                    includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
                })
                let excludeMsg = exclude.length > 0 ? "Excludes " : ""
                exclude.length > 0 && exclude.map(item => {
                    excludeMsg += item
                    excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
                })
                let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")
                includeRolesCount = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Roles"}
                    </div>
                    <div className="saved-search-tag-text">
                        {message}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.project_floor_area) {
                let value1 = savedSearch?.query?.project_floor_area?.operator === ".." ? savedSearch.query.project_floor_area.value1 : savedSearch?.query?.project_floor_area?.operator === "<=" || savedSearch?.query?.project_floor_area?.operator === "<" ? 0 : savedSearch?.query?.project_floor_area.value
                let value2 = savedSearch?.query?.project_floor_area?.operator === ".." ? savedSearch.query.project_floor_area.value2 : savedSearch?.query?.project_floor_area?.operator === ">=" || savedSearch?.query?.project_floor_area?.operator === ">" ? "No limit" : savedSearch?.query?.project_floor_area.value

                mProjectFloorArea = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Floor area (all floors)"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_plan_area) {
                let value1 = savedSearch?.query?.project_plan_area?.operator === ".." ? savedSearch.query.project_plan_area.value1 : savedSearch?.query?.project_plan_area?.operator === "<=" || savedSearch?.query?.project_plan_area?.operator === "<" ? 0 : savedSearch?.query?.project_plan_area.value
                let value2 = savedSearch?.query?.project_plan_area?.operator === ".." ? savedSearch.query.project_plan_area.value2 : savedSearch?.query?.project_plan_area?.operator === ">=" || savedSearch?.query?.project_plan_area?.operator === ">" ? "No limit" : savedSearch?.query?.project_plan_area.value

                mProjectPlanArea = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Plan area (ground floor only)"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>
                </div>)

            }
            if (savedSearch?.query?.project_site_area) {
                let value1 = savedSearch?.query?.project_site_area?.operator === ".." ? savedSearch.query.project_site_area.value1 : savedSearch?.query?.project_site_area?.operator === "<=" || savedSearch?.query?.project_site_area?.operator === "<" ? 0 : savedSearch?.query?.project_site_area.value
                let value2 = savedSearch?.query?.project_site_area?.operator === ".." ? savedSearch.query.project_site_area.value2 : savedSearch?.query?.project_site_area?.operator === ">=" || savedSearch?.query?.project_site_area?.operator === ">" ? "No limit" : savedSearch?.query?.project_site_area.value

                mProjectSiteArea = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Site Area"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>
                </div>)

            }
            if (savedSearch?.query?.project_storeys_above) {
                let value1 = savedSearch?.query?.project_storeys_above?.operator === ".." ? savedSearch.query.project_storeys_above.value1 : savedSearch?.query?.project_storeys_above?.operator === "<=" || savedSearch?.query?.project_storeys_above?.operator === "<" ? 0 : savedSearch?.query?.project_storeys_above.value
                let value2 = savedSearch?.query?.project_storeys_above?.operator === ".." ? savedSearch.query.project_storeys_above.value2 : savedSearch?.query?.project_storeys_above?.operator === ">=" || savedSearch?.query?.project_storeys_above?.operator === ">" ? "No limit" : savedSearch?.query?.project_storeys_above.value

                mProjectStoreysAbove = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Above ground storeys"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>
                </div>)

            }
            if (savedSearch?.query?.project_storeys_below) {
                let value1 = savedSearch?.query?.project_storeys_below?.operator === ".." ? savedSearch.query.project_storeys_below.value1 : savedSearch?.query?.project_storeys_below?.operator === "<=" || savedSearch?.query?.project_storeys_below?.operator === "<" ? 0 : savedSearch?.query?.project_storeys_below.value
                let value2 = savedSearch?.query?.project_storeys_below?.operator === ".." ? savedSearch.query.project_storeys_below.value2 : savedSearch?.query?.project_storeys_below?.operator === ">=" || savedSearch?.query?.project_storeys_below?.operator === ">" ? "No limit" : savedSearch?.query?.project_storeys_below.value

                mProjectStoreysBelow = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Below ground storeys"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"from: " + (value1) + " to: " + (value2)}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.person_job_title) {
                personJobTitle = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Job title"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.person_job_title.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.person_full_name) {
                personName = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Person Name"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.person_full_name.value}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.company_text) {
                companyText = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company Information"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.company_text.value}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.person_title) {
                personText = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Person Title"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.person_title.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.person_text) {
                personText = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Person Information"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.person_text.value}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.company_town) {
                let townArray = savedSearch.query.company_town.value,
                    tagValue = ''

                if (townArray.length > 0) {
                    if (townArray.length != 0 && townArray.length == 1) {
                        tagValue = townArray
                    } else {
                        tagValue = townArray.slice(0, -1).join(', ') + ' and ' + townArray.slice(-1)
                    }
                }

                companyTown = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company town"}
                    </div>
                    <div className="saved-search-tag-text">
                        {tagValue}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.company_postcode) {
                let postCodeArray = savedSearch.query.company_postcode.value,
                    tagValue = ''

                if (postCodeArray.length > 0) {
                    if (postCodeArray.length != 0 && postCodeArray.length == 1) {
                        tagValue = postCodeArray
                    } else {
                        tagValue = postCodeArray.slice(0, -1).join(', ') + ' and ' + postCodeArray.slice(-1)
                    }
                }

                companyPostcode = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company postcode"}
                    </div>
                    <div className="saved-search-tag-text">
                        {tagValue}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_town) {
                let townArray = savedSearch?.query?.project_town?.value,
                    tagValue = ''

                if (townArray.length > 0) {
                    if (townArray.length != 0 && townArray.length == 1) {
                        tagValue = townArray
                    } else {
                        tagValue = townArray.slice(0, -1).join(', ') + ' and ' + townArray.slice(-1)
                    }
                }

                projectTown = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project town"}
                    </div>
                    <div className="saved-search-tag-text">
                        {tagValue}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_postcode) {
                let postCodeArray = savedSearch?.query?.project_postcode?.value,
                    tagValue = ''

                if (postCodeArray?.length > 0) {
                    if (postCodeArray.length != 0 && postCodeArray.length == 1) {
                        tagValue = postCodeArray
                    } else {
                        tagValue = postCodeArray.slice(0, -1).join(', ') + ' and ' + postCodeArray.slice(-1)
                    }
                }

                projectPostcode = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project postcode"}
                    </div>
                    <div className="saved-search-tag-text">
                        {tagValue}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_title) {
                projectTitle = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project Title"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.project_title.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_build_phase) {
                if (!savedSearch.query.project_build_phase.value.includes("no_timing")) {
                    let title = []
                    if (savedSearch.query.project_build_phase.value.includes("not_started")) {
                        title.push("Not Started")
                    }
                    if (savedSearch.query.project_build_phase.value.includes("enabling")) {
                        title.push("Enabling works")
                    }
                    if (savedSearch.query.project_build_phase.value.includes("on_site")) {
                        title.push("On-site")
                    }
                    projectBuildPhase = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable">
                            {"Build Phase"}
                        </div>
                        <div className="saved-search-tag-text">
                            {[title.slice(0, -1).join(', '), title.slice(-1)[0]].join(title.length < 2 ? '' : ' and ')}
                        </div>
                    </div>)
                }
                if (savedSearch.query.project_build_phase.value.includes("completed")) {
                    completedProject = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable onlyTitle">
                            {"Include completed projects"}
                        </div>
                    </div>)
                }
            }
            if (savedSearch?.query?.company_name) {

                companyName = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company name"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.company_name.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.company_id) {
                companyId = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company Id"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.company_id.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_scheme) {
                projectScheme = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project Scheme"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.project_scheme.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_brands) {
                let message = Array.isArray(savedSearch?.query?.project_brands?.value) &&
                    savedSearch?.query?.project_brands?.value?.length > 1 ?
                    savedSearch?.query?.project_brands?.value?.slice(0, -1).join(', ') + ', ' + savedSearch?.query?.project_brands.value.slice(-1) :
                    savedSearch.query.project_brands.value
                projectBrands = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Product specification"}
                    </div>
                    <div className="saved-search-tag-text">
                        {message}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_id) {
                projectId = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project Id"}
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch.query.project_id.value}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_value) {
                let value1 = savedSearch?.query?.project_value?.operator === ".." ? savedSearch.query.project_value.value1 : savedSearch?.query?.project_value?.operator === "<=" || savedSearch?.query?.project_value?.operator === "<" ? 0 : savedSearch?.query?.project_value.value
                let value2 = savedSearch?.query?.project_value?.operator === ".." ? savedSearch.query.project_value.value2 : savedSearch?.query?.project_value?.operator === ">=" || savedSearch?.query?.project_value?.operator === ">" ? "No limit" : savedSearch?.query?.project_value.value

                projectValue = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project Value"}
                    </div>
                    <div className="saved-search-tag-text">
                        {"From: " + (getDisplayProjectValue(value1 || 0) + " To: " + (value2 != "No limit" ? getDisplayProjectValue(value2) : value2))}

                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_start || savedSearch?.query?.project_finish) {
                if (savedSearch?.query?.project_start?.operator === ".." && Number.isInteger(savedSearch?.query?.project_start?.value1) || savedSearch?.query?.project_finish?.operator === ".." && Number.isInteger(savedSearch?.query?.project_finish?.value1) && savedSearch?.query?.project_finish?.operator === "..") {
                    let s = "", e = "";
                    if (savedSearch.query.project_start) {
                        let value0 = savedSearch.query.project_start.value1
                        let value1 = savedSearch.query.project_start.value2
                        if (value0 === value1) {
                            s = "Construction " + (value0 == 0 ? "starting " : value0 < 0 ? "started " : "starts ") +
                                getRelativeTimingMessage(value0) + (value0 < 0 ? " in the past" : value0 > 0 ? " in the future" : "")
                        } else if (value0 <= 0 && value1 > 0) {
                            s = "Construction started between " + getRelativeTimingMessage(value0) + (value0 != 0 ? " ago " : "") +
                                " or is due to start " + getRelativeTimingMessage(value1) + " in the future"
                        } else {
                            s = "Construction " + (value0 < 0 ? "started " : "starts ") + "between " + getRelativeTimingMessage(value0) +
                                " and " + getRelativeTimingMessage(value1) + (value1 < 0 ? " in the past" : value1 > 0 ? " in the future" : "")
                        }

                    }
                    if (savedSearch?.query?.project_finish) {

                        let value0 = savedSearch.query.project_finish.value1
                        let value1 = savedSearch.query.project_finish.value2
                        if (value0 === value1) {
                            e = "Construction " + (value0 == 0 ? "ending " : value0 < 0 ? "ended " : "is due to end ") +
                                getRelativeTimingMessage(value0) + (value0 < 0 ? " in the past" : value0 > 0 ? " in the future" : "")
                        } else if (value0 < 0 && value1 > 0) {
                            e = "Construction ended between " + getRelativeTimingMessage(value0) + " ago or is due to end " +
                                getRelativeTimingMessage(value0) + " in the future"
                        } else {
                            e = "Construction " + (value0 == 0 ? "will end " : value0 < 0 ? "ended " : "is due to end ") + "between " +
                                getRelativeTimingMessage(value0) + (value0 == 0 ? " or " : " and ") +
                                getRelativeTimingMessage(value1) + (value1 < 0 ? " in the past" : value1 > 0 ? " in the future" : "")
                        }
                    }
                    relativeTiming = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable">
                            {"Rolling timeline "}
                        </div>
                        <div className="saved-search-tag-text">
                            {s + (s != "" && e != "" ? " and " : "") + e}
                        </div>
                    </div>)
                }
                else {
                    let startDateMsg = "", endDateMsg = "";
                    if (savedSearch?.query?.project_start) {
                        if (savedSearch.query.project_start.operator === "..") {
                            let range0 = savedSearch.query.project_start.value1,
                                range1 = savedSearch.query.project_start.value2,
                                startFromDate = moment(range0).format('DD-MMM-YYYY'),
                                startToDate = moment(range1).format('DD-MMM-YYYY');
                            startDateMsg = ((new Date() <= new Date(range0)) ? "Starts between " : "Started between ") + startFromDate + " and " + startToDate;

                        }
                        else {
                            let startFromDate = savedSearch.query.project_start.operator === "<=" ? "*" : moment(savedSearch.query.project_start.value).format('DD-MMM-YYYY'),
                                startToDate = savedSearch.query.project_start.operator === ">=" ? "*" : moment(savedSearch.query.project_start.value).format('DD-MMM-YYYY')
                            startDateMsg = ((new Date() <= new Date(savedSearch.query.project_start.value)) ? "Starts between " : "Started between ") + startFromDate + " and " + startToDate;

                        }
                    }
                    if (savedSearch?.query?.project_finish) {
                        if (savedSearch.query.project_finish.operator === "..") {
                            let range0 = savedSearch.query.project_finish.value1,
                                range1 = savedSearch.query.project_finish.value2,
                                endFromDate = moment(range0).format('DD-MMM-YYYY'),
                                endToDate = moment(range1).format('DD-MMM-YYYY');
                            endDateMsg = ((new Date() <= new Date(range0)) ? "Ends between " : "Ended between ") + endFromDate + " and " + endToDate;

                        }
                        else {
                            let endFromDate = savedSearch.query.project_finish.operator === "<=" ? "*" : moment(savedSearch.query.project_finish.value).format('DD-MMM-YYYY'),
                                endToDate = savedSearch.query.project_finish.operator === ">=" ? "*" : moment(savedSearch.query.project_finish.value).format('DD-MMM-YYYY');
                            endDateMsg = ((new Date() <= new Date(savedSearch.query.project_finish.value)) ? "Ends between " : "Ended between ") + endFromDate + " and " + endToDate;
                        }

                    }
                    let message = (startDateMsg ? startDateMsg : "") + (startDateMsg && endDateMsg ? " and " : "") + (endDateMsg ? endDateMsg : "")

                    calendarTiming = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable">
                            {"Calendar timeline "}
                        </div>
                        <div className="saved-search-tag-text">
                            {message}
                        </div>
                    </div>)
                }

            }
            if (savedSearch?.query?.company_distance?.value) {
                companyLocation = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        Company Location (Within radius of {savedSearch?.query?.company_distance.value ? Math.round(savedSearch?.query?.company_distance.value) : Math.round(MIN_MILES)} {Math.round(savedSearch?.query?.company_distance.value) == 1 ? 'mile' : 'miles'})
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch?.company_current_location ? 'Current Location' : savedSearch.company_location_display ? savedSearch.company_location_display : 'Dropped pin'}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_distance?.value) {
                projectLocation = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        Project Location (Within radius of {savedSearch?.query?.project_distance?.value ? Math.round(savedSearch?.query?.project_distance?.value) : Math.round(MIN_MILES)} {Math.round(savedSearch?.query?.project_distance?.value) == 1 ? 'mile' : 'miles'})
                    </div>
                    <div className="saved-search-tag-text">
                        {savedSearch?.project_current_location ? 'Current Location' : savedSearch.project_location_display ? savedSearch.project_location_display : 'Dropped pin'}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.company_geocode) {
                let selectedRegions = [],
                    selectedCounties = [],
                    regions = customerProfile?.["company_geocode"]?.values,
                    regionNames = '';
                for (var i = 0; i < regions?.length; i++) {
                    for (var j = 0; j < regions[i].children?.length; j++) {
                        if (savedSearch?.query?.company_geocode.value.indexOf(regions[i].children[j].value) > -1) {
                            if (selectedCounties.indexOf(regions[i].children[j].label) == -1) { //avoiding duplication
                                selectedCounties.push(regions[i].children[j].label)
                            }
                            if (selectedRegions.indexOf(regions[i].label) == -1) {
                                selectedRegions.push(regions[i].label)
                            }
                        }
                    }
                }
                if (selectedRegions.length != 0 && selectedRegions.length == 1) {
                    regionNames = selectedRegions
                } else if (selectedRegions.length != 0 && selectedRegions.length > 1) {
                    regionNames = selectedRegions.slice(0, -1).join(', ') + ' and ' + selectedRegions.slice(-1)
                }
                companyRegions = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Company regions and counties"}
                    </div>
                    <div className="saved-search-tag-text">
                        {selectedCounties.length + " regions in " + regionNames}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_geocode) {
                let regionsArr = [],
                    projectCounties = [],
                    regions = customerProfile?.["project_geocode"]?.values,
                    regionNames = '';
                for (var i = 0; i < regions?.length; i++) {
                    for (var j = 0; j < regions[i].children.length; j++) {
                        if (savedSearch?.query?.project_geocode?.value.indexOf(regions[i].children[j].value) > -1) {
                            if (projectCounties.indexOf(regions[i].children[j].label) == -1) { //avoiding duplication
                                projectCounties.push(regions[i].children[j].label)
                            }
                            if (regionsArr.indexOf(regions[i].label) == -1) {
                                regionsArr.push(regions[i].label)
                            }
                        }
                    }
                }
                if (regionsArr.length != 0 && regionsArr.length == 1) {
                    regionNames = regionsArr
                } else if (regionsArr.length != 0 && regionsArr.length > 1) {
                    regionNames = regionsArr.slice(0, -1).join(', ') + ' and ' + regionsArr.slice(-1)
                }
                projectRegions = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Project regions and counties"}
                    </div>
                    <div className="saved-search-tag-text">
                        {projectCounties.length + " regions in " + regionNames}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_materials) {
                let childCount = 0
                let matMessage = ""
                let childMaterial = []
                let materials = customerProfile?.["project_materials"]?.values;
                if (materials?.every(item => savedSearch?.query?.project_materials?.value.includes(item.value))) {
                    matMessage += "All are selected"
                } else {
                    let parent = materials?.forEach(item => {
                        if (savedSearch?.query?.project_materials?.value.includes(item.value)) {
                            item.children.forEach(subChild => {
                                subChild.children.forEach(child => {
                                    ++childCount;
                                    childMaterial.push(child.label)
                                })
                            })
                        } else {
                            item.children.forEach(subChild => {
                                if (savedSearch?.query?.project_materials?.value.includes(subChild.value)) {
                                    subChild.children.forEach(child => {
                                        ++childCount;
                                        childMaterial.push(child.label)
                                    })
                                }
                                else {
                                    subChild.children.forEach(child => {
                                        if (savedSearch?.query?.project_materials?.value.includes(child.value)) {
                                            ++childCount;
                                            childMaterial.push(child.label)
                                        }
                                    })
                                }
                            })
                        }
                    })
                    if (childMaterial.length && childMaterial.length <= 2) {
                        matMessage += childMaterial[0] + (childMaterial.length > 1 ? ", " + childMaterial[1] : "")
                    } else if (childCount) {
                        matMessage += childCount + " materials"
                    }
                }

                materialsCount = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Materials"}
                    </div>
                    <div className="saved-search-tag-text">
                        {matMessage}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_stages) {
                let include = []
                let exclude = []
                savedSearch?.query?.project_stages?.map(stages => {
                    if (stages?.operator === "=") {
                        customerProfile?.["project_stages"]?.values?.map(item => {
                            if (item) {
                                item?.children?.map(stage => {
                                    if (Array.isArray(stage.value)) {
                                        if (stage.value.filter(val => !stages.value.includes(val)).length == 0) {
                                            include.push(stage.label)
                                        }
                                    } else {
                                        if (stages.value.includes(stage.value)) {
                                            include.push(stage.label)
                                        }
                                    }
                                })



                            }
                        })
                    }
                    if (stages?.operator === "!=") {
                        customerProfile?.["project_stages"]?.values?.map(item => {
                            if (item) {
                                item?.children?.map(stage => {
                                    if (Array.isArray(stage.value)) {
                                        if (stage.value.filter(val => !stages.value.includes(val)).length == 0) {
                                            exclude.push(stage.label)
                                        }
                                    } else {
                                        if (stages.value.includes(stage.value)) {
                                            exclude.push(stage.label)
                                        }
                                    }
                                })

                            }
                        })
                    }
                })
                let includeMsg = include.length > 0 ? "Includes " : ""
                let excludeMsg = exclude.length > 0 ? "Excludes " : ""

                include.length > 0 && include.map(item => {
                    includeMsg += item
                    includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
                })
                exclude.length > 0 && exclude.map(item => {
                    excludeMsg += item
                    excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
                })
                let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")

                if (message) {
                    includeStagesCount = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable">
                            {"Stages"}
                        </div>
                        <div className="saved-search-tag-text">
                            {message}
                        </div>
                    </div>)
                }
            }
            if (savedSearch?.query?.project_development_types?.length > 0) {
                let projectDevelopmentTypes = customerProfile?.["project_development_types"]?.values;
                let include = []
                let exclude = []
                savedSearch?.query?.project_development_types.map(developmentTypes => {
                    if (developmentTypes.operator === "=") {
                        projectDevelopmentTypes?.map(item => {
                            if (developmentTypes.value.toString().includes(item.value)) {
                                include.push(item.label)
                            }
                        })
                    }
                    if (developmentTypes.operator === "!=") {
                        projectDevelopmentTypes?.map(item => {
                            if (developmentTypes.value.toString().includes(item.value)) {
                                exclude.push(item.label)
                            }
                        })
                    }
                })

                let includeMsg = include.length > 0 ? "Includes " : ""
                include.length > 0 && include.map(item => {
                    includeMsg += item
                    includeMsg += include.indexOf(item) == include.length - 2 ? " and " : include.indexOf(item) == include.length - 1 ? "" : ", "
                })
                let excludeMsg = exclude.length > 0 ? "Excludes " : ""
                exclude.length > 0 && exclude.map(item => {
                    excludeMsg += item
                    excludeMsg += exclude.indexOf(item) == exclude.length - 2 ? " and " : exclude.indexOf(item) == exclude.length - 1 ? "" : ", "
                })
                let message = (includeMsg ? includeMsg : "") + (includeMsg && excludeMsg ? " and " : "") + (excludeMsg ? excludeMsg : "")
                devTypes = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Development types"}
                    </div>
                    <div className="saved-search-tag-text">
                        {message}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.project_categories || savedSearch?.query?.project_primary_category || savedSearch?.query?.project_usage || savedSearch?.query?.project_land_type) {
                let includecount = 0,
                    include = [],
                    exclude = [],
                    excludeCount = 0,
                    isPrimary = false,
                    includeCategories,
                    excludeCategories;
                let inclusionMsg = ""
                let exclusionMsg = ""
                let defaultCategories = customerProfile?.["project_categories"]?.values
                if (savedSearch?.query?.project_categories) {
                    savedSearch?.query?.project_categories?.map(categories => {
                        if (categories?.operator === "=") {
                            if (categories?.value) {
                                includeCategories = categories.value
                            }
                            if (defaultCategories?.every(item => item?.active)) {
                            }
                            else {
                                defaultCategories?.map(item => {
                                    item?.children?.map(subItem => {

                                        if (includeCategories.includes(subItem.value)) {
                                            includecount += 1
                                            if (includecount <= 2) {
                                                include.push(subItem.label)
                                            }
                                        }

                                    })

                                })
                                if (includecount > 2) {
                                    inclusionMsg = "Include " + includecount.toString() + (isPrimary ? ' primary' : '') + " categories"
                                } else if (includecount == 2) {
                                    inclusionMsg = "Include " + (isPrimary ? ' primary category ' : '') + include[0] + " and " + include[1]
                                } else if (includecount == 1) {
                                    inclusionMsg = "Include " + (isPrimary ? ' primary category ' : '') + include[0]
                                }
                            }
                        }

                        if (categories?.operator === "!=") {
                            if (categories?.value) {
                                excludeCategories = categories?.value
                            }
                            if (defaultCategories?.every(item => item?.active)) {
                            } else {
                                defaultCategories?.map(item => {
                                    item?.children?.map(subItem => {
                                        if (excludeCategories.includes(subItem.value)) {
                                            excludeCount += 1
                                            if (excludeCount <= 2) {
                                                exclude.push(subItem.label)
                                            }
                                        }

                                    })

                                })
                                if (excludeCount > 2) {
                                    exclusionMsg = "Exclude " + excludeCount.toString() + (isPrimary ? ' primary' : '') + " categories"
                                } else if (excludeCount == 2) {
                                    exclusionMsg = "Exclude " + (isPrimary ? ' primary category ' : '') + include[0] + " and " + exclude[1]
                                } else if (excludeCount == 1) {
                                    exclusionMsg = "Exclude " + (isPrimary ? ' primary category ' : '') + exclude[0]
                                }
                            }
                        }
                    })
                }


                if (savedSearch?.query?.project_primary_category) {
                    savedSearch?.query?.project_primary_category?.map(primaryCategories => {
                        if (primaryCategories?.operator === "=") {
                            if (primaryCategories?.value) {
                                isPrimary = true
                                includeCategories = primaryCategories.value
                            }
                            if (defaultCategories?.every(item => item?.active)) {
                            } else {
                                defaultCategories?.map(item => {
                                    item.children.map(subItem => {
                                        if (includeCategories.includes(subItem.value)) {
                                            includecount += 1
                                            if (includecount <= 2) {
                                                include.push(subItem.label)
                                            }
                                        }

                                    })

                                })
                                if (includecount > 2) {
                                    inclusionMsg = "Include " + includecount.toString() + (isPrimary ? ' primary' : '') + " categories"
                                } else if (includecount == 2) {
                                    inclusionMsg = "Include " + (isPrimary ? ' primary category ' : '') + include[0] + " and " + include[1]
                                } else if (includecount == 1) {
                                    inclusionMsg = "Include " + (isPrimary ? ' primary category ' : '') + include[0]
                                }
                            }
                        }

                        if (primaryCategories?.operator === "!=") {
                            if (primaryCategories?.value) {
                                isPrimary = true
                                excludeCategories = primaryCategories?.value
                            }
                            if (defaultCategories?.every(item => item?.active)) {
                            } else {
                                defaultCategories?.map(item => {
                                    item.children.map(subItem => {

                                        if (excludeCategories.includes(subItem.value)) {
                                            excludeCount += 1
                                            if (excludeCount <= 2) {
                                                exclude.push(subItem.label)
                                            }
                                        }

                                    })

                                })
                                if (excludeCount > 2) {
                                    exclusionMsg = "Exclude " + excludeCount.toString() + (isPrimary ? ' primary' : '') + " categories"
                                } else if (excludeCount == 2) {
                                    exclusionMsg = "Exclude " + (isPrimary ? ' primary category ' : '') + include[0] + " and " + exclude[1]
                                } else if (excludeCount == 1) {
                                    exclusionMsg = "Exclude " + (isPrimary ? ' primary category ' : '') + exclude[0]
                                }
                            }
                        }
                    })
                }

                let message = (inclusionMsg ? inclusionMsg : "") + (inclusionMsg && exclusionMsg ? " and " : "") + (exclusionMsg ? exclusionMsg : "")
                if (savedSearch?.query?.project_usage) {
                    if (Array.isArray(savedSearch?.query?.project_usage?.value) && savedSearch?.query?.project_usage?.value.find(x => x === 7)) {
                        message += " (Build to Rent)"
                    }
                    if (Array.isArray(savedSearch?.query?.project_usage?.value) && savedSearch?.query?.project_usage?.value.find(x => x === 11)) {
                        message += (inclusionMsg || exclusionMsg ? " (" : "") +
                            "Site to be sold" +
                            (inclusionMsg || exclusionMsg ? ")" : "")
                    }
                }
                if (savedSearch?.query?.project_land_type) {
                    if (Array.isArray(savedSearch?.query?.project_land_type?.value) && savedSearch?.query?.project_land_type?.value.find(x => x === 2)) {
                        message += "Brownfield " +
                        (inclusionMsg || exclusionMsg ? ")" : "")
                    }
                    if (Array.isArray(savedSearch?.query?.project_land_type?.value) && savedSearch?.query?.project_land_type?.value.find(x => x === 1)) {
                        message += (inclusionMsg || exclusionMsg ? " (" : "") +
                            "Greenfield" +
                            (inclusionMsg || exclusionMsg ? ")" : "")
                    }
                }

                includeCategoriesCount = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Categories"}
                    </div>
                    <div className="saved-search-tag-text">
                        {message}
                    </div>
                </div>)
            }

            if (!checkSaveSearchById(savedSearch)) {
                let condStatisfied = false
                if (savedSearch?.query?.project_stages === undefined) {
                    condStatisfied = true
                }
                else {
                    savedSearch?.query?.project_stages?.map(stages => {
                        if ((stages.value && stages.operator === "!=" && defalutExcludeStages.every(item => !stages.value.includes(item)))) {
                            condStatisfied = true
                        }
                    })
                }

                if (condStatisfied) {
                    let message = "withdrawn, abandoned"
                    inclusions = (<div className="saved-search-tag">
                        <div className="saved-search-tag-lable">
                            {"Inclusions"}
                        </div>
                        <div className="saved-search-tag-text">
                            {message}
                        </div>
                    </div>)
                }



            }
            if (savedSearch?.query?.project_framework) {
                let frameworkArray = searchPersist?.frameworkList?.filter(item => savedSearch?.query?.project_framework?.value.includes(item.id)).map(item => item.description),
                    tagValue = ''
                if (frameworkArray?.length > 0) {
                    if (frameworkArray.length != 0 && frameworkArray.length == 1) {
                        tagValue = frameworkArray
                    } else {
                        tagValue = frameworkArray.slice(0, -1).join(', ') + ' and ' + frameworkArray.slice(-1)
                    }
                }

                projectFramework = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Framework"}
                    </div>
                    <div className="saved-search-tag-text">
                        {tagValue}
                    </div>
                </div>)
            }
            if (savedSearch?.query?.project_is_new || savedSearch?.query?.project_last_published) {

                let msg = savedSearch?.query?.project_is_new?.value === undefined ? "New " + "and" + " Updated " : savedSearch?.query?.project_is_new?.value === true ? "New " : "Updated "
                msg += "in the last" + getRelativeUpdatedMessage(savedSearch?.query?.project_last_published?.value1)


                projectUpdatedSince = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Updated"}
                    </div>
                    <div className="saved-search-tag-text">
                        {msg}
                    </div>
                </div>)
            }

            if (savedSearch?.query?.role_code && savedSearch?.query?.role_updated) {
                let relativeTiming = getRelativeTimingMessage(savedSearch?.query?.role_updated?.value1);
                let msg;
                if (relativeTiming.includes("last")) {
                    msg = "in the " + relativeTiming;
                } else {
                    msg = "in the last " + relativeTiming;
                }
                roleAdded = (<div className="saved-search-tag">
                    <div className="saved-search-tag-lable">
                        {"Role Added"}
                    </div>
                    <div className="saved-search-tag-text">
                        {msg}
                    </div>
                </div>)
            }


        }




        return (

            <div className="d-flex flex-wrap">

                {includeRolesCount}
                {companyText}
                {personText}
                {companyName}
                {companyLocation}
                {companyRegions}
                {projectRegions}
                {companyTown}
                {projectTown}
                {companyPostcode}
                {projectPostcode}
                {includeCategoriesCount}
                {devTypes}
                {includeStagesCount}
                {companyId}
                {projectScheme}
                {projectTitle}
                {projectBrands}
                {projectId}
                {projectValue}
                {mProjectFloorArea}
                {mProjectStoreysBelow}
                {mProjectStoreysAbove}
                {mProjectSiteArea}
                {mProjectPlanArea}
                {mProjectParking}
                {mProjectUnits}
                {personJobTitle}
                {personName}
                {projectUpdatedSince}
                {projectBuildPhase}
                {completedProject}
                {calendarTiming}
                {relativeTiming}
                {inclusions}
                {materialsCount}
                {projectFramework}
                {projectLocation}
                {roleAdded}

            </div>

        )


    }

    const handleSaveSearchClick = (e, item) => {
        e.stopPropagation();
        let defaultFilter = initializeFilterState(search?.frameworkOptions, searchPersist.customerProfile, {})
        dispatch(updateFilterState(defaultFilter))
        initializeSavedSearchFilterState(item, searchPersist, filter, dispatch)
        dispatch(updateSaveSeachRedirectionApplied(true))
        dispatch(updateSelectedSelectorControlValue(0))
        dispatch(updateSearchListSelection(0))
        let objectType = item.object_type === 'people' ? 'person' : item.object_type
        if (!shareState.isMasqueradeUser) {
            window.Intercom('trackEvent', `Run ${objectType} saved search`, {
                'search_name': item.saved_search_name
            })
        }
        navigate("/app/list")
    }

    const renderLoader = () => {
        return (
            <div className="pt-3 main-container container saved-search-page">
                <div className="content-wrapper">
                    <div className="result-panel-scrollbar d-block placeholder" >
                        <div class="top-margin">
                            <div className="save-container">
                                <div className="column-block ">
                                    <a className="save-block-wrapper" >
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    const renderShareList = (shares) => {
        let teamlist = []
        if (shares?.shared_with['U'] !== undefined) {
            teamlist = shareListWithTooltip(shares?.shared_with['U'], 'U', teamlist)
        }
        if (shares?.shared_with['T'] !== undefined) {
            teamlist = shareListWithTooltip(shares?.shared_with['T'], 'T', teamlist)
        }
        if (shares?.shared_with['C'] !== undefined) {
            teamlist = shareListWithTooltip(shares?.shared_with['C'], 'C', teamlist)
        }
        return teamlist;
    }

    const shareListWithTooltip = (elem, userType, teamlist) => {
        if (elem?.id?.length > 0) {
            for (let i = 0; i < elem.id.length; i++) {
                if (userType === "T") {
                    teamlist.push(
                        <><span className="team-member-name team-ss-icon"
                            data-tooltip-id={`collaborators-${elem.id[i]}`}
                            data-tooltip-hidden={!settings.preferences.hint_text}
                        >
                            {elem.initials[i]}</span>
                            <ReactTooltip place="bottom" type='dark' id={`collaborators-${elem.id[i]}`}>
                                <p>{elem.name[i]} <br /> {moment(elem.shared_on[i]).format("DD-MMM-YYYY")}</p>
                            </ReactTooltip></>
                    )
                } else if (userType === "U") {
                    teamlist.push(<><span className="team-member-name"
                        data-tooltip-id={`collaborators-${elem.id[i]}`}
                        data-tooltip-hidden={!settings.preferences.hint_text}
                    >
                        {elem.initials[i]}</span>
                        <ReactTooltip place="bottom" type='dark' id={`collaborators-${elem.id[i]}`}>
                            <p>{elem.name[i]} <br /> {moment(elem.shared_on[i]).format("DD-MMM-YYYY")}</p>
                        </ReactTooltip>
                    </>)
                } else {
                    teamlist.push(<><span className="share-everyone"
                        data-tooltip-id={`collaborators-${elem.id[i]}`}
                        data-tooltip-hidden={!settings.preferences.hint_text}></span>
                        <ReactTooltip place="bottom" type='dark' id={`collaborators-${elem.id[i]}`}>
                            <p>{elem.name[i]} <br /> {moment(elem.shared_on[i]).format("DD-MMM-YYYY")}</p>
                        </ReactTooltip>
                    </>)
                }
            }
        }
        return teamlist
    }

    return (
        <>
            <div className="content-wrapper custom-one">
                <ColoredScrollbars className="result-panel-scrollbar">
                    <div className='save-container'>
                        {
                            savesearch?.length > 0 ?
                                savesearch && savesearch.map((item, index) =>
                                    <div className='column-block' key={index}>
                                        <a className='save-block-wrapper' onClick={(e) => handleSaveSearchClick(e, item)}>
                                            <div className='save-block'>
                                                <div className='row mt-2'>
                                                    <div className="col-6">
                                                        <div className='save-title mb-3'>
                                                            {item.object_type === 'project' ? (
                                                                <Icon color="#053874" size={25} icon="icon-gen-project-search" className="me-1" data-intro={TOURSAVEDSEARCH.title.intro} data-position={TOURSAVEDSEARCH.title.position} data-class={TOURSAVEDSEARCH.title.class} />
                                                            ) : item.object_type === 'company' ? (
                                                                <Icon color="#053874" size={25} icon="icon-gen-comp-search" className="me-1" data-intro={TOURSAVEDSEARCH.title.intro} data-position={TOURSAVEDSEARCH.title.position} data-class={TOURSAVEDSEARCH.title.class} />
                                                            ) : (
                                                                <Icon color="#053874" size={25} icon="icon-gen-ppl-search" className="me-1" />
                                                            )
                                                            }



                                                            <span>{item.saved_search_name}</span>
                                                            {(item?.feed?.configuration?.frequency) ? (
                                                                <Badge href="#" color="secondary" className="custom-gray-badge me-2">
                                                                    {capitalizeFirstLetter(item?.feed?.configuration?.frequency) + " Feed"}

                                                                    {
                                                                        (item?.feed?.configuration.day_of_week) ? (", " + capitalizeFirstLetter(item?.feed?.configuration?.day_of_week)) : ('')
                                                                    }
                                                                </Badge>
                                                            ) : ('')}
                                                            {item.is_default ? (
                                                                <a href="#" className="badge bg-info">Default</a>
                                                            ) : ('')}

                                                        </div>
                                                    </div>


                                                    {index == 0 ?
                                                        (<div className="hori-placeholder-tour left-zero" data-intro={TOURSAVEDSEARCH.filteroptions.intro} data-position={TOURSAVEDSEARCH.filteroptions.position} data-class={TOURSAVEDSEARCH.filteroptions.class} >&nbsp;</div>) :
                                                        ('')
                                                    }
                                                </div>
                                                <ReactTooltip id="set-alert" place="top" type='dark' effect="solid">
                                                    <p className="tool-tip-sidebar">Set alert</p>
                                                </ReactTooltip>
                                                <ReactTooltip id="set-default" place="top" type='dark' effect="solid">
                                                    <p className="tool-tip-sidebar">Set as default</p>
                                                </ReactTooltip>
                                                <ReactTooltip id="share" place="top" type='dark' effect="solid">
                                                    <p className="tool-tip-sidebar">Share</p>
                                                </ReactTooltip>
                                                <ReactTooltip id="delete" place="top" type='dark' effect="solid">
                                                    <p className="tool-tip-sidebar">Delete</p>
                                                </ReactTooltip>
                                                <div className="saved-search-tag-listing">
                                                    {renderDetails(item)}

                                                </div>
                                            </div>
                                        </a>

                                        <div className="save-search-action">
                                            <div className="d-flex justify-content-end">
                                                <div className="team-member-list me-3">
                                                    <div className="shared-user-blocks d-flex">
                                                        {/* {get_shared_users_with_details(savedSearch.sharedInfo, that.props.user).length > 0 ? (<span className="all-team-member" id="show-all"  onClick={(event) => that.handleShowAll(this, savedSearch.id)}> {that.state.savedSearchId == savedSearch.id && that.state.showAll === true ? 'Close' : ' Show all'} </span>) : ('')} */}
                                                        {
                                                            item.shares ?
                                                                <> <span className="team-member-name"
                                                                    data-tooltip-id={`collaborators-${item?.shares?.shared_by}`}
                                                                    data-tooltip-hidden={!settings.preferences.hint_text}
                                                                >
                                                                    {item?.shares?.shared_by_initials}</span>
                                                                    <ReactTooltip place="bottom" type='dark' id={`collaborators-${item?.shares?.shared_by}`}>
                                                                        <p>{item?.shares?.shared_by_name} <br /> {moment(item?.shares?.shared_on).format("DD-MMM-YYYY")}</p>
                                                                    </ReactTooltip>
                                                                    {renderShareList(item?.shares)}
                                                                </>
                                                                : ("")
                                                        }
                                                    </div>
                                                    {/* item.sharedInfo.length > 5 ? (
                                        <span onClick={(event) => that.handleShowAll(event, item.id)} id='+1' className="more-team-member" data-tooltip-id='ALL' >+{get_shared_users_with_details(item.sharedInfo, that.props.user).length - 5} </span>
                                    ) : ('')} */}

                                                </div>
                                                {index == 0 ?
                                                    (<div className="hori-placeholder-tour left-right-zero bottom-zero" data-intro={TOURSAVEDSEARCH.actionbutton.intro} data-position={TOURSAVEDSEARCH.actionbutton.position} data-class={TOURSAVEDSEARCH.actionbutton.class} >&nbsp;</div>) :
                                                    ('')
                                                }

                                                <ul className='search-button d-flex p-0'>
                                                    {userData.user_id === item.created_by && item.object_type !== 'people' ? <li className='p-1' data-tooltip-id="set-alert" data-tooltip-hidden={!settings.preferences.hint_text}><Icon color="#053874" size={25} icon={item.feed?.configuration ? "icon-gen-timer-outline-1" : "icon-gen-timer-outline"} onClick={() => handleFeeds(item)} /></li> : ''}
                                                    {userData.user_id === item.created_by ? <li className={`default-button p-1 ${item.is_default ? 'active' : ''}`} data-tooltip-id="set-default" data-tooltip-hidden={!settings.preferences.hint_text}><Icon color="#053874" size={25} icon={item.is_default ? 'icon-gen-round-filled' : "icon-gen-tick-round-outline"} onClick={() => handleSwitchDefault(item, item.saved_search_id)} /></li> : ''}
                                                    {userData.user_id === item.created_by ? <li className='p-1' data-tooltip-id="share" data-tooltip-hidden={!settings.preferences.hint_text}><Icon color="#053874" size={25} icon="icon-gen-share" onClick={() => handleShare(item)} /></li> : ''}
                                                    {userData.user_id === item.created_by ? <li className='p-1' data-tooltip-id="delete" data-tooltip-hidden={!settings.preferences.hint_text}><Icon size={25} icon="icon-gen-trash"
                                                        onClick={() => handleDelete(item.saved_search_id, item.saved_search_name)} /></li> : ''}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                ) :

                                savedsearch?.isLoaderActive ? renderLoader() :
                                    savedsearch?.isMySaveSearchActive ?
                                        (<div className="no-saved-search">
                                            <p>You have no saved searches</p>
                                            <span>To create one, search and click "Save search"</span>
                                        </div>)
                                        :

                                        (<div className="no-saved-search">
                                            <p>You have no saved searches shared with you</p>

                                        </div>)


                        }
                        {
                            savedsearch?.isLoaderActive === false && savesearch?.length > 0 && savedsearch?.isSavedSearchShowMoreActive ? (
                                <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                </div>
                            ) : ''
                        }

                    </div>
                </ColoredScrollbars>
            </div>
            {
                label === 'delete' && (
                    <DeleteModal
                        isOpen={isOpen}
                        title={deleteName}
                        handleDeleteYes={handleDeleteYes}
                        handleCancelDelete={handleCancelDelete}
                    />
                )
            }

            {
                label === 'share' && (
                    <ShareModal
                        isOpen={isOpen}
                        shareDetail={shareDetail}
                        handleCancelDelete={handleCancelDelete}
                    />
                )
            }
            {
                label === 'feeds' && (
                    <FeedsModal
                        isOpen={isOpen}
                        handleCancelDelete={handleCancelDelete}
                        activeSavedSearch={activeSavedSearch}

                    />
                )
            }

        </>
    );
}

export default MySavedSearches;