import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import moment from "moment"
import { checkIfFieldActive } from "../../utils/commonFunctions"
import { DEFAULT_EXPORT_TEMPLATE_ID } from "../../utils/settings"
import { getSelectFieldsValue } from "./utils"

const initialState = {
	exportList: [],
	exportCriteria: {
		feedname: null,
		setting: {
			collection: {
				type: '',
				key: null
			},
			sizeOfDelivery: 2000,
			type: 'Projects',
			fields: {
				projects: [],
				companies: [],
				contacts: []
			},
			sortBy: 'Latest',
			from: '',
			to: '',
			includeRoles: [],
			schedule: {
				frequency: 'once'
			},
			mediaType: 'csv',
			exportRecordsCount: -1,
			excludeContactWithNoConfirmedName: false,
			excludeContactWithNoEmail: false
		}
	},
	saveSearchCount: -1,
	microcopyMsg: "",
	exportFilters: [],
	selectedType: 'Select export type',
	selectedTypeName: '',
	exportStaticData: {
		projects: [],
		companies: [],
		contacts: [],
		templateData: {},
		templates: []
	},
	projectDownloadRecordSettings: [
		{ name: 'All', value: 2000 },
		{ name: 'Top 50', value: 50 },
		{ name: 'Top 100', value: 100 },
		{ name: 'Top 200', value: 200 },
		{ name: 'Top 500', value: 500 },
		{ name: 'Top 1000', value: 1000 }
	],
	companyDownloadRecordSettings: [
		{ name: 'All', value: 200 },
		{ name: 'Top 50', value: 50 },
		{ name: 'Top 100', value: 100 },
		{ name: 'Top 200', value: 200 },
		{ name: 'Top 500', value: 500 },
		{ name: 'Top 1000', value: 1000 }
	],
	isProjectsChecked: false,
	isCompaniesChecked: false,
	isContactsChecked: false,
	isProjectsCheckActive: false,
	isCompaniesCheckActive: false,
	isContactsCheckActive: false,
	isCopyFeed: false,
	showMoreOffsetLimit: {
		offset: 0,
		records: 50
	},
	isShowMoreActive: false,
	exportSaveSearch: [],
	savedTemplateValues: {},
	ExportCountAPILoadingCount: false,
}

const exportSlice = createSlice({
	name: 'export',
	initialState,
	reducers: {
		updateExportList: (state, action) => {
			state.exportList = action.payload
			return state
		},
		updateFilteredExportList: (state, action) => {
			let exportList = _.cloneDeep(state.exportList)
			if (Array.isArray(state.exportList?.exports)) {
				let exports = state.exportList?.exports.map(item => {
					let data = action.payload.find(val => val.feedno === item.feedno)
					if (data !== undefined && item.status !== data?.status) {
						item = data
					}
					return item
				})
				exportList = {
					...exportList,
					exports: exports
				}
			}
			state.exportList = exportList
			return state
		},
		exportTemplateSelectionHandler: (state, action) => {
			let exportCriteriaPayload = state.exportCriteria.setting.fields
			let templateData = state.exportStaticData.templates ? state.exportStaticData.templates[action.payload] : ''
			let projects = _.cloneDeep(state.exportStaticData.projects), contacts = _.cloneDeep(state.exportStaticData.contacts), companies = _.cloneDeep(state.exportStaticData.companies), col_names = []
			if (templateData) {
				exportCriteriaPayload['contacts'] = getSelectFieldsValue(contacts)
				exportCriteriaPayload['projects'] = getSelectFieldsValue(projects)
				exportCriteriaPayload['companies'] = getSelectFieldsValue(companies)
			}
			state.exportStaticData = { ...state.exportStaticData, contacts: contacts, projects: projects, companies: companies }
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload, selectedTemplate: templateData ? templateData.template_name : action.payload, templateid: templateData ? templateData.template_id : '' } }
			return state
		},
		udpateRerunExportList: (state, action) => {
			let newExportList = _.cloneDeep(state.exportList.exports)
			newExportList = newExportList.map(record => record.deliveryno === action.payload ? { ...record, status: 0, created_on: moment().utc().format('MM/DD/YYYY HH:mm:ss'), expired_on: null, description: null } : record)
			state.exportList.exports = newExportList
			return state
		},
		updateExportTemplateData: (state, action) => {
			let newExport = _.cloneDeep(state.exportCriteria)
			let templateData = action.payload.find(x => !x.is_editable && x.is_system && x.template_id === DEFAULT_EXPORT_TEMPLATE_ID)
			newExport = {
				...newExport,
				setting: {
					...state.exportCriteria.setting,
					selectedTemplate: templateData ? templateData.template_name : action.payload,
					templateid: templateData.template_id
				}
			}
			let newExportStaticData = _.cloneDeep(state.exportStaticData)
			newExportStaticData = {
				...newExportStaticData,
				templates: action.payload
			}
			state.exportStaticData = newExportStaticData
			state.exportCriteria = newExport
			return state
		},
		updateExportName: (state, action) => {
			let newExportCriteria = _.cloneDeep(state.exportCriteria)
			newExportCriteria = {
				...state.exportCriteria,
				feedname: action.payload['feedname']
			}
			state.exportCriteria = newExportCriteria
			return state
		},
		resetDataExportCriteria: (state) => {
			let defaultCriteria = {
				feedname: null,
				setting: {
					collection: {
						type: '',
						key: null
					},
					sizeOfDelivery: 2000,
					type: 'Projects',
					fields: {
						projects: [],
						companies: [],
						contacts: []
					},
					sortBy: 'Latest',
					from: '',
					to: '',
					includeRoles: [],
					schedule: {
						frequency: 'once'
					},
					mediaType: 'csv',
					exportRecordsCount: -1,
					excludeContactWithNoConfirmedName: false,
					excludeContactWithNoEmail: false
				}
			}

			let optionsList = state?.exportFilters?.includeRoles?.options
			let updatedOptionsList = optionsList?.map(option => {
				option.active = false
				return option
			})
			state.ExportCountAPILoadingCount = false
			state.exportCriteria = defaultCriteria
			state.selectedType = 'Select export type'
			state.selectedTypeName = ''
			state.projectDownloadRecordSettings = [
				{ name: 'All', value: 2000 },
				{ name: 'Top 50', value: 50 },
				{ name: 'Top 100', value: 100 },
				{ name: 'Top 200', value: 200 },
				{ name: 'Top 500', value: 500 },
				{ name: 'Top 1000', value: 1000 }
			]
			state.companyDownloadRecordSettings = [
				{ name: 'All', value: 200 },
				{ name: 'Top 50', value: 50 },
				{ name: 'Top 100', value: 100 },
				{ name: 'Top 200', value: 200 },
				{ name: 'Top 500', value: 500 },
				{ name: 'Top 1000', value: 1000 }
			]
			state.saveSearchCount = -1
			state.exportStaticData = {
				projects: [],
				companies: [],
				contacts: [],
				templates: []
			}
			state.isExportListEmpty = false
			state.isProjectsChecked = false
			state.isProjectsCheckActive = false
			state.isCompaniesChecked = false
			state.isContactsChecked = false
			state.isCompaniesCheckActive = false
			state.isContactsCheckActive = false
			state.isCopyFeed = false
			state.currentStep = 0
			state.exportFilters = {
				...state.exportFilters,
				includeRoles: {
					...state.exportFilters.includeRoles,
					options: updatedOptionsList
				}
			}
			state.exportList = []
			return state
		},
		updateExportSaveSearch: (state, action) => {
			state.exportSaveSearch = action.payload
			return state
		},
		updateSelectedTypeName: (state, action) => {
			state.selectedTypeName = action.payload
			return state
		},
		updateSelectedType: (state, action) => {
			state.selectedType = action.payload
			return state
		},
		updateSavedSearchExportType: (state, action) => {
			let isProjectActive = checkIfFieldActive(state.exportCriteria, "projects");
			let isCompanyActive = checkIfFieldActive(state.exportCriteria, "companies");
			let isContactActive = checkIfFieldActive(state.exportCriteria, "contacts")
			let newExportCriteria = _.cloneDeep(state.exportCriteria)
			newExportCriteria = {
				...state.exportCriteria,
				setting: {
					...state.exportCriteria.setting,
					type: action.payload['type'],
				}
			}
			state.isProjectsChecked = isProjectActive
			state.isProjectsCheckActive = action.payload['type'] === "Companies" ? isContactActive : action.payload['type'] === "Contacts" ? isCompanyActive : isProjectActive
			state.isCompaniesChecked = isCompanyActive
			state.isContactsChecked = isContactActive
			state.isCompaniesCheckActive = action.payload["type"] === "Contacts" ? isContactActive :
				action.payload["type"] === "Projects" ? isProjectActive : isCompanyActive
			state.isContactsCheckActive = action.payload["type"] === "Companies" || action.payload["type"] === "Projects" ?
				isCompanyActive : isContactActive
			state.exportCriteria = newExportCriteria
			return state
		},
		updateShowMoreOffsetLimit: (state, action) => {
			state.showMoreOffsetLimit = action.payload
			return state
		},
		updateIfShowMoreAvailable: (state, action) => {
			state.isShowMoreActive = action.payload
			return state
		},
		fetchShowMoreListOfexports: (state, action) => {
			let newExportList = _.cloneDeep(state.exportList)
			newExportList = {
				...state.exportList,
				exports: state.exportList?.exports.concat(action.payload.exports)
			}
			state.exportList = newExportList
			return state;
		},
		updateSavedSearchCount: (state, action) => {
			let projectDownloadRecordSettings = state.projectDownloadRecordSettings
			let companyDownloadRecordSettings = state.companyDownloadRecordSettings
			let count = action.payload.count
			let saveSearchCount = action.payload.savesearchFlag ? count : state.saveSearchCount
			if (state.exportCriteria.setting.type === 'Projects') {
				projectDownloadRecordSettings = [
					{ name: 'All', value: count },
					{ name: 'Top 50', value: 50 },
					{ name: 'Top 100', value: 100 },
					{ name: 'Top 200', value: 200 },
					{ name: 'Top 500', value: 500 },
					{ name: 'Top 1000', value: 1000 }
				]
			} else {
				companyDownloadRecordSettings = [
					{ name: 'All', value: count },
					{ name: 'Top 50', value: 50 },
					{ name: 'Top 100', value: 100 },
					{ name: 'Top 200', value: 200 },
					{ name: 'Top 500', value: 500 },
					{ name: 'Top 1000', value: 1000 }
				]
			}
			let newExportCriteria = _.cloneDeep(state.exportCriteria)
			newExportCriteria = {
				...state.exportCriteria,
				setting: {
					...state.exportCriteria.setting,
					exportRecordsCount: count
				}
			}
			state.exportCriteria = newExportCriteria
			state.projectDownloadRecordSettings = projectDownloadRecordSettings
			state.companyDownloadRecordSettings = companyDownloadRecordSettings
			state.saveSearchCount = action.payload.savesearchFlag ? count : state.saveSearchCount
			state.microcopyMsg = action.payload['object_type'] === "projects" ? count === 1 ? ('project') : ('projects') : action.payload['object_type'] === "companies" ? count === 1 ? ('company') : ('companies') : count === 1 ? ('contact') : ('contacts')
			state.microcopySavesearchMsg = action.payload['object_type'] === "projects" ? saveSearchCount === 1 ? ('project') : ('projects') : action.payload['object_type'] === "companies" ? saveSearchCount === 1 ? ('company') : ('companies') : saveSearchCount === 1 ? ('contact') : ('contacts')
			return state
		},
		updateExportRecordsCount: (state, action) => {
			let selectedCount = state.exportCriteria.setting.sizeOfDelivery
			let projectDownloadRecordSettings = state.projectDownloadRecordSettings
			let companyDownloadRecordSettings = state.companyDownloadRecordSettings
			let count = action.payload.count
			let saveSearchCount = action.payload.savesearchFlag ? count : state.saveSearchCount
			if (state.exportCriteria.setting.type === 'Projects') {
				projectDownloadRecordSettings = [
					{ name: 'All', value: count },
					{ name: 'Top 50', value: 50 },
					{ name: 'Top 100', value: 100 },
					{ name: 'Top 200', value: 200 },
					{ name: 'Top 500', value: 500 },
					{ name: 'Top 1000', value: 1000 }
				]
			} else {
				companyDownloadRecordSettings = [
					{ name: 'All', value: count },
					{ name: 'Top 50', value: 50 },
					{ name: 'Top 100', value: 100 },
					{ name: 'Top 200', value: 200 },
					{ name: 'Top 500', value: 500 },
					{ name: 'Top 1000', value: 1000 }
				]
			}
			let newExportCriteria = _.cloneDeep(state.exportCriteria)
			newExportCriteria = {
				...state.exportCriteria,
				setting: {
					...state.exportCriteria.setting,
					exportRecordsCount: count,
					sizeOfDelivery: action.payload.savesearchFlag ? selectedCount : count > 2000 ? (selectedCount < count ? selectedCount : 2000) : count
				}
			}
			state.exportCriteria = newExportCriteria
			state.projectDownloadRecordSettings = projectDownloadRecordSettings
			state.companyDownloadRecordSettings = companyDownloadRecordSettings
			state.saveSearchCount = action.payload.savesearchFlag ? count : state.saveSearchCount
			state.microcopyMsg = action.payload['object_type'] === "projects" ? count === 1 ? ('project') : ('projects') : action.payload['object_type'] === "companies" ? count === 1 ? ('company') : ('companies') : count === 1 ? ('contact') : ('contacts')
			state.microcopySavesearchMsg = action.payload['object_type'] === "projects" ? saveSearchCount === 1 ? ('project') : ('projects') : action.payload['object_type'] === "companies" ? saveSearchCount === 1 ? ('company') : ('companies') : saveSearchCount === 1 ? ('contact') : ('contacts')
			return state
		},
		updateExportFieldsForProjects: (state, action) => {
			let projects = action.payload
			if (state.exportCriteria.setting.fields.hasOwnProperty('projects') && state.exportCriteria.setting.fields.projects.length > 0) {
				projects = action.payload.map(record => state.exportCriteria.setting.fields.projects.filter(e => e.display_name === record.value.display_name).length > 0 ? { ...record } : record)
			}
			let newExportStaticData = _.cloneDeep(state.exportStaticData)
			state.exportStaticData = { ...newExportStaticData, projects: projects }
			return state
		},
		updateExportFieldsForCompanies: (state, action) => {
			let companies = action.payload
			if (state.exportCriteria.setting.fields.hasOwnProperty('companies') && state.exportCriteria.setting.fields.companies.length > 0) {
				companies = action.payload.map(record => state.exportCriteria.setting.fields.companies.filter(e => e.display_name === record.value.display_name).length > 0 ? { ...record } : record)
			}
			let newExportStaticData = _.cloneDeep(state.exportStaticData)
			state.exportStaticData = { ...newExportStaticData, companies: companies }
			return state
		},
		updateExportFieldsForContacts: (state, action) => {
			let contacts = action.payload
			if (state.exportCriteria.setting.fields.hasOwnProperty('contacts') && state.exportCriteria.setting.fields.contacts.length > 0) {
				contacts = action.payload.map(record => state.exportCriteria.setting.fields.contacts.filter(e => e.display_name === record.value.display_name).length > 0 ? { ...record } : record)
			}
			let newExportStaticData = _.cloneDeep(state.exportStaticData)
			state.exportStaticData = { ...newExportStaticData, contacts: contacts }
			return state
		},
		updateExportTemplateFields: (state, action) => {
			state.templateFields = action.payload
			return state
		},
		updateSelectAllExportFields: (state, action) => {
			if (action.payload.type === "projects") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				// let projects = state.exportStaticData.projects?.map(record => action.payload.data?.includes(record.value) ? { ...record } : record)
				let projects = state.exportStaticData.projects?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: true } : record)
				exportCriteriaPayload['projects'] = getSelectFieldsValue(projects)
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, projects: projects }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
			}
			else if (action.payload.type === "companies") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let companies = state.exportStaticData.companies?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: true } : record)
				exportCriteriaPayload['companies'] = getSelectFieldsValue(companies)
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, companies: companies }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }

			}
			else {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let contacts = state.exportStaticData.contacts?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: true } : record)
				exportCriteriaPayload['contacts'] = getSelectFieldsValue(contacts)
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, contacts: contacts }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
			}
			return state
		},
		updateUserDefinedCheckedFlags: (state, action) => {
			state.isProjectsChecked = action.payload.hasOwnProperty('isProjectsChecked') ? action.payload['isProjectsChecked'] : state.isProjectsChecked
			state.isProjectsCheckActive = action.payload.hasOwnProperty('isProjectsCheckActive') ? action.payload['isProjectsCheckActive'] : state.isProjectsCheckActive
			state.isCompaniesCheckActive = action.payload.hasOwnProperty('isCompaniesCheckActive') ? action.payload['isCompaniesCheckActive'] : state.isCompaniesCheckActive
			state.isCompaniesChecked = action.payload.hasOwnProperty('isCompaniesChecked') ? action.payload['isCompaniesChecked'] : state.isCompaniesChecked
			state.isContactsCheckActive = action.payload.hasOwnProperty('isContactsCheckActive') ? action.payload['isContactsCheckActive'] : state.isContactsCheckActive
			state.isContactsChecked = action.payload.hasOwnProperty('isContactsChecked') ? action.payload['isContactsChecked'] : state.isContactsChecked
			return state
		},
		exportTemplateIdSelectionHandler: (state, action) => {
			state.exportCriteria = {
				...state.exportCriteria,
				setting: {
					...state.exportCriteria.setting,
					templateid: action.payload
				}
			}
			return state
		},
		updateExcludeMailed: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, ...action.payload } }
			return state
		},
		updateExcludeContactsWithNoConfirmedName: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, excludeContactWithNoConfirmedName: action.payload } }
			return state
		},
		updateExcludeContactsWithNoEmail: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, excludeContactWithNoEmail: action.payload } }
			return state
		},
		saveAllExportFieldsOnEdit: (state, action) => {
			if (action.payload.type === "projects") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let projects = state.exportStaticData.projects?.map(record => action.payload.data?.includes(record.value) ? { ...record } : record)
				exportCriteriaPayload['projects'] = action.payload.data?.length > 0 ? getSelectFieldsValue(projects) : []
				state.savedTemplateValues = _.cloneDeep(exportCriteriaPayload)
				return state
			}
			else if (action.payload.type === "companies") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let companies = state.exportStaticData.companies?.map(record => action.payload.data?.includes(record.value) ? { ...record } : record)
				exportCriteriaPayload['companies'] = action.payload.data?.length > 0 ? getSelectFieldsValue(companies) : []
				state.savedTemplateValues = _.cloneDeep(exportCriteriaPayload)
				return state
			}
			else {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let contacts = state.exportStaticData.contacts?.map(record => action.payload.data?.includes(record.value) ? { ...record } : record)
				exportCriteriaPayload['contacts'] = action.payload.data?.length > 0 ? getSelectFieldsValue(contacts) : []
				state.savedTemplateValues = _.cloneDeep(exportCriteriaPayload)
				return state
			}
		},
		updateClearAllExportFields: (state, action) => {
			if (action.payload.type === "projects") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let projects = state.exportStaticData.projects?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: false } : record)
				exportCriteriaPayload['projects'] = getSelectFieldsValue(projects, false)
				if (exportCriteriaPayload['projects'] == 0) {
					delete exportCriteriaPayload['projects']
				}
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, projects: projects }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
			}
			else if (action.payload.type === "companies") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let companies = state.exportStaticData.companies?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: false } : record)
				exportCriteriaPayload['companies'] = getSelectFieldsValue(companies, false)
				if (exportCriteriaPayload['companies'] == 0) {
					delete exportCriteriaPayload['companies']
				}
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, companies: companies }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
			}
			else {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let contacts = state.exportStaticData.contacts?.map((record, index) => _.isEqual(record.value, action.payload.data[index]) ? { ...record, active: false } : record)
				exportCriteriaPayload['contacts'] = getSelectFieldsValue(contacts, false)
				if (exportCriteriaPayload['contacts'] == 0) {
					delete exportCriteriaPayload['contacts']
				}
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				state.exportStaticData = { ...newExportStaticData, contacts: contacts }
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				state.exportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }

			}
			return state
		},
		updateIsProjectChecked: (state, action) => {
			state.isProjectsChecked = action.payload.hasOwnProperty('isProjectsChecked') ? action.payload['isProjectsChecked'] : state.isProjectsChecked
			state.isProjectsCheckActive = action.payload.hasOwnProperty('isProjectsCheckActive') ? action.payload['isProjectsCheckActive'] : state.isProjectsCheckActive
			state.isCompaniesCheckActive = action.payload.hasOwnProperty('isCompaniesCheckActive') ? action.payload['isCompaniesCheckActive'] : state.isCompaniesCheckActive
			state.isCompaniesChecked = action.payload.hasOwnProperty('isCompaniesChecked') ? action.payload['isCompaniesChecked'] : state.isCompaniesChecked
			state.isContactsCheckActive = action.payload.hasOwnProperty('isContactsCheckActive') ? action.payload['isContactsCheckActive'] : state.isContactsCheckActive
			state.isContactsChecked = action.payload.hasOwnProperty('isContactsChecked') ? action.payload['isContactsChecked'] : state.isContactsChecked
			return state
		},
		updateIsCompanyChecked: (state, action) => {
			state.isProjectsChecked = action.payload.hasOwnProperty('isProjectsChecked') ? action.payload['isProjectsChecked'] : state.isProjectsChecked
			state.isProjectsCheckActive = action.payload.hasOwnProperty('isProjectsCheckActive') ? action.payload['isProjectsCheckActive'] : state.isProjectsCheckActive
			state.isCompaniesCheckActive = action.payload.hasOwnProperty('isCompaniesCheckActive') ? action.payload['isCompaniesCheckActive'] : state.isCompaniesCheckActive
			state.isCompaniesChecked = action.payload.hasOwnProperty('isCompaniesChecked') ? action.payload['isCompaniesChecked'] : state.isCompaniesChecked
			state.isContactsCheckActive = action.payload.hasOwnProperty('isContactsCheckActive') ? action.payload['isContactsCheckActive'] : state.isContactsCheckActive
			state.isContactsChecked = action.payload.hasOwnProperty('isContactsChecked') ? action.payload['isContactsChecked'] : state.isContactsChecked
			return state
		},
		updateIsContactChecked: (state, action) => {
			state.isProjectsChecked = action.payload.hasOwnProperty('isProjectsChecked') ? action.payload['isProjectsChecked'] : state.isProjectsChecked
			state.isProjectsCheckActive = action.payload.hasOwnProperty('isProjectsCheckActive') ? action.payload['isProjectsCheckActive'] : state.isProjectsCheckActive
			state.isCompaniesCheckActive = action.payload.hasOwnProperty('isCompaniesCheckActive') ? action.payload['isCompaniesCheckActive'] : state.isCompaniesCheckActive
			state.isCompaniesChecked = action.payload.hasOwnProperty('isCompaniesChecked') ? action.payload['isCompaniesChecked'] : state.isCompaniesChecked
			state.isContactsCheckActive = action.payload.hasOwnProperty('isContactsCheckActive') ? action.payload['isContactsCheckActive'] : state.isContactsCheckActive
			state.isContactsChecked = action.payload.hasOwnProperty('isContactsChecked') ? action.payload['isContactsChecked'] : state.isContactsChecked
			return state
		},
		updateExportFieldsTextInputFilter: (state, action) => {
			if (action.payload.type === "projects") {
				state.exportStaticData = {
					...state.exportStaticData,
					projects: state.exportStaticData.projects?.map(record => action.payload.fields?.map(option => option.name).includes(record.name) ?
						{ ...record, display: true } : { ...record, display: false }
					)
				}
				return state
			}
			else if (action.payload.type === "companies") {
				state.exportStaticData = {
					...state.exportStaticData,
					companies: state.exportStaticData.companies?.map(record => action.payload.fields?.map(option => option.name).includes(record.name) ?
						{ ...record, display: true } : { ...record, display: false }
					)
				}
				return state
			}
			else {
				state.exportStaticData = {
					...state.exportStaticData,
					contacts: state.exportStaticData.contacts?.map(record => action.payload.fields?.map(option => option.name).includes(record.name) ?
						{ ...record, display: true } : { ...record, display: false }
					)
				}
				return state
			}
		},
		updateFieldsFilter: (state, action) => {
			if (action.payload.type === "projects") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let projects = state.exportStaticData.projects?.map(record => record.name === action.payload.name ? { ...record, active: action.payload.active } : record)
				exportCriteriaPayload['projects'] = getSelectFieldsValue(projects)
				let newExportCriteria = _.cloneDeep(state.exportCriteria)
				newExportCriteria = { ...newExportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
				let newExportStaticData = _.cloneDeep(state.exportStaticData)
				newExportStaticData = { ...newExportStaticData, projects: projects }
				state.exportStaticData = newExportStaticData
				state.exportCriteria = newExportCriteria
				return state
			}
			else if (action.payload.type === "companies") {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let companies = state.exportStaticData.companies?.map(record => record.name === action.payload.name ? { ...record, active: action.payload.active } : record)
				exportCriteriaPayload['companies'] = getSelectFieldsValue(companies)
				state.exportStaticData = { ...state.exportStaticData, companies: companies }
				state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
				return state
			}
			else {
				let exportCriteriaPayload = state.exportCriteria.setting.fields
				let contacts = state.exportStaticData.contacts?.map(record => record.name === action.payload.name ? { ...record, active: action.payload.active } : record)
				exportCriteriaPayload['contacts'] = getSelectFieldsValue(contacts)
				state.exportStaticData = { ...state.exportStaticData, contacts: contacts }
				state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, fields: exportCriteriaPayload } }
				return state
			}
		},
		updateExistingValues: (state, action) => {
			state.existingValues = action.payload
			return state
		},
		updateExportRolesFilter: (state, action) => {
			state.exportFilters = action.payload
			return state
		},
		updateRoleTextInputFilter: (state, action) => {
			let newExportFilters = _.cloneDeep(state.exportFilters)
			newExportFilters = {
				...newExportFilters,
				includeRoles: {
					...state.exportFilters.includeRoles,
					options: state.exportFilters.includeRoles.options.map(record => record.name.trim().toLowerCase().indexOf(action.payload.trim().toLowerCase()) > -1 ?
						{ ...record, display: true } : { ...record, display: false })
				}
			}
			state.exportFilters = newExportFilters
			return state
		},
		updateSelectUnselectAllRoleFilterOptions: (state, action) => {
			let optionsList = state.exportFilters.includeRoles.options;
			let updatedList = optionsList.map(item => {
				if (item.display) {
					item.active = action.payload
				}
				return item
			})
			let newExportFilters = _.cloneDeep(state.exportFilters)
			newExportFilters = {
				...newExportFilters,
				includeRoles: {
					...state.exportFilters.includeRoles,
					options: updatedList
				}
			}
			state.exportFilters = newExportFilters
			return state
		},
		updateExportSelectAllFavourites: (state, action) => {
			let optionsList = state.exportFilters.includeRoles.options;
			let updatedOptionsList = optionsList.map(option => {
				if (action.payload.includes(option.value)) {
					option.active = true
				}
				else {
					option.active = false
				}
				return option
			})
			let newExportFilters = _.cloneDeep(state.exportFilters)
			newExportFilters = {
				...newExportFilters,
				includeRoles: {
					...state.exportFilters.includeRoles,
					options: updatedOptionsList
				}
			}
			state.exportFilters = newExportFilters
			return state
		},
		updateExportRoles: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, includeRoles: state.exportFilters.includeRoles?.options?.filter(x => x.active === true).map(option => parseInt(option.value)) } }
			return state
		},
		updateExportIncludeRolesFilter: (state, action) => {
			let newExportFilters = _.cloneDeep(state.exportFilters)
			newExportFilters = {
				...newExportFilters,
				includeRoles: {
					...state.exportFilters.includeRoles,
					options: state.exportFilters.includeRoles.options.map(record => record.value == action.payload.value ?
						{ ...record, active: action.payload.active } : record
					)
				}
			}
			state.exportFilters = newExportFilters
			return state
		},
		updateExportSortBy: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, sortBy: action.payload['sortBy'] } }
			return state
		},
		updateSizeOfDelivery: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, sizeOfDelivery: action.payload['sizeOfDelivery'] } }
			return state
		},
		updateIsExportCountAPILoaded: (state, action) => {
			state.ExportCountAPILoadingCount = action.payload
			return state
		},
		updateMarkAsMailed: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, ...action.payload } }
			return state
		},
		updateExportCountQuery: (state, action) => {
			state.exportCountQuery = action.payload
			return state
		},
		updateExportFromDate: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, from: action.payload['from'] } }
			return state
		},
		updateExportToDate: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, to: action.payload['to'] } }
			return state
		},
		resetExportDate: (state, action) => {
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, from: action.payload['from'] } }
			state.exportCriteria = { ...state.exportCriteria, setting: { ...state.exportCriteria.setting, to: action.payload['to'] } }
			return state
		},
		updateExportCriteria: (state, action) => {
			let projects = state.exportStaticData.projects
			let companies = state.exportStaticData.companies
			let contacts = state.exportStaticData.contacts
			if (action.payload['criteria']['setting']['fields'].hasOwnProperty('projects') && action.payload['criteria']['setting']['fields']['projects'].length > 0) {
				_.map(state.exportStaticData.projects, (record, index1) => {
					record.value.is_selected = false
					record.active = false
					record.display_value = false
					_.map(action.payload['criteria']['setting']['fields']['projects'], (selected, index2) => {
						if (selected.field_id === record.value.field_id) {
							record.value.is_selected = true
							record.active = true
							record.display_value = true
						}
					})
				})
			}
			if (action.payload['criteria']['setting']['fields'].hasOwnProperty('companies') && action.payload['criteria']['setting']['fields']['companies'].length > 0) {
				_.map(state.exportStaticData.companies, (record, index1) => {
					record.value.is_selected = false
					record.active = false
					record.display_value = false
					_.map(action.payload['criteria']['setting']['fields']['companies'], (selected, index2) => {
						if (selected.field_id === record.value.field_id) {
							record.value.is_selected = true
							record.active = true
							record.display_value = true
						}
					})
				})
			}
			if (action.payload['criteria']['setting']['fields'].hasOwnProperty('contacts') && action.payload['criteria']['setting']['fields']['contacts'].length > 0) {
				_.map(state.exportStaticData.contacts, (record, index1) => {
					record.value.is_selected = false
					record.active = false
					record.display_value = false
					_.map(action.payload['criteria']['setting']['fields']['contacts'], (selected, index2) => {
						if (selected.field_id === record.value.field_id) {
							record.value.is_selected = true
							record.active = true
							record.display_value = true
						}
					})
				})
			}
			let role_filter_options = state.exportFilters.includeRoles.options
			if (action.payload['criteria']['setting']['includeRoles'].length > 0) {
				role_filter_options = state.exportFilters.includeRoles.options.map(record => action.payload['criteria']['setting']['includeRoles'].filter(e => e == record.value).length > 0 ? { ...record, active: true } : record)
			}

			return {
				...state,
				exportFilters: {
					...state.exportFilters, includeRoles: { ...state.exportFilters.includeRoles, options: role_filter_options }
				},
				exportStaticData: {
					...state.exportStaticData,
					projects: projects,
					companies: companies,
					contacts: contacts
				},
				exportCriteria: {
					...state.exportCriteria,
					feedname: action.payload['criteria']['feedname'] + " (copy)",
					setting: action.payload['criteria']['setting']
				},
				selectedType: action.payload['criteria']['setting']['collection']['type'] == 'SS' ? 'Saved search' : action.payload['criteria']['setting']['collection']['type'] == 'tag' ? 'Tag' : '',
				selectedTypeName: action.payload['criteria']['setting']['collection']['name'],
				isProjectsChecked: action.payload['criteria']['setting']['fields'].hasOwnProperty('projects') && action.payload['criteria']['setting']['fields']['projects'].length > 0,
				isCompaniesCheckActive: action.payload['criteria']['setting']['type'] === 'Projects' ?
					action.payload['criteria']['setting']['fields'].hasOwnProperty('projects') &&
					action.payload['criteria']['setting']['fields']['projects'].length > 0 :
					action.payload['criteria']['setting']['type'] === 'Contacts' ?
						action.payload['criteria']['setting']['fields'].hasOwnProperty('contacts') &&
						action.payload['criteria']['setting']['fields']['contacts'].length > 0 :
						action.payload['criteria']['setting']['type'] === 'Companies',
				isCompaniesChecked: action.payload['criteria']['setting']['fields'].hasOwnProperty('companies') && action.payload['criteria']['setting']['fields']['companies'].length > 0,
				isContactsCheckActive: action.payload['criteria']['setting']['type'] === 'Companies' ||
					action.payload['criteria']['setting']['type'] === 'Projects' ?
					action.payload['criteria']['setting']['fields'].hasOwnProperty('companies') &&
					action.payload['criteria']['setting']['fields']['companies'].length > 0 :
					action.payload['criteria']['setting']['type'] === 'Contacts',
				isContactsChecked: action.payload['criteria']['setting']['fields'].hasOwnProperty('contacts') && action.payload['criteria']['setting']['fields']['contacts'].length > 0,
				isProjectsCheckActive: action.payload['criteria']['setting']['type'] === 'Companies' ?
					action.payload['criteria']['setting']['fields'].hasOwnProperty('contacts') &&
					action.payload['criteria']['setting']['fields']['contacts'].length > 0 :
					action.payload['criteria']['setting']['type'] === 'Contacts' ?
						action.payload['criteria']['setting']['fields'].hasOwnProperty('companies') &&
						action.payload['criteria']['setting']['fields']['companies'].length > 0 :
						action.payload['criteria']['setting']['type'] === 'Projects',
				microcopyMsg: action.payload['criteria']['setting']['type']
			}
		},
		updateCopyExport: (state, action) => {
			state.isCopyFeed = action.payload
			return state
		},
		updateExportCollection: (state, action) => {
			let newExportCriteria = state.exportCriteria
			newExportCriteria = {
				...newExportCriteria, setting: { ...state.exportCriteria.setting, collection: action.payload }
			}
			state.exportCriteria = newExportCriteria
			return state;
		}
	}
})

export const { updateExportList, exportTemplateSelectionHandler, udpateRerunExportList, updateExportTemplateData, resetDataExportCriteria,
	updateExportName, updateExportSaveSearch, updateSelectedTypeName, updateSelectedType, updateSavedSearchExportType, updateShowMoreOffsetLimit,
	updateIfShowMoreAvailable, fetchShowMoreListOfexports, updateExportRecordsCount, updateSavedSearchCount, updateExportFieldsForProjects,
	updateExportFieldsForCompanies, updateExportFieldsForContacts, updateExportTemplateFields, updateSelectAllExportFields, updateUserDefinedCheckedFlags,
	exportTemplateIdSelectionHandler, updateExcludeMailed, updateExcludeContactsWithNoEmail, updateExcludeContactsWithNoConfirmedName, saveAllExportFieldsOnEdit,
	updateIsProjectChecked, updateIsCompanyChecked, updateIsContactChecked, updateClearAllExportFields, updateExportFieldsTextInputFilter, updateFieldsFilter,
	updateExistingValues, updateExportRolesFilter, updateRoleTextInputFilter, updateSelectUnselectAllRoleFilterOptions, updateExportSelectAllFavourites,
	updateExportRoles, updateExportIncludeRolesFilter, updateExportSortBy, updateSizeOfDelivery, updateIsExportCountAPILoaded, updateMarkAsMailed,
	updateExportFromDate, updateExportToDate, updateExportCountQuery, resetExportDate, updateExportCriteria, updateCopyExport, updateExportCollection,
	updateFilteredExportList } = exportSlice.actions

export const exportReducer = exportSlice.reducer