import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, ListGroup, Row, TabContent, TabPane } from 'reactstrap';

import SearchSelector from '../../../list/searchSelector';
import { fetchCompanyRelatedCompanies, fetchJointProjects, updateSelectorControlTags, updateSelectorFavUnfav } from '../../../apiclient';
import SearchListRow from '../../../list/searchListRow';
import TagsModal from '../../../list/tagsModal';
import { updateIsJointProjectViewed, updateJointProjects, updateListSelection, updateSelectedTagDetails, updateShowMoreOffsetLimit } from '../../../redux/reducer';
import { selectorControllerArchive } from '../../../../search/apiclient';
import ColoredScrollbars from '../../../../common/js/coloredScrollbars';
import _ from 'lodash';

const CompanyRelationshipRoles = (props) => {
    const details = useSelector((state) => state.project)
    const [relatedCompanies, setRelatedCompanies] = useState(details?.companyRelatedCompanies)
    const [jointProjects, setJointProjects] = useState(details?.jointProjects?.projects)
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const [activeSort, setActiveSort] = useState('Joint')
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    const [modal, setModal] = useState(false)
    const [totalSelectedCount, setTotalSelectedCount] = useState(0)
    const userData = useSelector(state => state.user.user)
    const settings = useSelector((state) => state.settings)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const location = useLocation().pathname;

    useEffect(() => {
        setRelatedCompanies(details && details.companyRelatedCompanies && details.companyRelatedCompanies)
    }, [details && details.companyRelatedCompanies, details.isJointProjectViewed])

    useEffect(() => {
        setJointProjects(details?.jointProjects?.projects)
    }, [details && details.jointProjects])


    const handleCommonSort = (sort) => {
        setActiveSort(sort == '-count_project_id' ? 'Joint' : 'Latest')
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateListSelection({ count: 0, entityType: 'relatedCompaniesJointProjects' }))
        setShowMoreClicked(true)
    }

    useEffect(() => {
        if (showMoreClicked) {
            if (details?.isJointProjectViewed) {
                fetchJointProjects(location.split('/')[4], details?.companyIdForJointProjects, props.columnOneRole, props.columnTwoRole, dispatch, details)
            } else {
                let sort = activeSort === 'Joint' ? '-count_project_id' : '-max_project_last_published'
                fetchCompanyRelatedCompanies(location.split('/')[4], props.columnOneRole, props.columnTwoRole, dispatch, sort, details)
            }
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);


    const toggle = () => {
        setDropDownOpen(!dropdownOpen)
    }

    useEffect(() => {
        setTotalSelectedCount(details && details.selectorControlSelectionCount === undefined ? totalSelectedCount : details && details.selectorControlSelectionCount)
    }, [details && details.selectorControlSelectionCount])

    const getTotalRows = () => {
        let totalCount = 0
        if (details.isJointProjectViewed || jointProjects?.length > 0) {
            totalCount = details?.jointProjectsCount
        }
        else {
            totalCount = details?.companyRelatedCompaniesCount
        }
        return totalCount
    }

    const getUniqueSelectedList = () => {
        let objectIds = []
        if (details.isJointProjectViewed || jointProjects?.length > 0) {
            jointProjects && jointProjects.map((role, index) => {
                if (role.is_selected) {
                    objectIds.push(role.project_id)
                }
            })
            objectIds = [...new Set(objectIds)]
        }
        else {
            relatedCompanies && relatedCompanies.map((role, index) => {
                if (role.is_selected) {
                    objectIds.push(role.company_id)
                }
            })
            objectIds = [...new Set(objectIds)]
        }

        return objectIds
    }

    const handleFavChangeSelection = (action) => {
        const payload = {
            offset: 0,
            object_type: details.isJointProjectViewed ? 'project' : 'company',
            favourite: action === "Favourite" ? true : false,
        }
        // let view_company_id
        if (details && details.selectorControlSelectionCount) {
            payload['selected_records'] = details && details.selectorControlSelectionCount
        }
        if (details.includeIds && details.includeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] - details && details.includeIds.length
            if (details.isJointProjectViewed) {
                payload['project_id'] = details && details.includeIds
            } else {
                payload['company_id'] = details && details.includeIds
            }
        }
        if (details.excludeIds && details.excludeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] + details && details.excludeIds.length
            payload['exclude_ids'] = details && details.excludeIds
        }
        payload['identifier'] = details.isJointProjectViewed ? 'joint_projects' : 'relationships'
        let role1 = props.columnOneRole === 0 ? details?.relationshipRoleIdsFirstList : props.columnOneRole
        let role2 = props.columnTwoRole === 0 ? details?.relationshipRoleIdsSecondList : props.columnTwoRole
        payload['prequery'] = { 'company_id': { 'operator': '=', 'value': details && details.detailsPage.company_id }, 'role_code': { 'operator': '=', 'value': role1 } }
        if (details.isJointProjectViewed) {
            payload['query'] = { 'company_id': { 'operator': '=', 'value': details.companyIdForJointProjects }, 'role_code': { 'operator': '=', 'value': role2 } }
            // view_company_id = details.companyIdForJointProjects
        }
        else {
            payload['query'] = { 'company_id': { 'operator': '!=', 'value': details && details.detailsPage.company_id }, 'role_code': { 'operator': '=', 'value': role2 } }
        }
        if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
            delete payload['selected_records']
        }
        else {
            payload.selected_records = parseInt(payload.selected_records)
        }
        payload['sort'] = activeSort === 'Joint' ? '-count_project_id' : '-max_project_last_published'
        // payload['view_company_id'] = view_company_id
        updateSelectorFavUnfav(payload['object_type'] === 'project' ? 'projects' : 'companies', details.detailsPage.company_id, payload, dispatch, details)
        handleDropDownItemClick(0)
    }

    const handleArchiveChangeSelection = (action) => {
        const payload = {
            offset: 0,
            object_type: 'project',
            archive: action ? true : false,
            companyId: details && details.detailsPage.company_id
        }
        // let view_company_id
        if (details && details.selectorControlSelectionCount) {
            payload['selected_records'] = details && details.selectorControlSelectionCount
        }
        if (details.includeIds && details.includeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] - details && details.includeIds.length
            payload['project_id'] = details && details.includeIds
        }
        if (details.excludeIds && details.excludeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] + details && details.excludeIds.length
            payload['exclude_ids'] = details && details.excludeIds
        }
        payload['identifier'] = 'joint_projects'
        let role1 = props.columnOneRole === 0 ? details?.relationshipRoleIdsFirstList : props.columnOneRole
        let role2 = props.columnTwoRole === 0 ? details?.relationshipRoleIdsSecondList : props.columnTwoRole
        payload['prequery'] = { 'company_id': { 'operator': '=', 'value': details && details.detailsPage.company_id }, 'role_code': { 'operator': '=', 'value': role1 } }
        payload['query'] = { 'company_id': { 'operator': '=', 'value': details.companyIdForJointProjects }, 'role_code': { 'operator': '=', 'value': role2 } }
        // view_company_id = details.companyIdForJointProjects
        if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
            delete payload['selected_records']
        }
        else {
            payload.selected_records = parseInt(payload.selected_records)
        }
        // payload['view_company_id'] = view_company_id
        selectorControllerArchive(payload, props.columnOneRole, props.columnTwoRole, dispatch, {
            settings: settings,
            details: details,
            userData: user
          })
        handleDropDownItemClick(0)
    }

    const handleDownloadPDFSelection = () => {

    }

    const handleDropDownItemClick = (count) => {
        dispatch(updateListSelection({ count, entityType: 'relatedCompaniesJointProjects' }))
        if (count) {
            setDropDownOpen(false)
        }
    }

    const handleTagsSelection = () => {
        setModal(!modal)
    }

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setModal(!modal)
        handleDropDownItemClick(0)
    }

    const handleTagsSubmit = () => {
        let type = details.isJointProjectViewed ? 'projects' : 'companies'
        let payload = {
            object_type: type,
        }
        // let view_company_id
        if (details?.selectedTagsDetails?.tagIds?.length > 1 && details?.selectedTagsDetails?.tagIds?.includes(0)) {
            let tag_name = []
            let tag_id = []
            _.map(details?.selectedTagsDetails?.tagIds, (tagId, index) => {
                if (tagId === 0) {
                    tag_name.push(details.selectedTagsDetails.tagDescriptions[index])
                } else {
                    tag_id.push(details.selectedTagsDetails.tagIds[index])
                }
            })
            if (tag_name.length) {
                payload['tag_name'] = tag_name
            }
            if (tag_id.length) {
                payload['tag_id'] = tag_id
            }
        } else if (details?.selectedTagsDetails?.tagIds?.includes(0)) {
            payload['tag_name'] = details.selectedTagsDetails.tagDescriptions
        } else {
            payload['tag_id'] = details.selectedTagsDetails.tagIds
        }
        if (details && details.selectorControlSelectionCount) {
            payload['selected_records'] = details && details.selectorControlSelectionCount
            if (details.includeIds && details.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - details.includeIds.length
                if (details.isJointProjectViewed) {
                    payload['project_id'] = details.includeIds
                } else {
                    payload['company_id'] = details.includeIds
                }

            }
            if (details.excludeIds && details.excludeIds.length > 0) {
                payload['selected_records'] = details && details.selectorControlSelectionCount + details.excludeIds
                payload['exclude_ids'] = details.excludeIds
            }
            payload['identifier'] = details.isJointProjectViewed ? 'joint_projects' : 'relationships'
            let role1 = props.columnOneRole === 0 ? details?.relationshipRoleIdsFirstList : props.columnOneRole
            let role2 = props.columnTwoRole === 0 ? details?.relationshipRoleIdsSecondList : props.columnTwoRole
            payload['prequery'] = { 'company_id': { 'operator': '=', 'value': details && details.detailsPage.company_id }, 'role_code': { 'operator': '=', 'value': role1 } }
            if (details.isJointProjectViewed) {
                payload['query'] = { 'company_id': { 'operator': '=', 'value': details.companyIdForJointProjects }, 'role_code': { 'operator': '=', 'value': role2 } }
                // view_company_id = details.companyIdForJointProjects
            }
            else {
                payload['query'] = { 'company_id': { 'operator': '!=', 'value': details && details.detailsPage.company_id }, 'role_code': { 'operator': '=', 'value': role2 } }
            }
            payload['sort'] = activeSort === 'Joint' ? '-count_project_id' : '-max_project_last_published'
            // payload['view_company_id'] = view_company_id
            if ('selected_records' in payload && payload['selected_records'] == 0) {
                delete payload['selected_records']
            }
            else {
                payload.selected_records = parseInt(payload.selected_records)
            }
        }
        handleTagsCancel()
        handleDropDownItemClick(0)
        updateSelectorControlTags(type, details.detailsPage.company_id, payload, dispatch, details)
    }

    const onBackClick = () => {
        dispatch(updateListSelection({ count: 0, entityType: 'relatedCompaniesJointProjects' }))
        dispatch(updateIsJointProjectViewed(false))
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateJointProjects([]))
    }
    const handleShowMore = () => {
        setShowMoreClicked(true)
        let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
    }


    return (
        <>
            {
                relatedCompanies && Object.keys(relatedCompanies).length ? (
                    <div className="empty-view">
                        <div className="box-view group-companies-container">
                            <Row className="m-0">
                                <TabContent activeTab={"group_companies"} className="p-0">
                                    <TabPane activeTab={"group_companies"} tabId={"group_companies"}>
                                        <div className="list-wrapper-other-pro px-3 py-2">
                                            <div className="company-groups-listing">
                                                <div className="selectorAndDropdown">
                                                    <div className="toggle-role-dropdown sort-results d-flex justify-content-between">
                                                        <div className="mb-1">
                                                            <SearchSelector
                                                                isProjectList={details.isJointProjectViewed ? true : false}
                                                                handleFavChangeSelection={handleFavChangeSelection}
                                                                handleTagsSelection={handleTagsSelection}
                                                                handleDownloadPDFSelection={handleDownloadPDFSelection}
                                                                handleDropDownItemClick={handleDropDownItemClick}
                                                                totalSelectedCount={totalSelectedCount}
                                                                totalRows={getTotalRows()}
                                                                user={userData}
                                                                entitiesCount={getTotalRows()}
                                                                activeSort={activeSort}
                                                                getUniqueSelectedList={getUniqueSelectedList}
                                                                handleArchiveChangeSelection={handleArchiveChangeSelection}
                                                            >
                                                            </SearchSelector>
                                                        </div>
                                                        {!details.isJointProjectViewed ?
                                                            <div className="sort-order-selector">
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                                                        <DropdownToggle caret>
                                                                            {activeSort}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => handleCommonSort('-max_project_last_published')} key={1} value={'Letter mail merge'}>
                                                                                Latest
                                                                            </DropdownItem>
                                                                            <DropdownItem onClick={() => handleCommonSort('-count_project_id')} key={2} value={'Letter mail merge'}>
                                                                                Joint Projects
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div> : ''}
                                                    </div>
                                                </div>
                                                <TagsModal
                                                    handleTagsCancel={handleTagsCancel}
                                                    handleTagsSubmit={handleTagsSubmit}
                                                    selectedTags={details && details.selectedTagsDetails}
                                                    showModal={modal}
                                                >
                                                </TagsModal>
                                                {
                                                    details.isJointProjectViewed ? (<p className="joint-proj-heading">
                                                        <a onClick={onBackClick} className="back-btn">
                                                            <b>Companies</b>
                                                        </a>
                                                    </p>
                                                    ) : ('')
                                                }
                                                <div className={`company-list-scrollbar ${details.isJointProjectViewed ? 'project-list' : ''}`}>
                                                    <ColoredScrollbars className="scroll-wrapper">
                                                        <Row className="result-list-container content-panel" >
                                                            <Col xs="12" lg="12" xl="12" id="searchlistAccordion" className="p-0">
                                                                <ListGroup>
                                                                    {
                                                                        details.isJointProjectViewed ? (
                                                                            <>
                                                                                {
                                                                                    jointProjects?.length > 0 && (
                                                                                        jointProjects?.map((entity, index) => {
                                                                                            const newEntity = Object.assign({
                                                                                                objectId: entity.project_id,
                                                                                                objectType: "project", onFavClick: false
                                                                                            }, entity);
                                                                                            return (
                                                                                                <SearchListRow
                                                                                                    entity={newEntity}
                                                                                                    index={index}
                                                                                                    prevNextList='relatedCompaniesJointProjects'
                                                                                                    objectType='project'
                                                                                                    key={`resultrow${index}`}
                                                                                                    sort={activeSort}
                                                                                                    columnOneRole={props.columnOneRole}
                                                                                                    columnTwoRole={props.columnTwoRole}
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ) :
                                                                            (<>{relatedCompanies && relatedCompanies.map((entity, index) => {
                                                                                const newEntity = Object.assign({
                                                                                    objectId: entity.company_id,
                                                                                    objectType: "company", onFavClick: false
                                                                                }, entity);
                                                                                return (
                                                                                    <SearchListRow
                                                                                        entity={newEntity}
                                                                                        index={index}
                                                                                        prevNextList='relatedCompaniesJointProjects'
                                                                                        objectType='company'
                                                                                        key={`resultrow${index}`}
                                                                                        sort={activeSort}
                                                                                        columnOneRole={props.columnOneRole}
                                                                                        columnTwoRole={props.columnTwoRole}
                                                                                    />
                                                                                )
                                                                            })} </>)
                                                                    }
                                                                </ListGroup>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            !details?.isJointProjectViewed && details?.isCompanyRelatedCompaniesShowMoreActive ? (
                                                                <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                                                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                                </div>
                                                            ) : ''
                                                        }
                                                        {
                                                            details?.isJointProjectViewed && details?.isJointProjectsShowMoreActive ? (
                                                                <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                                                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </ColoredScrollbars>
                                                </div >
                                            </div>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        </div>
                    </div>
                ) : ('')
            }

        </>
    )
}

export default CompanyRelationshipRoles;