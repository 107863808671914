import React, { useState, useEffect } from 'react'
import { Col, Nav, NavItem, Row, TabContent, TabPane, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavLink } from 'reactstrap'
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MyLeadsHeader from './tableHeader';
import Updates from './updates';
import Favourites from './favourites';
import History from './history';
import Tags from './tags';
import NotesDetails from './notes';
import Shares from './shares';
import Archive from './archive';
import "./myLeads.scss"
import { fetchAllRecipients, fetchNotesForAllDetails, fetchShareTeams } from '../details/apiclient';
import { updateIsActiveTabChanged, updateLeadsSearchListCount, updateMyLeadOffsetLimitValue, updateMyLeadsShowHierarchy, updateSearchList, updateSearchListForUpdates, updateSearchListSelection, updateSelectedSelectorControlValue } from './reducer';
import { fetchArchieveList, fetchFavouritesResults, fetchHistoryResults, fetchSharesResults, fetchTagDetailsById, fetchTagLists, fetchUpdatesResults } from './apiclient';
import { shareInformationDetails } from '../../redux/features/user/userslice';
import { useNavigate, useLocation } from 'react-router-dom';
import { rolesForProject, updateHierarchyBack } from '../search/redux/reducer';

const MyleadsMain = () => {
    const location = useLocation();
    const hash = location.hash.replace('#', '').split('?')[0];
    const searchParams = new URLSearchParams(location.hash.split('?')[1]);
    const emailParam = searchParams.get('email');
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)   
    const [activeTab, setActiveTab] = useState(hash);
    const [tagDetailStatus, setTagDetailStatus] = useState(false)
    const userData = useSelector(state => state.user.user)
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings)
    const myleads = useSelector((state) => state.myleads)
    const dispatch = useDispatch();
    let navigate = useNavigate()


    const toggleTab = (activeTab) => {
        dispatch(updateSearchList([]))
        dispatch(updateSearchListForUpdates([]))
        navigate("/app/myleads#" + activeTab)
        dispatch(updateIsActiveTabChanged(true))
    }

    useEffect(() => {
        // To update document title
        document.title = 'Barbour ABI'
    }, []);

    useEffect(() => {
        if (hash || emailParam) {
            setActiveTab(hash || 'updates');
        }
    }, [hash, emailParam]);

    useEffect(() => {
        if (!search.hierarchyBack) {
            dispatch(updateSearchList([]))
            dispatch(updateSearchListForUpdates([]))
            dispatch(rolesForProject([]))
            dispatch(updateLeadsSearchListCount(0))
            dispatch(updateMyLeadsShowHierarchy({ showProjectHierarchy: false }))
            dispatch(updateSelectedSelectorControlValue({ count: 0, list: activeTab === 'updates' ? 'myLeadsUpdates' : '' }))
            dispatch(updateSearchListSelection({ count: 0, list: activeTab === 'updates' ? 'myLeadsUpdates' : '' }))
        }
        setTagDetailStatus(!search.hierarchyBack ? false : true)
        dispatch(updateHierarchyBack(false))
        dispatch(updateSelectedSelectorControlValue({ count: 0, list: activeTab === 'updates' ? 'myLeadsUpdates' : '' }))
        dispatch(updateIsActiveTabChanged(false))
        if (!search.hierarchyBack && (activeTab === 'notes' || activeTab === 'updates' || activeTab === 'favourite' ||
            activeTab === 'history' || activeTab === 'shares' || activeTab === 'archive')) {
            dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
        } else if (activeTab === 'tags') {
            fetchTagLists(dispatch)
            if (!search.hierarchyBack) {
                dispatch(updateMyLeadOffsetLimitValue({ offset: 0, records: 50 }));
                dispatch(shareInformationDetails({}))
            }
        }
    }, [activeTab])

    useEffect(() => {
        if (tagDetailStatus) {
            fetchAllRecipients(userData, dispatch, project)
            if ((project && project.teamListDetails === undefined) || (project && project.teamListDetails && project.teamListDetails.length === 0)) {
                fetchShareTeams(dispatch, project);
            }
        }
    }, [tagDetailStatus])


    const onClickStatus = (status) => {
        setTagDetailStatus(status)
        dispatch(shareInformationDetails({}))
    }

    return (
        <div className="tab-pane active">
            <Row className="page-title-bar">
                <Col col={12} sm={12}>
                    <h2 class="h5">My leads</h2>
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0 myleads-tab-wrapper">
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "updates" })}
                                onClick={() => { toggleTab("updates"); }}
                            >
                                Updates
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "favourite" })}
                                onClick={() => { toggleTab("favourite"); }}
                            >
                                Favourites
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "history" })}
                                onClick={() => { toggleTab("history"); }}
                            >
                                History
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "tags" })}
                                onClick={() => { toggleTab("tags"); }}
                            >
                                Tags
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "notes" })}
                                onClick={() => { toggleTab("notes"); }}
                            >
                                Notes
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "shares" })}
                                onClick={() => { toggleTab("shares"); }}
                            >
                                Shares
                            </NavLink>
                        </NavItem>
                        {settings?.preferences?.archiving_enabled ? <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "archive" })}
                                onClick={() => { toggleTab("archive"); }}
                            >
                                Archive
                            </NavLink>
                        </NavItem> : ('')}
                    </Nav>
                    <TabContent activeTab={activeTab} className="p-0">
                        <TabPane tabId="updates">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <Updates activeTab={activeTab} />
                            </div>
                        </TabPane>
                        <TabPane tabId="favourite">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <Favourites activeTab={activeTab} />
                            </div>
                        </TabPane>
                        <TabPane tabId="history">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <History activeTab={activeTab} />
                            </div>
                        </TabPane>
                        <TabPane tabId="tags">
                            <div className="selected-col p-0">
                                {tagDetailStatus && (<MyLeadsHeader activeTab={activeTab} onClickStatus={onClickStatus} />)}
                                <Tags
                                    activeTab={activeTab}
                                    onClickStatus={onClickStatus}
                                    tagDetailStatus={tagDetailStatus}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="notes" className="tab-notes">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <NotesDetails activeTab={activeTab} />
                            </div>
                        </TabPane>
                        <TabPane tabId="shares">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <Shares activeTab={activeTab} />
                            </div>
                        </TabPane>
                        <TabPane tabId="archive" className="tab-archive">
                            <div className="selected-col p-0">
                                <MyLeadsHeader activeTab={activeTab} />
                                <Archive activeTab={activeTab} />
                            </div>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </div>
    )
}


export default MyleadsMain