import React, { useEffect, useState } from 'react'
import { InputGroup } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';

import { addTags, unLinkTags } from '../../apiclient';
import Icon from '../../../common/js/icon';

const ActivitiesTagDetails = () => {
    const project = useSelector((state) => state.project)
    const [multiInputValue, setMultiInputValue] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [labelOptionList, setLabelOptionList] = useState([])
    const [labelNames, setLabelNames] = useState([])
    const [labelIds, setLabelIds] = useState([])
    const [labels, setLabels] = useState(project && project.labelTagsDetail)
    const user = useSelector((state) => state.user.user)
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const handleKeyDown = (event, value) => {
        if (event.keyCode === 13) {
            addTagButton()
        }
    };

    const addTagButton = () => {
        if (labelNames && labelNames.length > 0) {
            let labelsPayload = { id: project.detailsPage.objectId, type: project.detailsPage.objectType, ids: labelIds, description: labelNames }
            addTags(labelsPayload, dispatch, userData)
            setLabelNames([])
            setLabelIds([])
            setMultiInputValue([])
            setInputValue('')
        }
    }

    const handleLinkLabelChange = (values) => {
        let labelValues = []
        let labelId = []
        let labelArray = []
        setMultiInputValue(values)
        values && values.map(value => {
            labelArray.push(value.value)
            labelValues.push({ label: value.label, value: value.value, id: value.tag_id ? value.tag_id : 0 })
            labelId.push(value.tag_id ? value.tag_id : 0)
        })
        setLabelNames(labelArray)
        setLabelIds(labelId)
        setInputValue('')
    }

    const handleUnLinkTags = (value) => {
        let unLinkLabelIds = []
        let unLinkLabelValues = []
        unLinkLabelIds.push(value['id'])
        unLinkLabelValues.push(value['value'])
        let unlinkLabelsPayload = { id: project.detailsPage.objectId, type: project.detailsPage.objectType, "ids": unLinkLabelIds, description: unLinkLabelValues }
        unLinkTags(unlinkLabelsPayload, dispatch, userData)
    }

    useEffect(() => {
        let labelsList = []
        let tagList = []
        if (project && project.labelTagsAllDetail && project.labelTagsAllDetail.length > 0) {
            project && project.labelTagsAllDetail.map(function (label, index) {
                labelsList.push({ value: label.tag_name, label: label.tag_name, isLabelShared: label.is_shared, tag_id: label.tag_id })
            })
        }
        setLabelOptionList(labelsList)
        if (project && project.labelTagsDetail && project.labelTagsDetail.length > 0) {
            project && project.labelTagsDetail && project.labelTagsDetail.map(label => {
                tagList.push({ value: label.tag_name, label: label.tag_name, is_shared: label.is_shared, tag_id: label.tag_id, tagged_by: label.tagged_by, created_by: label.created_by })
            })
        }
        setLabels(tagList)
    }, [project])

    const shareOptionsRenderer = (option) => {
        return (
            <div id={`option-${option.value}`} key={`key-${option.value}`} className={`${option.isLabelShared ? "shared-label-block" : "label-block"}`}>
                <span><Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1" /> {option.label}</span>
            </div>
        )
    }

    return (
        <div className="share-details label-list p-3">
            <div className="share-details-wrapper">
                <InputGroup>
                    <Icon color="#cfcfcf" size={25} icon="icon-gen-plus-rounded" className="me-2 circle-border" />
                    <CreatableSelect
                        options={labelOptionList}
                        promptText={(label) => `${label}`}
                        inputValue={inputValue}
                        isClearable
                        formatOptionLabel={(option) => shareOptionsRenderer(option)}
                        isMulti
                        value={multiInputValue}
                        placeholder={<span className='label-placeholder'> Create new tag </span>}
                        onChange={(newValue) => handleLinkLabelChange(newValue)}
                        onInputChange={(newValue) => setInputValue(newValue)}
                        onInputKeyDown={handleKeyDown}
                    />
                    <div><button className="primary-btn btn-share" onClick={() => addTagButton()}>Add tag</button></div>
                </InputGroup>
            </div>
            <div className="label-details-wrapper">
                {
                    labels && labels.map(function (label, index) {
                        return (
                            <span key={`label-list-${index}`} className={`${label.is_shared ? 'shared-labels-list-wrapper' : 'labels-list-wrapper'}`}>
                                <a className={`${label.is_shared ? 'shared-labels-list' : 'labels-list'}`} id={`label-${index}`}>
                                    <Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1" /> {label.value}
                                </a>
                                {
                                    label.is_shared ? (
                                        (label.tagged_by && label.tagged_by === user?.user_id) ? (<span className="remove-label" aria-hidden="true" onClick={() => handleUnLinkTags(label)}>×</span>) :
                                        (!label.tagged_by && label.created_by === user?.user_id) ? (<span className="remove-label" aria-hidden="true" onClick={() => handleUnLinkTags(label)}>×</span>) : ('')
                                    ) : (
                                        <span className="remove-label" aria-hidden="true" onClick={() => handleUnLinkTags(label)}>×</span>
                                    )
                                }
                            </span>)
                    })
                }
            </div>
        </div>
    )
}

export default ActivitiesTagDetails