import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import ColoredScrollbars from '../common/js/coloredScrollbars';
import "./export.scss"
import { checkIfTrialCustomerUserExportedDataForToday } from '../../utils';
import moment from 'moment'
import { exportSaveSearch, fetchExportList, fetchExportRecordsCount, fetchFilteredExportList, fetchRerunFeed, fetchTemplates } from './apiclient';
import { exportTemplateSelectionHandler, updateExportCountQuery, updateExportRolesFilter, updateShowMoreOffsetLimit } from './reducer';
import getAxios from '../../utils/baseApi';
import ExportPlaceholder from '../details/exportPlaceholder';
import { updateCurrentPage } from '../search/redux/searchPeristReducer';
import { fetchLabelDataForAllDetails } from '../details/apiclient';
import _ from 'lodash';
import { extractPayloadForDatesExportCount, extractPayloadForRolesAndMailedExportCount, getSavedSearch, getTag } from './utils';
import ModalDetails from './modaldetails';
import { initializeFilterState } from '../search/filters/utils';

function checkIfFeedIsExpired(expiredDate) {
    if (expiredDate === null) {
        return false
    }
    var start = moment(expiredDate, 'DD/MM/YYYY HH:mm:ss').format('MM/DD/YYYY HH:mm:ss')
    var end = moment().utc().format('MM/DD/YYYY HH:mm:ss')
    var duration = moment.duration(moment(start).diff(moment(end)));

    return duration < 0
}

function checkIfFeedCanRerun(CreatedDate) {
    var start = moment(CreatedDate, 'DD/MM/YYYY HH:mm:ss').format('MM/DD/YYYY HH:mm:ss')
    var end = moment().utc().format('MM/DD/YYYY HH:mm:ss')
    var duration = moment.duration(moment(end).diff(moment(start)));
    return duration.hours() > 1
}

const ExportDetail = () => {
    const exports = useSelector((state) => state.export)
    const details = useSelector((state) => state.project)
    const user = useSelector((state) => state.user)
    const search = useSelector((state) => state.search)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [exportLists, setExportLists] = useState(exports?.exportList?.exports)
    const [isNewExportEnabled, setIsNewExportEnabled] = useState(false)
    const [isExportListEmpty, setIsExportListEmpty] = useState(false)
    const [filteredExportList, setFilteredExportList] = useState([])
    const [showFields, setShowFields] = useState(false)
    const [clickedExportInfo, setClickedExportInfo] = useState(null)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const handleNewExport = () => {
        navigate('/app/export/create')
        fetchTemplates(dispatch)
        exportSaveSearch(dispatch)
        dispatch(updateCurrentPage(0))
    }
    const handleRun = () => {
        fetchExportList(user?.user?.user_id, dispatch, exports)
    }

    useEffect(() => {
        async function fetchData() {
            fetchExportList(user?.user?.user_id, dispatch, exports)
            fetchLabelDataForAllDetails(dispatch)
            fetchTemplates(dispatch)
            exportSaveSearch(dispatch)
            let defaultFilter = initializeFilterState(search?.frameworkOptions, searchPersist.customerProfile, {})
            dispatch(updateExportRolesFilter(defaultFilter))
        }
        fetchData()

        // To update document title
        document.title = 'Barbour ABI'
    }, [])

    // useEffect(() => {
    //     setExportLists(exports?.exportList?.exports)
    //     setIsNewExportEnabled(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user?.user))
    //     setIsExportListEmpty(exports?.exportList?.exports?.length === 0 ? true : false)
    // }, [exports?.exportList])

    const handleRerunFeedClick = (event, feedToRerun) => {
        if (isNewExportEnabled) {
            let payload = {
                "userno": feedToRerun.created_by,
                "deliveryno": feedToRerun.deliveryno,
                "feedname": feedToRerun.feedname
            }
            fetchRerunFeed(payload, dispatch)
        }
    }

    const handleCopyFeedClick = (event, feedToCopy) => {
        if (isNewExportEnabled) {
            dispatch(updateCurrentPage(0))
            dispatch(exportTemplateSelectionHandler(feedToCopy['setting']['selectedTemplate']))
            copyFeed(feedToCopy);
        }
    }

    const copyFeed = async (payload) => {
        let exportCountPayload
        if (exports?.exportSaveSearch && Array.isArray(exports?.exportSaveSearch) && payload.setting?.collection?.type === 'SS') {
            let saveSearchList = _.clone(exports?.exportSaveSearch)
            exportCountPayload = getSavedSearch(saveSearchList, payload.setting?.collection?.key)
        }
        if (details?.labelTagsAllDetail?.length > 0 && payload.setting?.collection?.type === 'tag') {
            exportCountPayload = getTag(details?.labelTagsAllDetail, payload.setting?.collection?.key, payload.setting?.type)
        }
        await fetchExportRecordsCount(exportCountPayload, dispatch, true)
        exportCountPayload = extractPayloadForRolesAndMailedExportCount(payload, exportCountPayload);
        if (payload.setting?.from !== '' && payload.setting?.to !== '') {
            exportCountPayload = extractPayloadForDatesExportCount(exportCountPayload, payload.setting?.from, payload.setting?.to)
        }
        dispatch(updateExportCountQuery(exportCountPayload))
        let copyPayload = {
            "criteria": {
                "feedname": payload['feedname'],
                "setting": payload['setting']
            }
        }
        fetchExportRecordsCount(exportCountPayload, dispatch, false, copyPayload)
    }

    const handleDownload = (event, file) => {
        event.preventDefault()
        var axios = getAxios()
        if (file) {
            axios({
                url: file,
                method: 'GET',
                responseType: 'blob',
                crossdomain: true,
            }).then((response) => {
                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), file.substring(file.lastIndexOf('/') + 1));
                    return;
                }
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (file.includes("?X")) {
                    link.setAttribute('download', file.substring(file.lastIndexOf('/') + 1, file.indexOf('?X')));
                }
                else {
                    link.setAttribute('download', file.substring(file.lastIndexOf('/') + 1));
                }
                document.body.appendChild(link);
                link.click();
            });
        }
    }

    function handleIntercomClick(event) {
        window.Intercom('show')
    }

    useEffect(() => {
        if (exports.showMoreOffsetLimit.offset > 0) {
            fetchExportList(user?.user?.user_id, dispatch, exports)
        }
    }, [exports.showMoreOffsetLimit]);


    const handleShowMore = () => {
        let newOffset = exports.showMoreOffsetLimit.offset + exports.showMoreOffsetLimit.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: exports.showMoreOffsetLimit.records }));

    }

    useEffect(() => {
        // Update isExportListEmpty
        setIsExportListEmpty(exports?.exportList?.exports?.length > 0 ? false : true)

        // Disable Export for Trial customer after reaching Daily export limit
        setIsNewExportEnabled(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user?.user))

        // Update exportList
        let exportData = exports?.exportList?.exports
        let newExportList = exportData
        newExportList = pushTagsToExportList(exportData, newExportList);

        // Check if template is deleted
        if (newExportList?.length > 0) {
            if (exports?.exportStaticData.templates === undefined || exports?.exportStaticData.templates?.length === 0) {
                fetchTemplates()
            } else {
                newExportList = _.map(newExportList, (item) => {
                    let templateData = exports?.exportStaticData.templates.find(
                        (x) => x.template_name === item.setting?.selectedTemplate
                    );
                    item = {
                        ...item,
                        isTemplateDeleted: templateData && !templateData.length > 0
                    }
                    return item
                });
            }
        }
        setExportLists(newExportList)

        // Update filteredExportList
        const updateExportList = () => {
            let filteredExportIds = []
            if (Array.isArray(exports?.exportList?.exports)) {
                _.map(exports?.exportList?.exports, (item) => {
                    if (item['status'] === 0 || item['status'] === 1) {
                        filteredExportIds.push(item.feedno)
                    }
                })
                fetchFilteredExportList(dispatch, filteredExportIds)
            }
        };

        // Set up an interval to call the method every 1 minute (60,000 milliseconds)
        const intervalId = setInterval(updateExportList, 60000);

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [exports?.exportList]);

    useEffect(() => {
        // Update exportList
        let exportData = exports?.exportList?.exports
        let newExportList = []
        newExportList = pushTagsToExportList(exportData, newExportList);
        setExportLists(newExportList)
    }, [details.labelTagsAllDetail])

    const pushTagsToExportList = (exportData, newExportList) => {
        if (exportData && Array.isArray(exportData) && exportData.length > 0 && details.labelTagsAllDetail?.length > 0) {
            newExportList = exportData.map((item) => {
                if (item?.setting.collection.type === 'tag') {
                    var label = details.labelTagsAllDetail.filter((x) => x.tag_id === item.setting?.collection.key);
                    item = {
                        ...item,
                        setting: {
                            ...item.setting,
                            collection: {
                                ...item.setting?.collection,
                                name: label.length > 0 ? label[0].tag_name : ''
                            }
                        }
                    };
                }
                return item;
            });
        }
        return newExportList;
    }

    const handleShowFields = (exportId) => {
        if (!showFields) {
            document.addEventListener('click', handleOutsideClick, false);
        } else {
            document.removeEventListener('click', handleOutsideClick, false);
        }
        setShowFields(prevState => !prevState.showFields)
        setClickedExportInfo(exportId)
    }

    const handleOutsideClick = (e) => {
        if (inputRef.current.contains(e.target)) {
            return;
        }
    }

    const handleCloseClick = () => {
        setShowFields(!showFields)
    }

    const getFormattedDate = (dateToConvert) => {
        if (dateToConvert) {
            let dateSplit = dateToConvert.split('-')
            if (dateSplit[0].length == 4) {
                return moment(dateToConvert, 'YYYY-MM-DD').format('DD-MMM-YYYY')
            } else {
                return moment(dateToConvert, 'DD-MM-YYYY').format('DD-MMM-YYYY')
            }
        }
    }

    const exportAllData = () => {
        let that = this
        return (
            <div className="export-list-container">
                <div className="export-list-block">
                    <div className='export-table'>
                        <div className="export-table-header row">
                            <div className="col-12 col-sm-12 col-md-3">Export name</div>
                            <div class="col-12 col-sm-12 col-md-2">Data range</div>
                            <div class="col-12 col-sm-12 col-md-1">Records</div>
                            <div class="col-12 col-sm-12 col-md-2">Date created</div>
                            <div class="col-12 col-sm-12 col-md-2">Status</div>
                            <div class="col-12 col-sm-12 col-md-2">Action</div>
                        </div>
                        <div className="export-details-wrapper">
                            <ColoredScrollbars className="details-panel-scrollbar">
                                {
                                    exportLists?.length > 0 || isExportListEmpty ?
                                        exportLists?.length > 0 && !isExportListEmpty ?
                                            (exportLists?.map((item, index) =>
                                                <div className="export-table-list-container" key={index}>
                                                    <div className="export-detail-block">
                                                        <div className="m-0 row">
                                                            <div className="col-12 col-sm-12 col-md-3">
                                                                <span class="export-icon" className={`export-icon ${(item.setting?.type === 'Projects' || item.setting?.type === 'project' || item.setting?.type === 'Project') ? 'projects-' + item.setting?.mediaType : item.setting?.type === 'Companies' || item.setting?.type === "Company" || item.setting?.type === "company" ? 'company-' + item.setting?.mediaType : (item.setting?.type === "Contacts") ?
                                                                    'contacts-' + item.setting?.mediaType :
                                                                    ''}`}></span>
                                                                <div className="export-name">{item.feedname}
                                                                    {
                                                                        item.setting?.mediaType === "csv" ? (
                                                                            <span className="saved-src-namelist">
                                                                                <span className="export-more" onClick={() => handleShowFields(item.feedno)}></span>
                                                                                <div
                                                                                    className={`${(showFields) ? 'show-label-list-wrapper' : 'hide'}`}
                                                                                    ref={inputRef}>
                                                                                    {
                                                                                        (showFields && clickedExportInfo === item.feedno) ? (
                                                                                            <ModalDetails rolesFilter={exports?.exportFilters?.includeRoles} setting={item.setting} index={index}
                                                                                                fields={item.setting.fields} displayType={item.setting.type} handleCloseClick={handleCloseClick} />
                                                                                        ) : ('')
                                                                                    }
                                                                                </div>
                                                                            </span>) : ('')
                                                                    }
                                                                </div>
                                                                {item?.setting?.collection?.name ?
                                                                    (<span className="sub-ex-name">Based on {item.setting?.collection.type == 'SS' ? 'saved search: ' : 'tag: '} <span className="feed-Name"> {item.setting?.collection.name} </span></span>) : ('')
                                                                }
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-2 d-flex align-items-center">{(item.setting?.from && item.setting?.to) ? getFormattedDate(item.setting?.from) + ' - ' + getFormattedDate(item.setting?.to) : 'No dates applied'}</div>
                                                            
                                                            {
                                                                item.status == 2 && item.filename && item.setting?.mediaType == "pdf" ? <div className="col-12 col-sm-12 col-md-1 d-flex align-items-center"> {item.setting?.exportRecordsCount}</div> :
                                                                    item.status == 2 && item.filename && item.export_csv_count != null && item.setting?.mediaType == "csv" ? <div className="col-12 col-sm-12 col-md-1 d-flex align-items-center"> {item.export_csv_count}</div> :
                                                                        <div className="col-12 col-sm-12 col-md-1 d-flex align-items-center">{""} </div>}

                                                            <div className="col-12 col-sm-12 col-md-2 d-flex align-items-center">
                                                                {moment(item.created_on, 'DD/MM/YYYY HH:mm:ss').format("DD-MMM-YYYY")}
                                                            </div>
                                                            <div className={`col-12 col-sm-12 col-md-2 ps-0 d-flex align-items-center ${item.status === 0 ? 'yellow' : item.status === 1 ? 'blue' : item.status === 3 ? 'red' : ''}`}>
                                                                <span className={`export-detail-name ${item.status == 0 ? 'yellow' : `${item.status == 1 ? 'blue' : ''}`}`}>
                                                                    {
                                                                        checkIfFeedIsExpired(item.expired_on) ? (
                                                                            <span className="grey-expired">Expired</span>
                                                                        ) : (
                                                                            item.status == 2 && item.filename ?
                                                                                (
                                                                                    <span className="grey-expired">Ready for download</span>
                                                                                ) : item.status == 1 ? "Preparing"
                                                                                    : item.status == 0 ? "Scheduled"
                                                                                        : item.status == 3 ?
                                                                                            (
                                                                                                <Link className={`${item.description == 'status: 204, desc: No result' ? 'grey' : 'red'}`} onClick={handleIntercomClick}>{item.description == 'status: 204, desc: No result' ? 'No result' : 'Error, report this'}</Link>
                                                                                            ) : ('Scheduled')
                                                                        )
                                                                    }
                                                                </span>
                                                                {/* {item.status === 0 ? 'Scheduled' : item.status === 1 ? 'Preparing' : item.status === 3 ? 'Error, report this' : ''} */}
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-2 d-flex align-items-center">
                                                                <span className={`export-detail-name ${item.status == 0 ? 'yellow' : `${item.status == 1 ? 'blue' : ''}`}`}>
                                                                    {
                                                                        ((item.status == 0 || item.status == 1) && checkIfFeedCanRerun(item.created_on)) ? (<Link className={isNewExportEnabled ? "blue" : "disabled"} onClick={(e) => handleRerunFeedClick(e, item)}>Rerun</Link>) : item.status == 0 || item.status == 1 ? ("") : (
                                                                            <span>
                                                                                {!checkIfFeedIsExpired(item.expired_on) || item.status != 3 ? ("") : (<span> | </span>)}
                                                                                {item.setting?.collection.name !== "Deleted Saved Search" && item.setting?.mediaType == "csv" ? (item.isTemplateDeleted ?
                                                                                    (<span><Link className={isNewExportEnabled ? "blue" : "disabled"} to="/app/export/create" onClick={(e) => handleCopyFeedClick(e, item)}>
                                                                                        Copy</Link> | </span>) : ("")) : ("")}
                                                                                {item.setting?.collection.name !== "Deleted Saved Search" && ((checkIfFeedIsExpired(item.expired_on) && item.status == 2) ||
                                                                                    item.status == 3) ? (<span><Link className={isNewExportEnabled ? "blue" : "disabled"} onClick={(e) => handleRerunFeedClick(e, item)}>
                                                                                        Rerun</Link></span>) : ("")}
                                                                                {checkIfFeedIsExpired(item.expired_on) || item.status == 3 ? ("") : (<span> <Link className="green" onClick={(e) => handleDownload(e, item.filename)}>
                                                                                    Download</Link></span>)}
                                                                            </span>
                                                                        )
                                                                    }
                                                                </span>
                                                            </div>
                                                            {/* <div className="col-12 col-sm-12 col-md-1 ps-0 d-flex align-items-center" onClick={()=>{onRerunClick()}}>Rerun</div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : (<span class="d-block p-4">You have no exports, to create one click on New Export.</span>) :
                                        <ExportPlaceholder />

                                }
                                {exports?.isShowMoreActive ? (
                                    <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                        <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                    </div>
                                ) : ''}
                            </ColoredScrollbars>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="tab-pane active">
                <Row className="page-title-bar">
                    <Col col={12} sm={12}>
                        <h2 className="h5">Export data</h2>
                        <span className="sub-title">Exports will expire after 24 hours</span>
                    </Col>
                </Row>
                <div className='content-wrapper'>
                    <div className='data-export-tab'>
                        <div className='data-export-search-panel media'>
                            <div className="media-body">
                                <Row className="media-heading">
                                    <Col md='10' lg='10' class="src-button">
                                        <Button className={`btn-data-export ${exportLists && exportLists.length > 0 || isExportListEmpty ? '' : "disabled"}`} onClick={() => handleNewExport()}>New export</Button>
                                        <Button className={`btn-data-export ms-3 ${exportLists && exportLists.length > 0 || isExportListEmpty ? '' : "disabled"}`} onClick={() => handleRun()}>Run</Button>
                                    </Col>
                                    <Col md='2' lg='2' className="download-help">
                                        <a href="http://help.barbour-abi.com/barbour-abi/export/mail-merge-sample-templates" target="_blank">Get support with mail merges</a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='export-results'>
                {exportAllData()}
            </div>


        </>
    )
}

export default ExportDetail
