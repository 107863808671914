import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Sort from './searchSort'
import SearchList from './searchlist'
import SearchSelector from './selectorDropdown'
import { updateRemoveFilterToggle } from './redux/reducer'
import { fetchRolesForCompanyId, fetchSearchListCount } from './apiclient'
import ProjectHierarchy from './hierarchyList/projectHierarchy'
import SearchListResult from './searchListResult'
import CompanyHierarchy from './hierarchyList/companyHierarchy'
import ContactHierarchy from './hierarchyList/contactHierarchy'
import "./searchPanel.scss"
import './sort.scss'
import { Col, Input, Label, Row, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Icon from '../common/js/icon'
import MapView from '../map'
import { updateIsMyleadsListView } from '../myleads/reducer'
import SearchFilterModal from './filters'
import { initializeFilterState } from './filters/utils'
import { updateFilterState, updateProfileStore } from './filters/redux/reducer'
import { TOURPROJECTLISTVIEW } from '../../utils/tour'
import { preProcessorQuery } from './filters/preProcessorQuery'
import { addNewSavedSearch, editSavedSearch } from '../savedsearch/apiclient'
import { updatedSelectedSavedSearch, updateIsListView, updateLogin, updateLoginRedirectDefaultSaveSearch } from './redux/searchPeristReducer'
import { initializeSavedSearchFilterState } from '../savedsearch/tabs/utils'

const SearchFormIndex = () => {
  const searchPersist = useSelector((state) => state.searchPersist)
  const search = useSelector((state) => state.search)
  const project = useSelector((state) => state.project)
  const myleads = useSelector((state) => state.myleads)
  const filter = useSelector((state) => state.filter)
  const user = useSelector((state) => state.user.user);
  const userData = useSelector((state) => state.user);
  const savedsearch = useSelector((state) => state.savedsearch)
  const dispatch = useDispatch()
  const [listMapToggle, setListMapToggle] = useState(searchPersist.isListView)
  const [listMyLeadsMapToggle, setMyLeadsListMapToggle] = useState(myleads.isListView)
  const [entityFlag, setEntityFlag] = useState(false)
  const [buttonClicked, setButtonClicked] = useState(false)
  const settings = useSelector((state) => state.settings)
  const [listName, setListName] = useState(project.isProjectSelected ? "groupedCompanyProjects" : 'searchList')
  const [isSavedSearchActive, setIsSavedSearchActive] = useState(false);
  const [saveSearchName, setSaveSearchName] = useState('');
  const [previousSavedSearchName, setPeviousSavedSearchName] = useState('');
  const [id, setId] = useState(null);
  const [modal, setModal] = useState(false);
  const [createdBy, setCreatedBy] = useState(null);
  const [saveSearchObjectType, setSaveSearchObjectType] = useState("");
  const [saveSearchQuery, setSaveSearchQuery] = useState({});
  const textSearchRef = useRef(null);

  useEffect(() => {
    // To update document title
    document.title = 'Barbour ABI'
  }, [])

  useEffect(() => {
    if (search?.listOfSelectedObjectIds?.length > 0
      || search?.fetchRolesForProjects?.roleGroups?.length > 0
      || search?.projectsForCompanyId?.length > 0 || search?.peopleProjectsList?.length > 0
      || search?.groupCompanyProjects?.length > 0) {
      setEntityFlag(true)
      setPrevNextList()
    }
  }, [search.listOfSelectedObjectIds, search.fetchRolesForProjects, search.projectsForCompanyId, search.peopleProjectsList, search.groupCompanyProjects])


  const handleListView = () => {
    setListMapToggle(!listMapToggle)
    setMyLeadsListMapToggle(!listMyLeadsMapToggle)
    dispatch(updateIsListView(!listMapToggle))
    dispatch(updateIsMyleadsListView(!listMyLeadsMapToggle))
  }

  useEffect(() => {
    project.isProjectSelected ? setEntityFlag(true) : setEntityFlag(false)
  }, [project.isProjectSelected, search.groupCompanyProjects])

  const handleToggleChange = () => {
    setButtonClicked(true)
    dispatch(updateRemoveFilterToggle(!search.removeFilterToggle))
  }

  useEffect(() => {
    if (buttonClicked) {
      if (search.showCompanyHierarchy) {
        fetchRolesForCompanyId(search.objectId, search, searchPersist, dispatch)
      } else {
        var formValues = preProcessorQuery('', filter.filtersState, search?.showSelectedProjects ? "project" : searchPersist.appliedContext, search, searchPersist)
        fetchSearchListCount(formValues, dispatch, search, settings, userData);
      }
      setButtonClicked(false)
    }
  }, [buttonClicked])

  useEffect(() => {
    if (search?.listOfSelectedObjectIds?.length > 0
      || search?.fetchRolesForProjects?.roleGroups?.length > 0
      || search?.projectsForCompanyId?.length > 0 || search?.peopleProjectsList?.length > 0
      || search?.groupCompanyProjects?.length > 0) {
      setEntityFlag(true)
    }
  }, [search.backObjectId])

  useEffect(() => {
    setPrevNextList()
  }, [search.hierarchyBack, search.showProjectHierarchy, search.showCompanyHierarchy])

  useEffect(() => {
    setListMapToggle(searchPersist.isListView)
  }, [searchPersist.isListView])

  useEffect(() => {
    if (!filter.profileStore && searchPersist.customerProfile) {
      let defaultFilter = initializeFilterState(search?.frameworkOptions, searchPersist.customerProfile, {})
      dispatch(updateFilterState(defaultFilter))
      dispatch(updateProfileStore(true))
    }
  }, [searchPersist?.customerProfile])

  useEffect(() => {
    if (filter.profileStore && !searchPersist.saveSeachRedirectionApplied && searchPersist.login) {
      if (user.default_saved_search) {
        initializeSavedSearchFilterState(user.default_saved_search, searchPersist, filter, dispatch)
        dispatch(updateLogin(false))
        dispatch(updateLoginRedirectDefaultSaveSearch(true))
      }
    }
  }, [filter.profileStore])

  useEffect(() => {
    if (searchPersist?.selectedSaveSearch?.saved_search_name) {
      setSaveSearchName(searchPersist?.selectedSaveSearch?.saved_search_name)
      setPeviousSavedSearchName(searchPersist?.selectedSaveSearch?.saved_search_name)
    }
    else {
      setSaveSearchName("")
      setPeviousSavedSearchName("")
    }
    if (searchPersist?.selectedSaveSearch?.saved_search_id) {
      setId(searchPersist?.selectedSaveSearch?.saved_search_id)
    }
    if (searchPersist?.selectedSaveSearch?.created_by) {
      setCreatedBy(searchPersist?.selectedSaveSearch?.created_by)
    }
    if (searchPersist?.selectedSaveSearch?.object_type) {
      setSaveSearchObjectType(searchPersist?.selectedSaveSearch?.object_type)
    }
    if (searchPersist?.selectedSaveSearch?.query) {
      setSaveSearchQuery(searchPersist?.selectedSaveSearch?.query)
    }
    if (searchPersist?.filterQuery?.query) {
      setSaveSearchQuery(searchPersist?.filterQuery?.query)
    }
  }, [searchPersist?.selectedSaveSearch, searchPersist?.filterQuery])

  useEffect(() => {
    if (saveSearchName.trim().length > 0) {
      dispatch(updatedSelectedSavedSearch(savedsearch.detailsPage[0]))
    }
  }, [savedsearch.detailsPage])

  const setPrevNextList = () => {
    setListName(search?.listOfSelectedObjectIds?.length > 0 ? 'selectedSearchList'
      : search.navigationBack === 'relationClick' && search?.fetchRolesForProjects?.roleGroups?.length > 0 ? 'projectHierarchicalSearchList'
        : search?.projectsForCompanyId?.length > 0 ? 'companyHierarchicalSearchList'
          : project.isProjectSelected ? "groupedCompanyProjects" : 'searchList')
  }

  const getSearchListResult = () => {
    return (<SearchListResult
      currentOffset={0}
      type={project.isProjectSelected ? 'project' : searchPersist.appliedContext}
      isListMapButtonFlag={listMapToggle}
      prevNextList={project.isProjectSelected ? "groupedCompanyProjects" : 'searchList'}
    />)
  }
  const toggle = () => {
    setIsSavedSearchActive(!isSavedSearchActive);

  }
  const handleSaveSearchNameChange = (event) => {
    setSaveSearchName(event.target.value);
  };
  const handleCancel = () => {
    // Handle cancel action
    setModal(false);
  };
  const handleKeyDown = (event) => {
    if (event.keyCode == 13) {
      event.preventDefault()
      let display = searchPersist.appliedContext;
      if (previousSavedSearchName === saveSearchName) {
        setModal(!modal)
      } else if (previousSavedSearchName !== saveSearchName) {
        handleSubmit(false, event)
      } else {
        handleSubmit(true, event)
      }
    }
  }

  const handleEditModal = (event) => {
    event.preventDefault()
    let display = searchPersist.appliedContext;
    if (previousSavedSearchName == saveSearchName) {
      setModal(!modal)
    } else if (previousSavedSearchName != saveSearchName) {
      handleSubmit(false, event)
    } else {
      handleSubmit(true, event)
    }
  }

  const handleSubmit = (isEdit, event) => {
    let payload = {}
    let tempFilter = filter.filtersState[searchPersist.appliedContext]
    if (tempFilter?.location?.active && tempFilter?.location?.location !== "") {
      if (tempFilter?.location?.location.toLowerCase() == "Current Location".toLowerCase()) {
        payload['project_current_location'] = true
      } else {
        payload["project_location_display"] = tempFilter?.location?.location
      }
    }
    if (tempFilter?.companyLocation?.active && tempFilter?.companyLocation?.location !== "") {
      if (tempFilter?.companyLocation?.location?.toLowerCase() == "Current Location".toLowerCase()) {
        payload['company_current_location'] = true
      } else {
        payload["company_location_display"] = tempFilter?.companyLocation?.location
      }
    }
    payload['saved_search_name'] = saveSearchName.trim()
    payload['object_type'] = searchPersist.appliedContext
    payload['is_default'] = false
    payload["sort"] = tempFilter.sort
    payload["query"] = saveSearchQuery
    payload["project_search"] = tempFilter.projectInformation.active === true ? tempFilter.projectInformation.isAnyAll : ""
    toggle()

    if (isEdit) {
      payload['is_default'] = searchPersist?.selectedSaveSearch?.is_default
      if (searchPersist?.leagueTable?.selectedSavedSearch?.saved_search_name !== "Saved Search" && searchPersist?.leagueTable?.selectedSavedSearch?.query !== null) {
        editSavedSearch(payload, id, dispatch, savedsearch, searchPersist, userData)
      } else {
        editSavedSearch(payload, id, dispatch, savedsearch, null, userData)
      }
    } else {
      addNewSavedSearch(payload, dispatch, savedsearch, false, userData)
    }
    setModal(false);
  }

  const handleCancelSaveSearch = () => {
    setIsSavedSearchActive(!isSavedSearchActive);
  }

  return (
    <div className="sidebar-mini sidebar-collapse">
      <Row>
        <div className="main-container">
          <SearchFilterModal />
          <div className="search-list-content">
            <div className={`search-result ${searchPersist.isListView ? 'list-view-panel' : ''} `}>
              <div className="active page-title-bar">
                <div className='top-5'>
                  <div className="only-listview d-flex justify-content-between me-3">
                    {/* <div className='searchList-header'></div> */}
                    <SearchSelector />
                    <div className="d-flex justify-content-end">
                      {
                        (searchPersist.appliedContext === 'company' && search.showSelectedProjects) || (searchPersist.appliedContext === 'company' && search.showCompanyHierarchy) ? (
                          <div className="switch-panel-project-tab mt-2 me-3">
                            <div className="switch-project-tab d-flex align-items-center">
                              <span className="me-2">Remove filters</span>
                              <Input id="switch-apply-filter" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                checked={search.removeFilterToggle}
                                onClick={handleToggleChange}
                              />
                              <Label for="switch-apply-filter" className="mb-0"></Label>
                            </div>
                          </div>
                        ) : ''
                      }
                      <div className="maplist-container me-2">
                        <button onClick={handleListView} className={`listview outline-btn ${searchPersist.appliedContext === 'people' ? 'disabled' : ''}`} disabled={searchPersist.appliedContext === 'people'} data-intro={(searchPersist.appliedContext != "people" && !filter.isFilterActive) ? TOURPROJECTLISTVIEW.listmapview.intro : null} data-position={TOURPROJECTLISTVIEW.listmapview.position} data-class={TOURPROJECTLISTVIEW.listmapview.class}>
                          {listMapToggle ?
                            <span><Icon color="#ED1C24" size={20} icon="icon-gen-map" /> Map view </span> :
                            <span><Icon color="#ED1C24" size={20} icon="icon-gen-list" /> List view </span>}
                        </button>
                      </div>
                      <Sort />
                      <div className="over-map-panel" data-intro="Save the search to run again" data-position="bottom" data-class="cd-savesrc-info">
                        <div className="save-search-panel">
                          <div className="save-search-form">
                            <Button type="button" className="btn-primary-save outline-btn" onClick={toggle}
                            // disabled={props.searchCriteria.appliedCriteria.sort === SORT.companyAlphabetical || props.isSearchLoader}
                            >
                              Save search
                            </Button>
                            {isSavedSearchActive ?
                              <Form onKeyDown={handleKeyDown} className="visible-save-search-form save-src-form-group">
                                <div className="save-search-form-wrapper">

                                  <div className="src-name-field">
                                    <Input
                                      id="saved-search-textbox"
                                      type="text"
                                      placeholder="Enter name"
                                      onChange={handleSaveSearchNameChange}
                                      ref={textSearchRef}
                                      value={saveSearchName}
                                      maxLength="45"
                                    />
                                  </div>

                                  <div className="button-blocks">
                                    {saveSearchName.trim().length > 0 ? (
                                      id && user?.user_id === createdBy ? (
                                        <Button type="primary" className="btn-secondary-save outline-btn" onClick={handleEditModal}>
                                          Save
                                        </Button>
                                      ) : (
                                        <Button type="primary" className="btn-secondary-save outline-btn" onClick={() => handleSubmit(false)}>
                                          Save
                                        </Button>
                                      )
                                    ) : (
                                      <Button type="primary" className="btn-secondary-save outline-btn" disabled>
                                        Save
                                      </Button>
                                    )}
                                    <Button type="primary" className="btn-cancel" onClick={handleCancelSaveSearch}>
                                      Cancel
                                    </Button>
                                    <Modal isOpen={modal} >
                                      <ModalHeader> Save new search? <Button type="primary" className="btn-cancel" onClick={handleCancel}>Cancel</Button></ModalHeader>
                                      <ModalBody className="save-model-body">
                                        Do you want to save new search?
                                      </ModalBody>
                                      <ModalFooter>
                                        <Button className="outline-btn btn-save-cancel" onClick={() => handleSubmit(true)}>
                                          Replace
                                        </Button>
                                        <Button className="primary-btn btn-save-delete" onClick={handleCancel}>
                                          Cancel
                                        </Button>{' '}
                                      </ModalFooter>
                                    </Modal>
                                  </div>
                                </div>
                              </Form>
                              : ""}
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>
              <Row className="me-0">
                <Col>
                  {
                    searchPersist.isListView ?
                      entityFlag ?  // check nested and hierarchical 
                        ((search.showSelectedCompanies || search.showSelectedProjects) && search?.listOfSelectedObjectIds?.length > 0) ?
                          <SearchList entityFlag={entityFlag} prevNextList={project.isProjectSelected ? "groupedCompanyProjects" : 'selectedSearchList'} /> :
                          (
                            <>
                              {(search.showProjectHierarchy && searchPersist.appliedContext === 'project') || (search.showProjectHierarchy && searchPersist.appliedContext === 'company') && search?.fetchRolesForProjects?.roleGroups?.length > 0 ?
                                <ProjectHierarchy listMapToggle={listMapToggle} type={searchPersist.appliedContext} prevNextList="projectHierarchicalSearchList" /> :
                                search.showCompanyHierarchy && searchPersist.appliedContext === "company" && search?.projectsForCompanyId?.length > 0 ?
                                  <CompanyHierarchy type={searchPersist.appliedContext} prevNextList="companyHierarchicalSearchList" /> :
                                  search.showCompanyHierarchy && search?.peopleProjectsList?.length > 0 ?
                                    <ContactHierarchy listMapToggle={listMapToggle} type={searchPersist.appliedContext} prevNextList="contactHierarchicalSearchList" />
                                    :
                                    getSearchListResult()}
                            </>
                          ) :
                        getSearchListResult() : <MapView prevNextList={listName} entityFlag={entityFlag} />
                  }
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Row>
    </div>
  )
}

export default SearchFormIndex