import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalFooter, ModalHeader, ModalBody, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {updateFeedSaveSearch,deleteFeedSaveSearch} from "./apiclient"
import { capitalizeFirstLetter } from '../../utils/commonFunctions';
import { updateSavedSearchOffsetLimitValue } from './redux/reducer';


const FeedsModal = (props) => {
    const [dropdownFrequencyOpen, setFrequencyOpen] = React.useState(false);
    const [dropdownWeeklyOpen, setWeeklyOpen] = React.useState(false);
    const [frequencyType,setFrequencyType]=useState("")
    const [dayOfWeek, setDayOfWeek] = useState("");
    const [activeFeedId,setActiveFeedId]=useState("")
    const [isSaveButtonEnabled,setSaveButtonEnabled]=useState(true)
    const [isRemoveFeedButtonEnabled,setRemovedFeedButton]=useState(false)
    const [isApiCall,setApiCall]=useState(false)
    const savedsearch = useSelector((state) => state.savedsearch)
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user)

    const handleChange = (event) => {
        setSaveButtonEnabled(false)
        setDayOfWeek(event.target.value)
    };

    const handleFrequencyType = (type) => {
        setFrequencyType(type)
        if (type === "daily") {
            setSaveButtonEnabled(false)
        }
        else {
            setSaveButtonEnabled(true)
        }
    }

    useEffect(() => {
        if (isApiCall) {
            deleteFeedSaveSearch(activeFeedId, dispatch, savedsearch)
            setApiCall(false)
            props.handleCancelDelete()
            setRemovedFeedButton(false)
        }

    }, [isApiCall])

    useEffect(()=>{
        if(props?.activeSavedSearch?.feed){
            setSaveButtonEnabled(false)
            setRemovedFeedButton(true)
            setActiveFeedId(props?.activeSavedSearch?.feed.id)
            if(props?.activeSavedSearch?.feed?.configuration?.frequency){
                setFrequencyType(props?.activeSavedSearch?.feed?.configuration?.frequency)
            }
            if(props?.activeSavedSearch?.feed?.configuration?.day_of_week){
                setDayOfWeek(props?.activeSavedSearch?.feed?.configuration?.day_of_week)
            }
        }

    },[props?.activeSavedSearch?.feed])


    const handleSubmitFeedSaveSearch = () => {
        let payload={};
        if(frequencyType==="daily"){
             payload= {'schedule': {'frequency':frequencyType}, 'saved_search_id': props?.activeSavedSearch?.saved_search_id}
        }
        else{
            payload ={'schedule': {'frequency': frequencyType, 'day_of_week': dayOfWeek}, 'saved_search_id':props?.activeSavedSearch?.saved_search_id}
        }
    
        updateFeedSaveSearch(payload, dispatch, savedsearch, userData);
        props.handleCancelDelete()
        setSaveButtonEnabled(true)
    }
    const handleDeleteFeedSaveSearch=()=>{
        dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
        setApiCall(!isApiCall)
    }


    return (
        <Modal isOpen={props.isOpen} id="feedsModal" className='feedsModalBox'>
            <ModalHeader className='d-flex' toggle={props.handleCancelDelete}>
                Set notification frequency
            </ModalHeader>
            <ModalBody className="save-model-body">
                <div className="input-container mt-3">
                    <span className="d-inline-block pb-1 font-weight-bold">Create a feed</span>
                    <div className="select-type select-input-type">
                        <ButtonDropdown toggle={() => { setFrequencyOpen(!dropdownFrequencyOpen) }}
                            isOpen={dropdownFrequencyOpen} className="w-45 save-cancel-top">
                            <DropdownToggle caret className="w-100 text-left">
                               {frequencyType!==""?capitalizeFirstLetter(frequencyType):"Select frequency type"} 
                            </DropdownToggle>
                            <DropdownMenu className="w-100">
                                <DropdownItem value="Daily" onClick={() => handleFrequencyType("daily")}>Daily</DropdownItem>
                                <DropdownItem value="Weekly" onClick={() => handleFrequencyType("weekly")}>Weekly</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                    </div>

                    {frequencyType==="weekly"?(
                    <div className="input-container mt-3">
                        <span className="d-inline-block pb-1 font-weight-bold">Select a day</span>
                        <div className="select-type select-input-type">
                            <ButtonDropdown toggle={() => { setWeeklyOpen(!dropdownWeeklyOpen) }}
                                isOpen={dropdownWeeklyOpen} className="w-45">
                                <DropdownToggle caret className="w-100 text-left">
                                    {dayOfWeek !==""?capitalizeFirstLetter(dayOfWeek):"Select day of week"}
                                </DropdownToggle>
                                <DropdownMenu className="w-100">
                                    <DropdownItem value="monday" onClick={(e) => handleChange(e)}>Monday</DropdownItem>
                                    <DropdownItem value="tuesday" onClick={(e) => handleChange(e)}>Tuesday</DropdownItem>
                                    <DropdownItem value="wednesday" onClick={(e) => handleChange(e)}>Wednesday</DropdownItem>
                                    <DropdownItem value="thursday" onClick={(e) => handleChange(e)}>Thursday</DropdownItem>
                                    <DropdownItem value="friday" onClick={(e) => handleChange(e)}>Friday</DropdownItem>
                                    <DropdownItem value="saturday" onClick={(e) => handleChange(e)}>Saturday</DropdownItem>
                                    <DropdownItem value="sunday" onClick={(e) => handleChange(e)}>Sunday</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>
                    </div>
                    )
                    :""}

                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-primary" disabled={isSaveButtonEnabled} onClick={handleSubmitFeedSaveSearch}>Save</Button>
                {isRemoveFeedButtonEnabled?(<Button className="outline-red-btn btn-save-cancel ml-3" onClick={handleDeleteFeedSaveSearch}>Remove Feed</Button>) :("")}
            </ModalFooter>
        </Modal>
    )
}

export default FeedsModal;