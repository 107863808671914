import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Label, Input } from 'reactstrap';
import { updateUserSettings } from "../apiclient"

function ArchiveProjects({ active }) {

    const {
        settings_container,
        userData,
        user
    } = useSelector((state) => {
        return {
            settings_container: state.settings,
            userData: state.user.user,
            user: state.user
        }
    });

    const [isChecked, setIsChecked] = useState(settings_container.preferences.archiving_enabled)
    const dispatch = useDispatch();

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked)
    };

    useEffect(()=>{
        if (active) {
            setIsChecked(settings_container.preferences.archiving_enabled)
        }
    },[active])

    const handleSubmit = () => {
        updateUserSettings({ 'archiving_enabled': isChecked }, userData.user_id, dispatch, '', user)
    }

    return (
        <div className="archive-project-wrapper">
            <div className="content-panel p-1">
                <div className="col-md-12 col-sm-12">
                    <div className="switch-panel">
                        <div className="switch-notification">
                            <Input id="archive-project-notification" name="archiveProject" type="checkbox" checked={isChecked} onChange={e => handleCheckboxChange(e)} className="cmn-toggle cmn-toggle-round" />
                            <Label for="archive-project-notification"></Label>
                        </div>
                        <div className="notification-switch-text">
                            <p>Archive projects</p>
                        </div>
                    </div>
                    <div className="notification-other-content">
                        {(isChecked === false) ? (
                            <p>Archive projects to hide them from your search results.</p>
                        ) : (
                            <div className='toggle-details'>
                                <p>Archive projects to hide them from your search results. You will be able to review them at any time in <a href="#">My Leads - Archive</a>.</p>
                                <p>Note: A high volume of archived projects may affect search performance. Switch off archive projects if you find search too slow.</p>
                            </div>
                        )}
                    </div>

                </div>

            </div>
            <button className={"primary-btn btn-save-cancel mt-3"} onClick={() => handleSubmit()}>Save</button>

        </div>
    );
}

export default ArchiveProjects;