import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import SliderCarousel from '../sliderCarousel';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import ContactTabDetails from './contact';
import NavigationTab from '../person/navigationTab';
import './person.scss';
import { fetchDataForDetailsPage } from "../apiclient"
import { getCompanyAddress, isValidText, formatUrlWithProtocol } from '../../../utils';
import { renderMapboxImage } from '../../../utils/commonFunctions';
import { fetchDetailsPage, fetchNoDetailsPage, updatePeopleProjectRoles } from '../redux/reducer';
import { HttpErrorHandler } from '../httpErrorHandler';


const Contact = () => {

    const sliderImageArray = useRef(null);
    const location = useLocation();
    const details = useSelector((state) => state.project)
    const userData = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const companyRedirection = (payload) => {
        navigate(`/app/company/0/${payload.company_id}`
            ,
            {
                state: {
                    from: 'contactClick',
                    contactId: payload.objectId,
                    activeTab: "2",
                    previousState: location.state,
                }
            }
        );
    }
    function getFullName() {
        let fullName = '';
        if (details.detailsPage && details.detailsPage.person_first_name) {
            fullName = `${details.detailsPage && details.detailsPage.person_first_name}`
        }
        if (details.detailsPage && details.detailsPage.person_last_name) {
            fullName = `${fullName} ${details.detailsPage && details.detailsPage.person_last_name}`
        }

        return fullName
    }

    useEffect(() => {
        dispatch(updatePeopleProjectRoles([]))
        dispatch(fetchDetailsPage({}))
        dispatch(fetchNoDetailsPage(false))
        fetchDataForDetailsPage(location.pathname.split('/')[3], dispatch, false, location.pathname.split('/')[2], userData)
    }, [location])

    useEffect(() => {
        if (details && details.detailsPage && details.detailsPage.objectId) {
            let images = renderMapboxImage(details.detailsPage)
            sliderImageArray.current = images.carouselImages
            // To update document title with details title
            document.title = details?.detailsPage?.person_first_name || details?.detailsPage?.person_last_name ? getFullName() : document.title
        }
    }, [details && details.detailsPage])

    return (

        <>
        { details && Object.keys(details.detailsPage).length !== 0 ?
        <div className="details-page company-details">
            <div className="detail-page-wrapper">
                <ColoredScrollbars className="details-panel-scrollbar">
                    <Row className="header-section flex-row-reverse pb-2">
                        <Col lg="3" md="12" sm="12" className="pt-2">
                            <div className="imageSlider">
                                {details && details.detailsPage && sliderImageArray.current !== null && <SliderCarousel imageArray={sliderImageArray.current} objectType="people" />}
                            </div>
                        </Col>
                        <Col lg="9" md="12" sm="12" className="pt-2">
                            <div className="company-overview mt-2">
                                <Row className="justify-content-between project-info">
                                    <Col lg="3" md="12" sm="12" className="ps-0">
                                        <NavigationTab></NavigationTab>
                                    </Col>
                                    <Col sm="12" md="6" lg="8" className="project-details pe-0">

                                        <span class="icon-contact"></span>

                                        {details.detailsPage && details.detailsPage.person_first_name || details.detailsPage.person_last_name ?
                                            <h5>{getFullName()}</h5> : ''
                                        }

                                        <h3 className="contacts-subheader">{details.detailsPage && details.detailsPage.person_job_title}</h3>


                                        {details.detailsPage && details.detailsPage.name ?
                                            <h3 className="contacts-company"><a className="company-redirection" onClick={() => companyRedirection(details.detailsPage)}  > {details.detailsPage.name}</a> </h3>
                                            : ('')}

                                        <div className="d-flex align-content-start flex-wrap mt-1 contact-details">
                                            {isValidText(details.detailsPage && details.detailsPage.person_phone) ? (
                                                <div className="co-phone">
                                                    <span>Phone: </span>
                                                    <a href={`tel:${details.detailsPage && details.detailsPage.person_phone.replace(/ /g, '')}`}>{details.detailsPage && details.detailsPage.person_phone}</a>
                                                    {isValidText(details.detailsPage && details.detailsPage.person_phone_tps) && details.detailsPage.person_phone_tps ? (<span className="tps-badge">TPS</span>) : ("")}
                                                </div>) : ('')
                                            }
                                            {isValidText(details.detailsPage && details.detailsPage.person_mobile) ? (
                                                <div className="co-phone">
                                                    <span>Mobile: </span>
                                                    <a href={`tel:${details.detailsPage && details.detailsPage.person_mobile.replace(/ /g, '')}`}>{details.detailsPage && details.detailsPage.person_mobile}</a>
                                                    {isValidText(details.detailsPage && details.detailsPage.personMobileTps) && details.detailsPage && details.detailsPage.personMobileTps ? (<span className="tps-badge">TPS</span>) : ("")}
                                                </div>) : ('')
                                            }
                                            {isValidText(details.detailsPage && details.detailsPage.company_phone) ? (
                                                <div className="co-phone">
                                                    <span>Company phone: </span>
                                                    <a href={`tel:${details.detailsPage && details.detailsPage.company_phone.replace(/ /g, '')}`}>{details.detailsPage && details.detailsPage.company_phone}</a>
                                                    {isValidText(details.detailsPage && details.detailsPage.company_phone_tps) && details.detailsPage && details.detailsPage.company_phone_tps ? (<span className="tps-badge">TPS</span>) : ("")}
                                                </div>) : ('')}

                                            {isValidText(details.detailsPage && details.detailsPage.person_email) ? (
                                                <div className="co-email">
                                                    <span>Email: </span>
                                                            <a href={`mailto:${details?.detailsPage?.person_email}`}>{details?.detailsPage?.person_email}</a>
                                                        </div>) : ('')}
                                            {
                                                isValidText(details.detailsPage && details.detailsPage.linkedIn) ?
                                                    (
                                                        <a href={formatUrlWithProtocol(details.detailsPage && details.detailsPage.linkedIn)} className="linkedin-icon" target="_blank"></a>
                                                    ) : ("")
                                            }
                                        </div>

                                        {isValidText(details && details.detailsPage && details && details.detailsPage.company_address1) || isValidText(details && details.detailsPage && details && details.detailsPage.company_address2) || isValidText(details && details.detailsPage && details && details.detailsPage.company_address3) || isValidText(details && details.detailsPage && details && details.detailsPage.company_address4) || isValidText(details && details.detailsPage && details && details.detailsPage.company_postcode) ?
                                            (<div className="address">
                                                <span>Address: </span>
                                                {getCompanyAddress(details && details.detailsPage)}
                                            </div>) : ('')}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <ContactTabDetails />
                </ColoredScrollbars>
            </div>
        </div>
        : details.pageNotFoundFlag ? <HttpErrorHandler /> : ''}
        </>
    )
}

export default Contact;