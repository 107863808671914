import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import SliderCarousel from "../sliderCarousel";
import ColoredScrollbars from "../../common/js/coloredScrollbars";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchAllRecipients,
  fetchLabelData,
  fetchLabelDataForAllDetails,
  fetchDataForDetailsPage,
  fetchShareTeams,
  fetchRolesForProjects,
} from "../apiclient";
import ProjectDetail from "./details";
import ProjectMain from "./main";
import {
  checkIfProjectIsNew,
  getFormatedCopyrightDate,
  getFormatedDate,
} from "../../../utils";
import DonutChart from "../donutChart";
import DetailsTab from "./detailtabs/detailstabs";
import NavigationTab from "./navigationTab";
import {
  initializeCollaborationFilters,
  renderMapboxImage,
} from "../../../utils/commonFunctions";
import {
  fetchDetailsPage,
  fetchNoDetailsPage,
  initializeCollabFilter,
  isPrint,
} from "../redux/reducer";
import "../projectDetails.scss";
import { HttpErrorHandler } from "../httpErrorHandler";
import { PROJECTDETAILS } from "../../../utils/tour";
import { ABI_LOGO, PROJECT_IMG_URL } from "../../../utils/settings";

const Project = () => {
  const [isPrintOrNot, setisPrintOrNot] = useState(false);
  const sliderImageArray = useRef(null);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab ? location.state.activeTab : "1"
  );
  const user = useSelector((state) => state.user.user);
  const project = useSelector((state) => state.project);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDetailsPage({}));
    dispatch(fetchNoDetailsPage(false));
    fetchDataForDetailsPage(
      location.pathname.split("/")[3],
      dispatch,
      false,
      location.pathname.split("/")[2],
      userData
    );
    fetchLabelData(
      location.pathname.split("/")[3],
      location.pathname.split("/")[2],
      dispatch,
      userData
    );
    fetchLabelDataForAllDetails(dispatch);
    if (project && project.teams !== undefined) {
      fetchShareTeams(dispatch, project);
      fetchAllRecipients(user, dispatch, project);
    }
    if (
      project &&
      project.detailsPage &&
      !("project" in project?.collaborationFilter)
    ) {
      let data = initializeCollaborationFilters("project");
      dispatch(
        initializeCollabFilter({ collabData: data, objectType: "project" })
      );
    }
    if (project?.isPrint) {
      fetchRolesForProjects(project && project.detailsPage.objectId, dispatch, project && project.isProjectFrameWork)

    }
  }, [location]);

  useEffect(() => {
    if (project && project.detailsPage && project.detailsPage.objectId) {
      let images = renderMapboxImage(project.detailsPage);
      sliderImageArray.current = images.carouselImages;

      // To update document title with project title
      document.title =
        project && project.detailsPage && project.detailsPage.project_title
          ? project.detailsPage.project_title
          : document.title;
    }
  }, [project && project.detailsPage]);

  const activePrint = () => {
    let page = document.getElementsByTagName('body')[0];
    setisPrintOrNot(!isPrintOrNot);
    if (!isPrintOrNot) {
      page.classList.remove('noscroll');
      document.body.classList.add("print");
      dispatch(isPrint(true));
      let routingState = location.state;
      if (routingState === null) {
        let stateActiveTab = { activeTab: activeTab }
        routingState = { ...routingState, ...stateActiveTab };
      } else {
        routingState.activeTab = activeTab;
      }
      navigate(location.pathname, { state: routingState });
    } else {
      page.classList.add('noscroll');
      document.body.classList.remove("print");
      dispatch(isPrint(false));
    }
  };

  const renderLoader = () => {
    return (
      <>
        <div className="details-page project-details placeholder">
          <span className="preloader"></span>
          <div className="detail-page-wrapper">
            <div className="details-panel-scrollbar">
              <Row className="header-section pb-2 m-0">
                <Col md={9} className="ps-4 pt-3">
                  <Row className="d-flex justify-content-between">
                    <Col className="project-overview mt-2 ">
                      <span className="icon-project"></span>
                      <h2></h2>
                      <div className="address"></div>
                    </Col>
                    <Col className="pl-3">
                      <div className="dp-nav-buttons-wrapper d-flex justify-content-end">
                        <button
                          type="button"
                          className="back-to-resultpage-button btn btn-secondary"
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="dp-prev-button btn btn-secondary"
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="dp-next-button btn btn-secondary"
                        >
                          Next
                        </button>
                      </div>
                    </Col>
                  </Row>

                  <Row className="meta-data pt-2 ps-3 d-flex">
                    <Col className="pl-0">
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                    <Col>
                      <span></span>
                      <div></div>
                    </Col>
                  </Row>

                  <div className="pr-3">
                    <Row className="label-sharing-cta d-flex justify-content-between mb-3">
                      <Col className="d-inline-flex">
                        <div>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Calendar
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Share
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Notes
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-2"
                          >
                            Tag
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Archieve
                          </button>
                          <button
                            type="button"
                            className="favourite-icon btn btn-secondary me-1"
                          >
                            Favourite
                          </button>
                        </div>
                      </Col>
                      <Col className="d-inline-flex justify-content-end">
                        <button
                          type="button"
                          className="print-btn btn btn-secondary"
                        >
                          Push to CHUB
                        </button>
                        <button
                          type="button"
                          className="print-btn btn btn-secondary"
                        >
                          Print
                        </button>
                        <button
                          type="button"
                          className="feedback-icon btn btn-secondary"
                        >
                          Project enquiry
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={3} className="pr-0 image-curosal">
                  <div className="map-view">
                    {/* <img src={src} alt={alt} /> */}
                  </div>
                  <div className="location-name-distance ml-3">
                    <span className="proj-location"></span>
                    <span className="distance"></span>
                  </div>
                </Col>
              </Row>
              <div className="mt-3 ms-0 me-0 mb-3 ps-3 project-information row">
                <div className="project-info-wrapper col">
                  <div className="row p-2">
                    <div className="proj-address col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-type col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-scheme col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-devtype col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-url col col-sm-12 col-md-12 col-lg-8">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                    <div className="proj-sizes col col-sm-12 col-md-12 col-lg-4">
                      <div className="field-name"></div>
                      <div className="field-value"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const makePrint = () => {
    window.print();
  };

  return (
    <>
      {!isPrintOrNot ? (
        <>
          {project && Object.keys(project.detailsPage).length !== 0 ? (
            <div className="details-page project-details">
              <div className="detail-page-wrapper">
                <ColoredScrollbars className="details-panel-scrollbar">
                  <Row className="header-section flex-row-reverse pb-2">
                    <Col lg="3" md="12" sm="12" className="pt-2">
                      <div className="imageSlider" data-intro={PROJECTDETAILS.projectimage.intro} data-position={PROJECTDETAILS.projectimage.position} data-class={PROJECTDETAILS.projectimage.class}>
                        {project &&
                          project.detailsPage &&
                          sliderImageArray.current !== null && (
                            <SliderCarousel
                              imageArray={sliderImageArray.current}
                              objectType="project"
                            />
                          )}
                      </div>
                    </Col>
                    <Col lg="9" md="12" sm="12">
                      {project.detailsPage && (
                        <ProjectMain handlePrint={activePrint} />
                      )}
                    </Col>
                  </Row>
                  <ProjectDetail
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </ColoredScrollbars>
              </div>
            </div>
          ) : project.pageNotFoundFlag ? (
            <HttpErrorHandler />
          ) : (
            renderLoader()
          )}
        </>
      ) : (
        <div className="details-page project-details" id="print-container">
          <div className="detail-page-wrapper">
            <img className='logo-images' src={`${PROJECT_IMG_URL}${ABI_LOGO}`} />
            <Row className="top-info">
              <div className="map-view p-0">
                <img
                  src={
                    sliderImageArray.current.length > 1
                      ? sliderImageArray.current[1].url
                      : sliderImageArray.current[0].url
                  }
                />
              </div>
              <Col className="project-overview">
                <h2>
                  <span className="me-3">
                    {project &&
                      project.detailsPage &&
                      project.detailsPage.project_title}
                  </span>
                  {checkIfProjectIsNew(
                    project &&
                    project.detailsPage &&
                    project.detailsPage.project_last_published,
                    project &&
                    project.detailsPage &&
                    project.detailsPage.project_first_published
                  ) ? (
                    <div className="new-pro-flag">New</div>
                  ) : (
                    ""
                  )}
                  {project &&
                    project.detailsPage &&
                    project.detailsPage.project_stopped_display ? (
                    <div className="project-build-phase-pro-flag on-hold-flag">
                      {project &&
                        project.detailsPage &&
                        project.detailsPage.project_stopped_display}
                    </div>
                  ) : project &&
                    project.detailsPage &&
                    project.detailsPage.project_build_phase &&
                    ((project &&
                      project.detailsPage &&
                      project.detailsPage.project_build_phase === "on_site") ||
                      (project &&
                        project.detailsPage &&
                        project.detailsPage.project_build_phase ===
                        "active_on_site")) ? (
                          <div className="project-build-phase-pro-flag">
                            {(project &&
                              project.detailsPage &&
                              project.detailsPage.project_build_phase ===
                              "active_on_site" ?
                              "Confirmed On-site" : "Estimated On-site")}
                          </div>
                  ) : (
                    ""
                  )}
                </h2>
                <div className="proj-ref proj-location">
                  {project &&
                    project.detailsPage &&
                    project.detailsPage.project_site4}
                </div>
                <p className="mb-1 proj-ref">
                  <strong>Project reference:</strong>{" "}
                  {project &&
                    project.detailsPage &&
                    project.detailsPage.project_id}
                </p>
                {project &&
                  project.detailsPage &&
                  project.detailsPage.project_first_published ? (
                  <p className="mb-1 proj-ref">
                    <span>
                      <strong>First published:</strong>{" "}
                      {getFormatedDate(
                        project &&
                        project.detailsPage &&
                        project.detailsPage.project_first_published,
                        "DD-MMM-YYYY"
                      )}
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="copyright position-relative me-0">
              <div>
                Copyright © {getFormatedCopyrightDate(null, "YYYY")}. Barbour
                ABI Limited. All rights reserved. <br />
                <span>
                  This Barbour ABI data was printed by {user.first_name}{" "}
                  {user.last_name} at {user.company_name} on{" "}
                  {getFormatedCopyrightDate(null, "DD-MMM-YYYY")}
                </span>
              </div>
              {project &&
                project.detailsPage &&
                project.detailsPage.project_site_progression &&
                project &&
                project.detailsPage &&
                project.detailsPage.project_site_progression !== 100 &&
                project &&
                project.detailsPage &&
                project.detailsPage.project_build_phase === "active_on_site" ? (
                <Col className="site-progression">
                  <DonutChart
                    value={
                      project &&
                      project.detailsPage &&
                      project.detailsPage.project_site_progression
                    }
                  />
                  <div className="ms-1">
                    <span>Site progress:</span>
                    {(project &&
                      project.detailsPage &&
                      project.detailsPage.project_site_progression) + "%"}
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col className="mt-3 project-information">
                <div className="project-info-wrapper pb-3">
                  {<DetailsTab isPrint={isPrintOrNot} />}
                </div>
              </Col>
            </Row>
          </div>
          <div className="overlay-print">
            <NavigationTab />
            <button className="user-ctrl-btn print-btn" onClick={makePrint}>
              Print
            </button>
            <button className="user-ctrl-btn back-btn" onClick={activePrint}>
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Project;
