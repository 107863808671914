import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { checkAvailabilityOfLimit, checkAvailabilityOfOption, selectorControlValue } from '../../../utils/commonFunctions'
import Icon from '../../common/js/icon'
import _ from 'lodash';
import { fetchLabelDataForAllDetails } from '../../details/apiclient'
import TagsModal from '../../details/list/tagsModal'
import { updateIsProjectSelected, updateSelectedTagDetails } from '../../details/redux/reducer'
import { useNavigate } from 'react-router-dom'
import { rolesForProject, updateCompanyDetail, updateHierarchyBack, updatelistOfSelectedObjectIds, updateObjectData, updateObjectId, updatePeopleProjectsList, updateProjectsForCompanyId, updateRemoveFilterToggle, updateShowHierarchy } from '../../search/redux/reducer';
import { resetRelationalEntititySelectionCount, updateSelectedSelectorControlValue, updateSearchListSelection, updateLeagueShowHierarchy, updateLeagueRemoveFilterToggle } from '../reducer';
import { updateSelectorControlAction } from '../apiclient'
import { updateLeagueSelectorControlTags } from '../apiclient'
import { createNewExport } from '../../export/apiclient'
import { createDownloadPdfPayload } from '../../export/utils'
import { MAX_PDF_DOWNLOAD_LIMIT } from '../../../utils/settings'
import { checkIfTrialCustomerUserExportedDataForToday } from '../../../utils'

const LeagueSearchSelectorDropdown = (props) => {
    const exports = useSelector((state) => state.export)
    const user = useSelector((state) => state.user.user);
    const userData = useSelector((state) => state.user);
    const search = useSelector((state) => state.search)
    const leagueTableState = useSelector(state => state.leagueTable)
    const project = useSelector((state) => state.project)
    const [leagueList, setLeagueList] = useState(leagueTableState?.searchList)
    const searchPersist = useSelector((state) => state.searchPersist)
    const [booleanData, setBooleanData] = useState({
        dropdownOpen: false,
        actionsDropDownOpen: false,
        isModalOpen: false,
        modal: false
    })
    const dispatch = useDispatch()
    const [listCount, setListCount] = useState(searchPersist?.leagueTable?.selectedTopSearch?.label === "Show all" ? leagueTableState?.leagueSearchListCount : leagueTableState?.searchList.length)
    const [searchControlLimit, setSearchControlLimit] = useState(selectorControlValue(searchPersist?.leagueTable?.selectedTopSearch?.label === "Show all" ? leagueTableState?.leagueSearchListCount : leagueTableState?.searchList.length))
    const [isPdfActive] = useState(user.licenceFlag?.isExportLicence)
    const [isExportEnabled] = useState(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user))

    let statusList = ('active')
    const navigate = useNavigate();

    useEffect(() => {
        setLeagueList(leagueTableState?.searchList)
    }, [leagueTableState?.searchList])

    useEffect(() => {
        if (searchPersist?.leagueTable?.selectedTopSearch?.label === "Show all") {
            setSearchControlLimit(selectorControlValue(leagueTableState?.leagueSearchListCount))
            setListCount(leagueTableState?.leagueSearchListCount)
        } else {
            setSearchControlLimit(selectorControlValue(leagueTableState?.searchList.length))
            setListCount(leagueTableState?.searchList.length)
        }
    }, [leagueTableState?.leagueSearchListCount, leagueTableState?.searchList, searchPersist?.leagueTable?.selectedTopSearch, leagueTableState?.showCompanyHierarchy, search.showCompanyHierarchy])

    const handleDropDownItemClick = (value) => {
        if (statusList && value === '') {
            value = leagueTableState?.leagueSearchListCount
        } else {
            dispatch(resetRelationalEntititySelectionCount())
            dispatch(updateSelectedSelectorControlValue(value))
        }
        dispatch(updateSelectedSelectorControlValue(value))
        dispatch(updateSearchListSelection(value))
    }

    const handleItemSelect = (count) => {
        handleDropDownItemClick(count)
        setBooleanData(prev => ({ ...prev, dropdownOpen: false }))
    }


    const handleSelectorControlActionsClick = (action) => {

        let objectIds = getUniqueSelectedList()
        let id = 'company_id'
        let selectionEntityRelatedMode = false
        if (objectIds.length > 0) {
            const payload = {
                object_type: 'company',
                favourite: action === "Favourite" ? true : false,
                offset: 0,
                limit: 50,
            }
            payload[id] = objectIds;
            if (leagueTableState?.selectorControlSelectionCount) {
                payload['selected_records'] = leagueTableState?.selectorControlSelectionCount
            }
            if (leagueTableState.includeIds && leagueTableState.includeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] - leagueTableState?.includeIds.length
                payload[id] = leagueTableState.includeIds
            }
            if (leagueTableState.excludeIds && leagueTableState.excludeIds.length > 0) {
                payload['selected_records'] = payload['selected_records'] + leagueTableState?.excludeIds.length
                payload['exclude_ids'] = leagueTableState.excludeIds
            }
            payload['identifier'] = 'favourite'
            if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
                delete payload['selected_records']
            }
            else {
                payload.selected_records = parseInt(payload.selected_records)
            }
            updateSelectorControlAction(payload, leagueTableState, searchPersist, dispatch, selectionEntityRelatedMode)
        }

        setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))
    }
    const getUniqueSelectedList = () => {
        let id = 'company_id'
        let objectIds = []
        const newArray = leagueList.filter(element => element.is_selected).map(element => element[id])
        objectIds = [...new Set(newArray)]
        return objectIds
    }

    const handleSelectorControlTagsClick = (action) => {
        fetchLabelDataForAllDetails(dispatch)
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setBooleanData(prev => ({ ...prev, modal: !booleanData.modal }))
    }

    const handleTagsSubmit = () => {
        let payload = {}
        payload['identifier'] = 'league'
        payload['query'] = _.cloneDeep(leagueTableState?.searchQuery)
        payload["selected_records"] = parseInt(leagueTableState.selectedCount)
        payload["object_type"] = "company"
        if (leagueTableState?.includeIds?.length > 0) {
            payload['object_id'] = leagueTableState.includeIds
        }
        if (leagueTableState?.excludeIds?.length > 0) {
            payload['exclude_ids'] = leagueTableState.excludeIds

          
        }
        if (payload["selected_records"] === 0) {
            delete payload["limit"]
            delete payload["offset"]
            delete payload["selected_records"]
            payload['company_id'] = payload['object_id']
            delete payload['object_id']
            payload["isLeague"] = true

        }

        if (project?.selectedTagsDetails?.tagIds?.length > 1 && project?.selectedTagsDetails?.tagIds?.includes(0)) {
            let tag_name = []
            let tag_id = []
            _.map(project?.selectedTagsDetails?.tagIds, (tagId, index) => {
                if (tagId === 0) {
                    tag_name.push(project.selectedTagsDetails.tagDescriptions[index])
                } else {
                    tag_id.push(project.selectedTagsDetails.tagIds[index])
                }
            })
            if (tag_name.length) {
                payload['tag_name'] = tag_name
            }
            if (tag_id.length) {
                payload['tag_id'] = tag_id
            }
        } else if (project?.selectedTagsDetails?.tagIds?.includes(0)) {
            payload['tag_name'] = project.selectedTagsDetails.tagDescriptions
        } else {
            payload['tag_id'] = project.selectedTagsDetails.tagIds
        }

        payload["sort"] = searchPersist?.leagueTable?.selectedSortValue === 'Projects' ? '-count_project_id' : '-sum_project_value'


        updateLeagueSelectorControlTags(payload, leagueTableState, searchPersist, dispatch)
        handleTagsCancel()
    }

    const goBack = (event) => {
        dispatch(updateLeagueRemoveFilterToggle(false))
        setSearchControlLimit(selectorControlValue(leagueTableState?.leagueSearchListCount))
        setListCount(leagueTableState?.leadSearchListCount)
        if (search.showSelectedCompanies || search.showSelectedProjects) {
            dispatch(updateSelectedSelectorControlValue(leagueTableState?.selectedLeadsFlag && search.selectedLeadsFlag.length))
        }
        dispatch(updateShowHierarchy({ showProjectHierarchy: false, showCompanyHierarchy: false }))
        dispatch(updateLeagueShowHierarchy({ showCompanyHierarchy: false }))
        dispatch(updateHierarchyBack(true))
        dispatch(updateProjectsForCompanyId([]))
        dispatch(rolesForProject([]))
        dispatch(updatePeopleProjectsList([]))
        dispatch(updatelistOfSelectedObjectIds([]))
        dispatch(updateRemoveFilterToggle(true))
        dispatch(resetRelationalEntititySelectionCount())
        dispatch(updateObjectData(undefined))
        dispatch(updateCompanyDetail(undefined))
        dispatch(updateObjectId(undefined))
        if (project.isProjectSelected) {
            navigate(`/app/company/0/${project.objectId}`)
            dispatch(updateIsProjectSelected({ isProjectSelected: false }))
        }
    }

    const handleSelectorControlBatchPrintClick = () => {
        let objectIds = getUniqueSelectedList()
        let payload = createDownloadPdfPayload("Companies PDF", objectIds, "Company", user)
        handleDropDownItemClick(0)
        createNewExport(payload, dispatch, navigate, userData)
    }
    let buttonClass = "",
        buttonClass2 = "",
        searchListHeaderCSS = "";

    const pagedDataRows = [1, 2, 3, 4, 5] // array to loop 5 times
    let pagedOptions = [];
    pagedDataRows.map(row => {
        pagedOptions.push((''))
    })
    return (
        <>
            <div className="d-flex justify-content-start">
                {
                    (search.showCompanyHierarchy) ?
                        (
                            <div className={`searchList-header ${searchListHeaderCSS}`}>
                                <a onClick={goBack} color="primary" className={`show map-back`}>
                                    back
                                </a>
                            </div>
                        ) : <>
                            {
                                <ButtonDropdown
                                    className={`select-drop-count me-2 ${buttonClass} ${search.clusterList?.leadsFlags?.length !== 0 || leagueList.length === 0 ? 'invisible' : ''}`}
                                    isOpen={booleanData.dropdownOpen} toggle={() => setBooleanData(prev => ({ ...prev, dropdownOpen: !booleanData.dropdownOpen }))}>

                                    <DropdownToggle className={`${(leagueTableState?.selectedSelectorControlValue > 0) || (leagueTableState?.countOfSelectedObjectIds > 0) ? 'active' : ''}`} caret>
                                        {
                                            parseInt(leagueTableState?.selectedSelectorControlValue) > 0 ? (
                                                <p><Icon color="#292B2C" size={22} icon="icon-gen-tick-filled-skyblue" className="me-1" /> {leagueTableState?.selectedSelectorControlValue} selected</p>
                                            ) : <><Icon color="#292B2C" size={22} icon="icon-tick-filled-grey" className="me-1" /></>
                                        }
                                    </DropdownToggle>
                                    {
                                        <DropdownMenu className="dropdown-menu-right select-drop-list">
                                            <DropdownItem className={`${leagueTableState?.selectedSelectorControlValue.toString() == "0" ? 'active' : ''}`} onClick={() => handleItemSelect(0)}><Icon size={22} icon={`${leagueTableState?.selectedSelectorControlValue.toString() == "0" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> None </DropdownItem>
                                            {listCount ? searchControlLimit && searchControlLimit.map((keyr, val) => (
                                                Object.entries(keyr).map(([key, value]) => {
                                                    return checkAvailabilityOfLimit(listCount, key) ?
                                                        (<DropdownItem className={`${leagueTableState?.selectedSelectorControlValue === key ? 'active' : ''}`}
                                                            onClick={() => handleDropDownItemClick(key)}
                                                        ><Icon size={22} icon={`${leagueTableState?.selectedSelectorControlValue === key ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> {value} </DropdownItem>) : ('')
                                                }))) : pagedOptions && pagedOptions.map(row => row)}
                                            {checkAvailabilityOfOption(listCount) ? (<DropdownItem className={`${leagueTableState?.selectedSelectorControlValue.toString() == "" ? 'active' : ''}`} onClick={() => handleDropDownItemClick(listCount)}><Icon size={22} icon={`${leagueTableState?.selectedSelectorControlValue.toString() == "" ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />  All </DropdownItem>) : ('')}
                                        </DropdownMenu>
                                    }
                                </ButtonDropdown>
                            }
                        </>
                }
                {
                    (leagueTableState?.selectedSelectorControlValue.toString() != "0") ? (
                        <ButtonDropdown className={`select-actions ${buttonClass2}`} isOpen={booleanData.actionsDropDownOpen} toggle={() => setBooleanData(prev => ({ ...prev, actionsDropDownOpen: !booleanData.actionsDropDownOpen }))}>
                            <DropdownToggle caret className="active">
                                <Icon color="#292B2C" size={25} icon="icon-gen-threedot-ver" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right action-list">
                                <DropdownItem onClick={() => handleSelectorControlActionsClick('Favourite')}>Favourite</DropdownItem>
                                <DropdownItem onClick={() => handleSelectorControlActionsClick('Unfavourite')}>Unfavourite</DropdownItem>
                                <DropdownItem onClick={(event) => handleSelectorControlTagsClick(event)}>Tags</DropdownItem>
                                {
                                    isPdfActive ? (
                                        parseInt(leagueTableState?.selectedSelectorControlValue) <= 50 ?
                                            (
                                                <DropdownItem disabled={!isExportEnabled} onClick={() => handleSelectorControlBatchPrintClick()}>Download PDF</DropdownItem>
                                            ) : (
                                                <DropdownItem disabled={!isExportEnabled} className="disabled">Download PDF{(leagueTableState?.selectedSelectorControlValue <= MAX_PDF_DOWNLOAD_LIMIT) ? "" : " (max " + MAX_PDF_DOWNLOAD_LIMIT + ")"}</DropdownItem>
                                            )
                                    ) : ('')
                                }
                            </DropdownMenu>
                        </ButtonDropdown>
                    ) : ('')
                }
                {
                    <div className="button-blocks">
                        <TagsModal
                            handleTagsCancel={handleTagsCancel}
                            handleTagsSubmit={handleTagsSubmit}
                            selectedTags={project && project.selectedTagsDetails}
                            showModal={booleanData.modal}>
                        </TagsModal>
                    </div>
                }
                <div className={`searchList-header ${searchListHeaderCSS}`}>
                    {
                        (props?.prevNextList === 'LeagueTableList' && search.showCompanyHierarchy) ? (
                            <h1 className={`show h5 m-0 ps-3`}>Search results</h1>
                        ) : project.isProjectSelected ? (
                            <>
                                <h1 className={`show h5 m-0 ps-3`}>Projects of selected companies</h1>
                                <p className={`result-count mb-0`}>
                                    50 of All Projects
                                </p>
                            </>
                        ) :
                            ('')
                    }
                </div>
            </div>
        </>
    )
}

export default LeagueSearchSelectorDropdown