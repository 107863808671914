import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { updateExportName, updateSelectedTypeName, updateSelectedType, updateSavedSearchExportType, updateExportCountQuery, updateExportCollection } from '../../reducer';
import { exportSaveSearch, fetchExportRecordsCount, fetchTemplates } from '../../apiclient';
import _, { cloneDeep } from 'lodash';
import { fetchLabelDataForAllDetails } from '../../../details/apiclient';
import { getSavedSearchList } from '../../utils';

const FirstPage = forwardRef((props, ref) => {
    const exports = useSelector((state) => state.export)
    const details = useSelector((state) => state.project)
    const [exportTypeStatus, setExportTypeStatus] = useState(false);
    const [savedSearchStatus, setSavedSearchStatus] = useState(false);
    const [tagStatus, setTagStatus] = useState(false);
    const [selectedValue, setSelectedValue] = useState(exports?.selectedType);
    const [selectedSavedSearchValue, setSelectedSavedSearchValue] = useState("Select saved search");
    const [selectedTagValue, setSelectedTagValue] = useState("Select tag");
    const [exportName, setExportName] = useState("")
    const [saveSearchData, setSaveSearchData] = useState([])
    const [microcopyMsg, setMicrocopyMsg] = useState(exports?.microcopySavesearchMsg)
    const [labels, setLabels] = useState([])
    const [saveSearchCount, setsaveSearchCount] = useState(-1)
    const [state, setState] = useState({
        exportNameMsg: '',
        typeMsg: '',
        saveSearchMsg: '',
        saveSearchCountMsg: ''
    })
    const [isCopyFeed, setIsCopyFeed] = useState(false)
    const dispatch = useDispatch()

    useImperativeHandle(ref, () => ({
        isValidated() {
            const validateNewInput = _validateData() // run the new input against the validator
            let isDataValid = false

            // if full validation passes then save to store and pass as valid
            if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
                isDataValid = true;
            }
            else {
                _validationErrors(validateNewInput);
            }
            return isDataValid
        }
    }));

    const handleExportType = (value) => {
        setSelectedValue(value);
        let selectedTypeName = 'Select ' + value.toLowerCase()
        setSelectedSavedSearchValue(selectedTypeName)
        dispatch(updateSelectedType(value))
        dispatch(updateSelectedTypeName(selectedTypeName))
        if (value) {
            setState({
                typeMsg: ''
            })
        }
    }

    const checkSaveSearcHasCurrentLocationFilter = (savedSearchId) => {
        // let data = this.props.savedSearch.list
        // for (var i = 0; i < data.length; i++) {
        // 	if(data[i].id == savedSearchId && 'is_current_location' in data[i] && data[i].is_current_location){
        // 		return true
        // 	}
        // }
        // return false
    }

    const handleSavedSearchType = (value) => {
        if (checkSaveSearcHasCurrentLocationFilter(value.saved_search_id)) {

        } else {
            setSelectedSavedSearchValue(value.saved_search_name);
            if (value.saved_search_name) {
                setState({
                    saveSearchMsg: ''
                })
            }
            let payload = _.clone(value)
            payload = {
                ...payload,
                key: 'saved_search'
            }
            props.updateRefreshCountQuery(payload)
            dispatch(updateSelectedTypeName(value.saved_search_name))
            dispatch(updateExportCollection({ type: "SS", key: parseInt(value.saved_search_id) }))
            dispatch(updateSavedSearchExportType({ type: value.object_type === "project" ? "Projects" : value.object_type === "company" ? "Companies" : "Contacts" }))
            fetchExportRecordsCount(payload, dispatch, true)
        }
    }

    const handleTagType = (value, type) => {
        setSelectedTagValue(value.tag_name);
        if (value.tag_name) {
            setState({
                saveSearchMsg: ''
            })
        }
        let payload = _.clone(value)
        payload = {
            ...payload,
            key: 'tags',
            object_type: type
        }
        props.updateRefreshCountQuery(payload)
        dispatch(updateSelectedTypeName(value.tag_name))
        dispatch(updateExportCollection({ type: "tag", key: parseInt(value.tag_id) }))
        dispatch(updateSavedSearchExportType({ type: type === "project" ? "Projects" : type === "company" ? "Companies" : "Contacts" }))
        fetchExportRecordsCount(payload, dispatch, true)
    }

    const onExportNameChange = (e) => {
        setExportName(e.target.value)
        dispatch(updateExportName({ feedname: e.target.value }))
        if (e.target.value) {
            setState({
                exportNameMsg: ''
            })
        }
    }

    function getLabelsList(labels) {
        var list = []
        list["project"] = labels
        list["company"] = labels
        return list
    }

    useEffect(() => {
        if (exports?.exportSaveSearch && Array.isArray(exports?.exportSaveSearch)) {
            let saveSearchList = cloneDeep(exports?.exportSaveSearch)
            setSaveSearchData(getSavedSearchList(saveSearchList))
        }
        if (details?.labelTagsAllDetail?.length > 0) {
            setLabels(getLabelsList(details?.labelTagsAllDetail))
        }
    }, [exports?.exportSaveSearch])

    useEffect(() => {
        if (exports.selectedType == "Tag") {
            setsaveSearchCount(exports?.saveSearchCount)
            setMicrocopyMsg(exports?.microcopySavesearchMsg)
        } else {
            setsaveSearchCount(exports?.saveSearchCount)
            setMicrocopyMsg(exports?.microcopySavesearchMsg)
        }
    }, [exports])

    useEffect(() => {
        // exportSaveSearch(dispatch)
        // fetchLabelDataForAllDetails(dispatch)
        // fetchTemplates(dispatch)
    }, [])

    useEffect(() => {
        if (exports?.isCopyFeed === true) {
            setExportName(exports.exportCriteria.feedname)
            setIsCopyFeed(exports.isCopyFeed)
            setSelectedValue(exports.selectedType)
            exports.selectedType === 'Saved search' ? setSelectedSavedSearchValue(exports.selectedTypeName) : setSelectedTagValue(exports.selectedTypeName)
            setsaveSearchCount(exports.saveSearchCount)
            setMicrocopyMsg(exports.microcopySavesearchMsg)
            props.updateRefreshCountQuery(exports.exportCountQuery)
        }
    }, [exports?.isCopyFeed])


    useEffect(() => {
    setExportName(exports?.exportCriteria?.feedname)
    }, [exports?.exportCriteria?.feedname])

    useEffect(() => {
        exports.selectedType === 'Saved search' ? setSelectedSavedSearchValue(exports.selectedTypeName) : setSelectedTagValue(exports.selectedTypeName)
        }, [exports?.selectedTypeName])


    const _validationErrors = (val) => {
        setState((prevState) => ({
            ...prevState, // Keep previous state values
            exportNameMsg: val.exportNameVal ? '' : 'Export name is required',
            typeMsg: val.typeVal ? '' : 'Export type is required',
            saveSearchMsg: val.saveSearchVal ? '' : selectedValue !== 'Select export type' ? selectedValue + ' is required' : '',
            saveSearchCountMsg: val.saveSearchCountVal ? '' : selectedValue !== 'Select export type' ? selectedValue + ' count is zero' : ''
        }))
    }

    const _validateData = () => {
        return {
            exportNameVal: (exportName === undefined || exportName === null || (exportName !== null && exportName.trim() === '')) ? false : true,
            typeVal: (selectedValue !== "Select export type"),
            saveSearchVal: (selectedValue === 'Saved search' && selectedSavedSearchValue !== "Select saved search") || (selectedValue === 'Tag' && selectedTagValue !== "Select tag"),
            saveSearchCountVal: (saveSearchCount === 0) ? false : true
        }
    }

    return (
        <>
            <div className="input-container">
                <div className="has-error">
                    <span className='title'>Export name</span>
                    <Input autocomplete="off" placeholder="New export name" id="exportName" maxlength="60"
                        type="text" className="form-control long-input" value={exportName} name="exportName" onChange={(e) => onExportNameChange(e)} />
                    <div className="val-err-tooltip">{state.exportNameMsg}</div>
                </div>
            </div>
            <div className="input-container">
                <div className="has-error">
                    <span className='title'>Select export type</span>
                    <div className="select-type">
                        {
                            isCopyFeed === true ? (<Dropdown className='custom-dropdown' toggle={() => { setExportTypeStatus(!exportTypeStatus) }} isOpen={exportTypeStatus}>
                                <DropdownToggle caret className="disabled" disabled>
                                    {selectedValue}
                                </DropdownToggle>
                                <DropdownMenu className="disabled" disabled>
                                    <DropdownItem onClick={() => handleExportType('Saved search')}>Saved search</DropdownItem>
                                    <DropdownItem onClick={() => handleExportType('Tag')}>Tag</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>) : (<Dropdown className='custom-dropdown' toggle={() => { setExportTypeStatus(!exportTypeStatus) }} isOpen={exportTypeStatus}>
                                <DropdownToggle caret>
                                    {selectedValue}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => handleExportType('Saved search')}>Saved search</DropdownItem>
                                    <DropdownItem onClick={() => handleExportType('Tag')}>Tag</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>)
                        }
                    </div>
                    <div className="val-err-tooltip">{state.typeMsg}</div>
                </div>
            </div>
            {selectedValue === 'Saved search' ?
                (<div className="input-container">
                    <div className="has-error">
                        <span className='title'>Select a saved search</span>
                        <div className="select-type">
                            <Dropdown className='custom-dropdown' toggle={() => { setSavedSearchStatus(!savedSearchStatus) }} isOpen={savedSearchStatus}>
                                <DropdownToggle caret>
                                    {selectedSavedSearchValue}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        saveSearchData && Object.keys(saveSearchData).map((savedSearchKey, index) => (
                                            <div key={index}>
                                                <DropdownItem header>{savedSearchKey === "project" ? "Projects based saved search" : savedSearchKey === "company" ? "Companies based saved search" : "Contacts based saved search"}</DropdownItem>
                                                {
                                                    saveSearchData && saveSearchData[savedSearchKey].map(function (saveSearch, index) {
                                                        return (
                                                            <DropdownItem onClick={() => handleSavedSearchType(saveSearch)}>{saveSearch?.saved_search_name}</DropdownItem>
                                                            // <DropdownItem key={index} ref={index} onClick={() => that.handleSaveSearchClick(savedSearch.id, savedSearch.searchName, savedSearch.display ? savedSearch.display : savedSearch.filters ? savedSearch.filters.display : 'project')}>{savedSearch.searchName}</DropdownItem>
                                                        )
                                                    })
                                                }
                                            </div>
                                        ))
                                    }
                                </DropdownMenu>
                            </Dropdown>
                            {saveSearchCount >= 0 ? (<span className="export-count ms-2">{new Intl.NumberFormat('en-UK').format(saveSearchCount) + (" " + microcopyMsg)}</span>) : ('')}
                        </div>
                        <div className="val-err-tooltip">{state.saveSearchMsg}</div>
                        <div className="val-err-tooltip">{state.saveSearchCountMsg}</div>
                    </div>
                </div>) : selectedValue === 'Tag' ?
                    (<div className="input-container">
                        <div className="has-error">
                            <span className='title'>Select a tag</span>
                            <div className="select-type">
                                <Dropdown className='custom-dropdown' toggle={() => { setTagStatus(!tagStatus) }} isOpen={tagStatus}>
                                    <DropdownToggle caret>
                                        {selectedTagValue}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            labels && Object.keys(labels).map(function (labelsKey, index) {
                                                return (
                                                    <div key={index}>
                                                        <DropdownItem header>{labelsKey === "project" ? "Projects based tags" : labelsKey === "company" ? "Companies based tags" : "Contacts based tags"}</DropdownItem>
                                                        {
                                                            labels[labelsKey].map(function (label, index) {
                                                                return (
                                                                    <DropdownItem onClick={() => handleTagType(label, labelsKey)}>{label.tag_name}</DropdownItem>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                                {saveSearchCount >= 0 ? (<span className="export-count ms-2">{new Intl.NumberFormat('en-UK').format(saveSearchCount) + (" " + microcopyMsg)}</span>) : ('')}
                            </div>
                            <div className="val-err-tooltip">{state.saveSearchMsg}</div>
                            <div className="val-err-tooltip">{state.saveSearchCountMsg}</div>
                        </div>
                    </div>) : selectedValue === 'Email mail merge' ? (
                        <div className="input-container">
                            <div>
                                <span>Email mail merge</span>
                            </div>
                        </div>
                    ) : ('')
            }
        </>
    )
})

export default FirstPage