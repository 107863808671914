import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup } from 'reactstrap';
//import DayPickerInput from 'react-day-picker/DayPickerInput'
import DatePicker from "react-datepicker";
import moment from 'moment';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import UserActivityList from './userActivityList';
import { fetchUserActivities } from '../apiclient';
import { updateAdminUserActivitiesOffsetLimitValue } from '../reducer';
import { updateFromAndToDate,updateSelectedValue } from '../userAdminPersistReducer';

const UserActivity = (props) => {
  const useradminData = useSelector(state => state.useradmin)
  const searchPersist = useSelector((state) => state.searchPersist)
  const userAdminPersistData=useSelector(state => state.userAdminPersist)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(userAdminPersistData?.selectedValue);
  const dropdownValues = ["Last Week", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months"];
  const [from, setFrom] = useState( userAdminPersistData.userActivity.from  ? moment(userAdminPersistData.userActivity.from).toDate() : new Date());
  const [to, setTo] = useState( userAdminPersistData.userActivity.to ? moment(userAdminPersistData.userActivity.to).toDate() : new Date() );
  const [fromPlaceholder, setFromPlaceholder] = useState(userAdminPersistData?.userActivity?.from ? moment(userAdminPersistData?.userActivity?.from).format('DD-MMM-YYYY') : undefined);
  const [toPlaceholder, setToPlaceholder] = useState(userAdminPersistData?.userActivity?.to ? moment(userAdminPersistData?.userActivity?.to).format('DD-MMM-YYYY') : undefined);
  const [userActivityList, setUserActivityList] = useState(useradminData?.userActivityList);
  const [preloaderFlag, setPreloaderFlag] = useState(false);
  const dispatch = useDispatch();
  const [disablePastDay, setDisbaledPastDate] = useState(moment().subtract(searchPersist?.customerProfile?.noOfDays, "days").format('DD'))
  const [disablePastMonth, setDisablePastMonth] = useState(moment().subtract(searchPersist?.customerProfile?.noOfDays, 'days').format('MM'));
  const [disablePastYear, setDisablePastYear] = useState(moment().subtract(searchPersist?.customerProfile?.noOfDays, 'days').format('YYYY'));
  const [disableFutureDay, setDisableFutureDay] = useState(moment().add(0, 'days').format('DD'));
  const [disableFutureMonth, setDisableFutureMonth] = useState(moment().add(-1, 'month').format('MM'));
  const [disableFutureYear, setDisableFutureYear] = useState(moment().add(0, 'days').format('YYYY'));


  const toggle = () => {
    setDropdownOpen(prevOpen => !prevOpen);
  };

  const viewReport = () => {
    setPreloaderFlag(true);
    if (userAdminPersistData?.userActivity?.from !== undefined && userAdminPersistData?.userActivity?.to !== undefined) {
      let payload = {
        from: moment(userAdminPersistData?.userActivity?.from).format('DD/MM/YYYY'),
        to: moment(userAdminPersistData?.userActivity?.to).format('DD/MM/YYYY'),
        offset: 0,
        limit: 50
      }
      fetchUserActivities(payload,dispatch)
      dispatch(updateAdminUserActivitiesOffsetLimitValue({ offset: 0, records: 50 }));
    } 
  };
 

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    dispatch(updateSelectedValue(value));
    const start = moment().startOf('isoWeek').toDate();
    const startOfMonth = moment().startOf('month').toDate();
    if (value === "Last Week") {
      const momentFrom = moment(start).subtract(7, 'days').toDate();
      const momentTo = moment(start).subtract(1, 'days').toDate();
      setDate(momentFrom, momentTo);
    } else if (value === "Last Month") {
      const momentFromMonth = moment(startOfMonth).subtract(1, 'months').toDate();
      const momentToMonth = moment(startOfMonth).subtract(1, 'days').toDate();
      setDate(momentFromMonth, momentToMonth);
    } else if (value === "Last 3 Months") {
      const momentFrom3Month = moment(startOfMonth).subtract(3, 'months').toDate();
      const momentTo3Month = moment(startOfMonth).subtract(1, 'days').toDate();
      setDate(momentFrom3Month, momentTo3Month);
    } else if (value === "Last 6 Months") {
      const momentFrom6Month = moment(startOfMonth).subtract(6, 'months').toDate();
      const momentTo6Month = moment(startOfMonth).subtract(1, 'days').toDate();
      setDate(momentFrom6Month, momentTo6Month);
    } else if (value === "Last 12 Months") {
      const momentFrom12Month = moment(startOfMonth).subtract(12, 'months').toDate();
      const momentTo12Month = moment(startOfMonth).subtract(1, 'days').toDate();
      setDate(momentFrom12Month, momentTo12Month);
    }
  };

  const setDate = (momentFrom, momentTo) => {
    const momentFromFormat = moment(momentFrom).format('DD-MMM-YYYY');
    const momentToFormat = moment(momentTo).format('DD-MMM-YYYY');
    dispatch(updateFromAndToDate({from: momentFrom,to: momentTo }))
    setFromPlaceholder(momentFromFormat);
    setToPlaceholder(momentToFormat);
  };


  const handleShowMore = () => {
    let newOffset = useradminData?.offsetLimitValueForUserActivities?.offset + useradminData?.offsetLimitValueForUserActivities?.records;
    dispatch(updateAdminUserActivitiesOffsetLimitValue({ offset: newOffset, records: useradminData?.offsetLimitValueForUserActivities?.records }));
    let payload = {
      from: moment(userAdminPersistData?.userActivity?.from).format('DD/MM/YYYY'),
      to: moment(userAdminPersistData?.userActivity?.to).format('DD/MM/YYYY'),
      offset: newOffset,
      limit:  useradminData?.offsetLimitValueForUserActivities?.records
    }
    fetchUserActivities(payload,dispatch)
  }
  const handleFromChange=(from) =>{
		let momentFromFormat = moment(from).toDate()
    setFrom(from);
    setFromPlaceholder(momentFromFormat);
    dispatch(updateFromAndToDate({from: momentFromFormat, to:userAdminPersistData?.userActivity?.to }))
	
	}
	const handleToChange=(to)=> {
		let momentToFormat = moment(to).toDate()
    setTo(to);
    dispatch(updateFromAndToDate({from :userAdminPersistData?.userActivity?.from,to: momentToFormat }))
    setToPlaceholder(momentToFormat);
	}

  useEffect(() => {
    if (props?.activeTab === "userActivity") {
      dispatch(updateAdminUserActivitiesOffsetLimitValue({ offset: 0, records: 50 }));
      let payload = {
        from: moment(userAdminPersistData?.userActivity?.from).format('DD/MM/YYYY'),
        to: moment(userAdminPersistData?.userActivity?.to).format('DD/MM/YYYY'),
        offset: 0,
        limit:  50
      }
      fetchUserActivities(payload,dispatch)
    }
    }, [props?.activeTab]);

    useEffect(()=>{
      setUserActivityList(useradminData?.userActivityList)

    },[useradminData?.userActivityList])


    useEffect(() => {
      const from = userAdminPersistData.userActivity.from;
      const to = userAdminPersistData.userActivity.to;
      setFrom(moment(from).toDate());
      setTo(moment(to).toDate());
      setFromPlaceholder(from ? moment(from).format('DD-MMM-YYYY') : undefined);
      setToPlaceholder(to ? moment(to).format('DD-MMM-YYYY') : undefined);
      setUserActivityList(useradminData?.userActivityList);
      setPreloaderFlag(false);
      setSelectedValue(userAdminPersistData?.selectedValue)
    }, [useradminData,userAdminPersistData]);

  return (
    props.activeTab === "userActivity" && (
      <div className="content-wrapper">
        <div className="user-tab">
          <div className="user-search-panel user-activity row">
            <div className="period-panel col-panel-1">
              <p>Select a period</p>
              <div className="p-0 col col-sm-9 col-md-6 col-lg-3">
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle caret>{selectedValue}</DropdownToggle>
                  <DropdownMenu>
                    {dropdownValues.map((value, index) => (
                      <DropdownItem
                        onClick={handleChange}
                        key={index}
                        value={value}
                      >
                        {value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <span className="divide">OR</span>
            </div>
            <div className="period-panel col-panel-2">
              <p>Select date</p>
              <div className="date-container">
                <div className="InputFromTo">
                  <DatePicker
                    value={fromPlaceholder}
                    selected={from}
                    onChange={(date) => handleFromChange(date)}
                    selectsStart
                    startDate={from}
                    endDate={to}
                    dateFormat="dd-MMM-yyyy"
                    minDate={
                      new Date(
                        disablePastYear,
                        disablePastMonth,
                        disablePastDay
                      )
                    }
                    maxDate={
                      new Date(
                        disableFutureYear,
                        disableFutureMonth,
                        disableFutureDay
                      )
                    }
                  />
                  <span className="mt-2 mx-2">—</span>
                  <DatePicker
                    value={toPlaceholder}
                    selected={to}
                    onChange={(date) => handleToChange(date)}
                    selectsEnd
                    startDate={from}
                    endDate={to}
                    dateFormat="dd-MMM-yyyy"
                    minDate={from}
                    maxDate={
                      new Date(
                        disableFutureYear,
                        disableFutureMonth,
                        disableFutureDay
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="period-panel col-panel-3">
              <div className="src-button">
                <button
                  type="primary"
                  class="primary-btn add-user"
                  onClick={viewReport}
                >
                  View Report
                </button>
              </div>
            </div>
          </div>

          <a
            className="download-report"
            href={useradminData?.filePath}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download user activity report
          </a>
          <table className="table mb-0">
            <thead className="table-head">
              <tr>
                <Row className="list-header mr-0">
                  <Col className="user-details" xs="12" sm="12" md={2}>
                    User details
                  </Col>
                  <Col className="center " xs="12" sm="12" md="0.8">
                    Searches
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Projects
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Companies
                  </Col>
                  <Col className="center " xs="12" sm="12" md="0.8">
                    Saved Searches
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Favourites
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Tags
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Shares
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Notes
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Exports
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Records
                  </Col>
                  <Col className="center" xs="12" sm="12" md="0.8">
                    Just Ask
                  </Col>
                </Row>
              </tr>
            </thead>
            <table className="main-body">
              <tr>
                <div className="list-wrapper mt-2 users-activity-wrapper">
                  <ColoredScrollbars className="details-panel-scrollbar">
                    <div className="user-list-container mr-0">
                      {userActivityList?.length > 0
                        ? userActivityList.map(function (user, index) {
                            return (
                              <UserActivityList
                                {...props}
                                result={user}
                                index={index}
                                preloaderFlag={preloaderFlag}
                                key={index}
                              />
                            );
                          })
                        : ""}
                    </div>
                    {userActivityList?.length > 0 &&
                    useradminData?.isuserActivityShowMoreActive ? (
                      <div className="show d-flex justify-content-center pt-3 pb-3 showmore-result">
                        <Button
                          className="btn btn-block btn-primary"
                          onClick={handleShowMore}
                        >
                          Show more
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </ColoredScrollbars>
                </div>
              </tr>
            </table>
          </table>
        </div>
      </div>
    )
  );
};
export default UserActivity;