import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputGroup } from 'reactstrap'

import { groupNotesbyUpdatedDate } from '../../../../utils';
import Icon from '../../../common/js/icon';
import { addNote } from '../../apiclient';
import NotesList from './notelist';

const ActivitiesNotesDetail = (props) => {
  const [noteNames, setNoteNames] = useState('')
  const [isEmpty, setIsEmpty] = useState(true)
  const [notes, setNotes] = useState([])
  let textPlaceholder = (props.sharedType === 'project') ? 'Add a note to this project' : (props.sharedType === 'company') ? 'Add a note to this company' : 'Add a note'
  const project = useSelector(state => state.project)
  const userData = useSelector(state => state.user)
  const dispatch = useDispatch();

  useEffect(()=>{
    setNotes(groupNotesbyUpdatedDate(project && project.fetchNotesForParticularObjectIds && project.fetchNotesForParticularObjectIds.notes ? project && project.fetchNotesForParticularObjectIds && project.fetchNotesForParticularObjectIds.notes : []))
  },[project])

  const handleAddNote = (event) => {
    if (noteNames && noteNames.length > 0) {
      let notesPayload = { id: project && project.detailsPage.objectId, type: project && project.detailsPage.objectType, description: noteNames }
      addNote(notesPayload, dispatch, userData)
      setNoteNames('')
    }
  }

  const handleChange = (event) => {
    if (event.target.value.trim().length > 0) {
      setIsEmpty(false)
    }
    if (event.target.value.length > 4000) {
      event.target.value = event.target.value.slice(0, 4000);
    }
    setNoteNames(event.target.value.length > 0 ? event.target.value : '')
  }

  return (
    <div className="note-list p-3">
      <div className="note-list-wrapper">
        <InputGroup className="note-form d-flex">
        <Icon color="#AAAAAA" size={25} icon="icon-gen-plus-rounded" className="circle-border" />
          <div className="note-input">
            <Input type="textarea" placeholder={textPlaceholder} value={noteNames} onChange={(e) => handleChange(e)} />
            <span className="text-area-counter">{noteNames.length}/4000</span>
          </div>
          <button className={`primary-btn btn-share`} onClick={handleAddNote}>Add note</button>
        </InputGroup>
      </div>
      {
        project && project.fetchNotesForParticularObjectIds && project.fetchNotesForParticularObjectIds.aggregation && project.fetchNotesForParticularObjectIds.aggregation.note_count > 0 ? (
          <div className="note-details-wrapper">
            {
                notes && Object.keys(notes).map(function (updatedDate, index) {
                      return (
                          <div className="note-listing pb-2" key={index}>
                              <span className="notes-updated-date">{updatedDate}</span>
                              {
                                  notes[updatedDate].map(function (note, notesIndex) {
                                      return (
                                          note.note ?
                                          <NotesList key={notesIndex} note={note} index={notesIndex}/>
                                          : ""
                                      )
                                  })
                              }
                          </div>
                      )
                  })
              }
          </div>
        ) : ('')
      }
    </div>
  )
}

export default ActivitiesNotesDetail