import getAxios from '../../utils/baseApi';
import { component, recipientDetails, setAlert, signIn, signOut, teamDetails, updateIsMasqueradeUser, updateIsRedirectedUser, updateOnlyDeveloperLicenses, updateUniverseCount } from '../../redux/features/user/userslice';
import { getIntercomId, messageAlert, modifyReciepientsResponse } from '../../utils';
import { ABI_IOS_APP, ABI_PLAY_APP, ADMIN_LICENSE, ANDROID_PROJECT_LOCATOR_URL, CALENDAR_LICENSE, CONSTRUCTION_HUB_LICENSE, DEVELOPER_LICENSE, EXPORT_LICENSE, FIELD_SALES_LICENSE, INSIDE_SALES_LICENSE, IOS_PROJECT_LOCATOR_URL, JUST_ASK_LICENSE, LANDMARK_LICENSE, WEB_APP_LICENSE } from '../../utils/settings';
import { updateUserPreferences } from '../../redux/features/settings/settingslice';
import { teamsUsersDataMergeProcessing } from '../details/postprocessing';
import { handleError } from '../../utils/errorHandler';
import { shareRecipientsDetail, updateTeams } from '../details/redux/reducer';
import { updateFrameworkList, updateLogin } from '../search/redux/searchPeristReducer';
import { fetchCustomerProfile } from '../search/apiclient';
import { fetchBadgeCount, getNotifications } from '../header/apiclient';


var axios = getAxios()
const initializeIntercomAfterLogin = (userData, payload) => {
    let intercomId = getIntercomId(window.location);
    if (payload.is_masquerade_user === 'False') {
        window.Intercom('boot', {
            app_id: intercomId,
            user_id: userData?.user_id,
            user_hash: userData?.intercom?.secret_key,
            hide_default_launcher: true,
            alignment: 'left',
            horizontal_padding: 80,
            vertical_padding: 0
        });
        window.Intercom('trackEvent', 'Sign in', {
            'userId': userData?.user_id,
            'name': `${userData?.first_name} ${userData?.last_name}`,
            'email': userData?.email,
            'companyId': userData?.client_id,
            'companyName': userData?.company_name
        });
    }
};
export const signInUser = (formValues, navigate, dispatch, appliedFilter, tempCriteria, previousUrl = null, redirect_to = false) => {
    let payload = { username: formValues.username, password: formValues.password, stno: formValues.stno }
    let isAdminLicence = false
    let isInsideSalesLicence = false
    let isExportLicence = false
    let isConstructionHubLicence = false
    let isCalendarLicence = false
    let isJustAskLicence = false
    let isLandmarkLicence = false

    if (formValues.hasOwnProperty('isMasqueradeUser') && formValues.isMasqueradeUser) {
        payload['is_masquerade_user'] = 'True'
        payload['masqueraded_by'] = formValues.masqueraded_by
    } else {
        payload['is_masquerade_user'] = 'False'
        payload['masqueraded_by'] = null
    }
    if (formValues.hasOwnProperty('redirectTo') && formValues.redirectTo.includes("useradmin#userActivity")) {
        payload['is_app_activity'] = 'True'
    } else {
        payload['is_app_activity'] = 'False'
    }
    axios.post('/login', payload)
        .then(async (response) => {
            dispatch(updateIsMasqueradeUser(payload['is_masquerade_user'] === 'True' ? true : false))
            dispatch(updateIsRedirectedUser(payload['is_app_activity'] === 'True' ? true : false))

            let userWithDeveloperLicenseOnly = false;

            // Check if any number in DEVELOPER_LICENSE is present in response.data.licence_ids
            const anyDeveloperInUserLicences = DEVELOPER_LICENSE.some(num => response.data.licence_ids.includes(num));

            // Check if no numbers in WEB_APP_LICENSE are present in response.data.licence_ids
            const noWebAppInUserLicences = WEB_APP_LICENSE.every(num => !response.data.licence_ids.includes(num));

            if (anyDeveloperInUserLicences && noWebAppInUserLicences) {
                userWithDeveloperLicenseOnly = true;
            }

            response.data.licence_ids.map(function (licence, index) {
                if (ADMIN_LICENSE.includes(licence)) {
                    isAdminLicence = true
                }
                if (INSIDE_SALES_LICENSE.includes(licence)) {
                    isInsideSalesLicence = true
                }
                if (EXPORT_LICENSE.includes(licence)) {
                    isExportLicence = true
                }
                if (CONSTRUCTION_HUB_LICENSE.includes(licence)) {
                    isConstructionHubLicence = true
                }
                if (CALENDAR_LICENSE.includes(licence)) {
                    isCalendarLicence = true
                }
                if (JUST_ASK_LICENSE.includes(licence)) {
                    isJustAskLicence = true
                }
                if (LANDMARK_LICENSE.includes(licence)) {
                    isLandmarkLicence = true
                }
            })

            const onlyHasJustAskLicense = (response.data.licence_ids.length === 1 && response.data.licence_ids[0] === 1084)
                || response.data.licence_ids.length === 2 && [1072, 1084].every((value, index) => value === response.data.licence_ids[index]);

            response.data['licenceFlag'] = {}
            response.data['licenceFlag']['isAdminLicence'] = isAdminLicence
            response.data['licenceFlag']['isInsideSalesLicence'] = isInsideSalesLicence
            response.data['licenceFlag']['isExportLicence'] = isExportLicence
            response.data['licenceFlag']['isConstructionHubLicence'] = isConstructionHubLicence
            response.data['licenceFlag']['isCalendarLicence'] = isCalendarLicence
            response.data['licenceFlag']['isJustAskLicence'] = isJustAskLicence
            response.data['licenceFlag']['isLandmarkLicence'] = isLandmarkLicence
            response.data['licenceFlag']['onlyHasJustAskLicense'] = onlyHasJustAskLicense

            dispatch(signIn(response.data))
            dispatch(updateLogin(response.data.default_saved_search ? true : false))
            fetchUniverseCount(dispatch)
            dispatch(updateOnlyDeveloperLicenses(userWithDeveloperLicenseOnly))
            await axios.get(`/users/${response.data.user_id}`).then((response) => {
                if (response.status === 200) {
                    dispatch(updateUserPreferences(response.data.user.preferences))
                }
            })
            fetchShareTeams(dispatch, response.data)
            getNotifications(response.data.user_id, dispatch);
            fetchBadgeCount(response.data.user_id, response.data.licenceFlag, dispatch);
            fetchAllFrameworksList(dispatch)
            fetchCustomerProfile(dispatch)
            navigate(payload['is_app_activity'] === 'True' ? "/app/useradmin#userActivity" : userWithDeveloperLicenseOnly ? "/app/settings#notifications" : onlyHasJustAskLicense ? "/app/justask" : "/app/list")

            initializeIntercomAfterLogin(response.data, payload)
        })
        .catch(function (error) {
            if (error.response) {
                let internalServerErrorMessage = "Oops, something has gone wrong# Please try again"
                let serviceUnavailableError = "Server is not responding. Please try again later"
                if (error.response.status === 500) {
                    messageAlert(dispatch, internalServerErrorMessage, 'danger')
                }
                else if (error.response.status === 503) {
                    messageAlert(dispatch, serviceUnavailableError, 'danger')
                }
                else if (error.response.status === 403) {
                    messageAlert(dispatch, internalServerErrorMessage, 'danger')
                }
                else if (error.response.status === 401) {
                    messageAlert(dispatch, error.response.data, 'danger')
                    if (redirect_to) {
                        navigate("/")
                    }
                }
                else {
                    messageAlert(dispatch, error.response, 'danger')
                    window.location = "/"
                }
            }
        });
}

export const fetchShareTeams = async (dispatch, response_data) => {
    await axios.get('/teams').then(function (response) {
        if (response.status === 200) {
            let teams_data;
            if (response && response.data && response.data.teams && response.data.teams.length > 0) {
                teams_data = response.data.teams
                dispatch(updateTeams(response.data))
                axios.get('/users').then(function (response) {
                    if (response.status === 200) {
                        const teamsResponse = teamsUsersDataMergeProcessing(response.data.users, teams_data)
                        let payloadObj = {
                            payload: teamsResponse,
                            loaded: true
                        }
                        dispatch(teamDetails(payloadObj.payload))
                    }
                })
            }
            if (response_data !== undefined) {
                fetchAllRecipients(response_data, dispatch, teams_data)
            }

        }
    })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export const fetchAllRecipients = (userData, dispatch, teams_data) => {
    axios.get('/users?offset=0&limit=10000').then(async (response) => {
        if (response.status === 200) {
            let users_list = response.data
            let licences_list = []
            let teams_list = teams_data
            if (teams_list === undefined) {
                await axios.get('/teams').then((response) => {
                    if (response.status === 200) {
                        teams_list = response.data
                    }
                })
            }
            await axios.get('/licences').then((response) => {
                if (response.status === 200) {
                    licences_list = response.data
                }
            })
            let user_data = users_list && users_list.users.filter(user => user.is_active === true)
            let removeLoggedInUser = userData => user_data && user_data.filter(user => user.user_id !== userData)
            const http_response = {
                'teams': teams_list,
                'users': { 'users': user_data },
                'licences': licences_list
            }
            let users = modifyReciepientsResponse(http_response);
            dispatch(recipientDetails(users))
            dispatch(shareRecipientsDetail(removeLoggedInUser(userData.user_id)))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const forgotPassword = (payload, navigate, dispatch) => {
    axios.post('/auth/reset_password', payload)
        .then(function (response) {
            navigate('/')
            dispatch(component('login'))
            messageAlert(dispatch, response.data.message, 'success')
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response && error.response.status === 500) {
                    messageAlert(dispatch, "User unknown #Email or username not recognised. Try again or contact your administrator.", 'danger')
                }
                else if (error.response && error.response.status === 400) {
                    messageAlert(dispatch, error.response.data.message, 'danger')
                }
                else {
                    messageAlert(dispatch, "We don't recognise that username or email, try again or contact your administrator", 'danger')
                }
            }
        });
}

export function signOutUser(dispatch, userData) {
    axios.get('/logout')
        .then(function (response) {
            dispatch(signOut())
            if (!userData.isMasqueradeUser) {
                window.Intercom('trackEvent', 'Sign out')
            }
        })
        .then(() => {
            window.location = "/"
            document.cookie = "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Lax";
        })
        .catch(function (error) {
            localStorage.clear()
            window.location = "/"
            messageAlert(dispatch, error.response, 'danger')
        });
}

export function fetchUniverseCount(dispatch) {
    try {
        axios.get("/settings").then((response) => {
            let universeCount = {};
            if (response.data && response.data.settings && response.data.settings.length > 0) {
                for (let item of response.data.settings) {
                    if (item.parameter === "LAST_WEEK_UNIVERSE_COUNT") {
                        universeCount.lastWeekUniverseCount = item.value;
                    }
                    if (item.parameter === "TOTAL_PROJECTS_UNIVERSE_COUNT") {
                        universeCount.totalUniverseCount = item.value;
                    }

                }
                dispatch(updateUniverseCount(universeCount))
            }
        })
    } catch (error) {
        handleError(error, dispatch)
    }


}
export const fetchAllFrameworksList = (dispatch) => {
    axios.get('lookups?lookups=framework').then((response) => {
        if (response.status === 200) {
            dispatch(updateFrameworkList(response?.data?.lookups?.framework))
        }
    }).catch((error) => {
        handleError(error, dispatch)
    })
}

export const changePassword = (payload, dispatch, navigate) => {
    axios.put("users", payload)
        .then((response) => {
            let fieldSales = false
            let insideSales = false
            let adminLicense = false
            let justAskLicense = false
            if (response.status === 201) {
                payload.licenses.map(function (licenseNo, index) {
                    if (FIELD_SALES_LICENSE.includes(licenseNo)) {
                        fieldSales = true
                    }
                    if (INSIDE_SALES_LICENSE.includes(licenseNo)) {
                        insideSales = true
                    }
                    if (ADMIN_LICENSE.includes(licenseNo)) {
                        adminLicense = true
                    }
                    if (JUST_ASK_LICENSE.includes(licenseNo)) {
                        justAskLicense = true
                    }
                })
                if (navigator.userAgent.match(/Android/i)) {
                    if (payload.sectorNo === 1) {
                        window.location = ABI_PLAY_APP
                    } else if (payload.sectorNo === 15) {
                        window.location = ABI_PLAY_APP
                    } else {
                        window.location = ANDROID_PROJECT_LOCATOR_URL
                    }

                } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                    if (payload.sectorNo === 1) {
                        window.location = ABI_IOS_APP
                    } else if (payload.sectorNo === 15) {
                        window.location = ABI_IOS_APP
                    } else {
                        window.location = IOS_PROJECT_LOCATOR_URL
                    }
                } else {
                    let user = {
                        username: payload.username,
                        password: payload.password,
                        rememberMe: true,
                        stno: payload.stno
                    }
                    messageAlert(dispatch, "Password updated successfully", 'success')
                    if (insideSales === true || adminLicense === true || justAskLicense === true) {
                        signInUser(user, navigate, dispatch, {}, null, "")
                    } else {
                        messageAlert(dispatch, "You are not authorised for this app. Please check with your Account Manager.", 'success')
                        navigate("/")
                    }
                }


            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export const validateLink = (payload, dispatch, navigate) => {
    axios.get(`auth?user_id=${payload.user_no.toString()}&validation_token=${payload.token.toString()}`)
        .then((response) => {
            if (response.data === "False") {
                navigate("/")
                messageAlert(dispatch, "Reset password link has expired", 'danger')

            }
            else {
                dispatch(signIn(response.data))
            }

        }).catch((error) => {
            handleError(error, dispatch)
        })
}