import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  fetchDetailUpdatesData,
  fetchLabelData,
  fetchLabelDataForAllDetails,
  fetchProjectDetailsFramework,
  fetchRolesForProjects,
} from "../apiclient";
import UpdateTab from "../updatetab";
import ActivitiesTab from "../activitytabs";
import DetailsTab from "./detailtabs/detailstabs";
import RoleCompanyTabs from "../roles&companytabs";
import MapDirections from "../map";
import Framework from "../framework/framework";
import { rolesForProject, updateActivityIsClicked, updateTabDetails } from "../redux/reducer";
import { useLocation } from "react-router-dom";
import { PROJECTDETAILS } from "../../../utils/tour";

const ProjectDetail = (props) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(props.activeTab);
  const [mapDirectionFlag, setMapDirectionFlag] = useState(false);
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();
  const [objectId] = useState(location.pathname.split("/")[3]);
  const userData = useSelector((state) => state.user);

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  useEffect(() => {
    if (!project?.isPrint && activeTab === "5") {
      setMapDirectionFlag(true);
    }
  }, [project?.isPrint]);

  const toggle = (tab) => {
    props.setActiveTab(tab);
    dispatch(updateActivityIsClicked(false));
    setMapDirectionFlag(false);
    if (tab === "3") {
      dispatch(updateTabDetails([]))
      fetchDetailUpdatesData(
        project && project.detailsPage.objectId,
        project && project.detailsPage.objectType,
        dispatch
      );
    } else if (tab === "4") {
      fetchLabelData(
        project && project.detailsPage.objectId,
        project && project.detailsPage.objectType,
        dispatch,
        userData
      );
      fetchLabelDataForAllDetails(dispatch);
    } else if (tab === "2") {
      dispatch(rolesForProject({ roleGroups: [] }))
      fetchRolesForProjects(
        project && project.detailsPage.objectId,
        dispatch,
        project && project.isProjectFrameWork
      );
    } else if (tab === "6") {
      fetchProjectDetailsFramework(
        {
          objectId: project && project.detailsPage.objectId,
          offset: 0,
          limit: 20,
        },
        dispatch
      );
    } else if (tab === "5") {
      setMapDirectionFlag(true);
    }
  };
  useEffect(() => {
    if (project.activityIsClicked === true) {
      if (
        project?.summaryActivity === "tags" ||
        project?.summaryActivity === "notes" ||
        project?.summaryActivity === "shares" ||
        project?.summaryActivity === "calendar_events" ||
        project?.summaryActivity === "collab"
      ) {
        props.setActiveTab("4");
      } else {
        props.setActiveTab("1");
      }
    }
  }, [project.summaryActivity, project.activityIsClicked]);

  useEffect(() => {
    toggle(activeTab);
  }, [location?.pathname]);


  return (
    <Row className="pt-3 pb-3 ms-0 me-0 mb-3 project-tab-panel">
      <Col className="p-0 project-tab-wrapper">
        <Nav tabs>
          <span className="d-flex" data-intro={PROJECTDETAILS.tabs.intro} data-position={PROJECTDETAILS.tabs.position} data-class={PROJECTDETAILS.tabs.class}>

            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => toggle("1")}
              >
                Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => toggle("2")}
              >
                Roles & Companies
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => toggle("3")}
              >
                Updates
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "4" ? "active" : ""}
                onClick={() => toggle("4")}
              >
                Activities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "5" ? "active" : ""}
                onClick={() => toggle("5")}
              >
                Map
              </NavLink>
            </NavItem>
            {project && project.detailsPage.project_framework_description ? (
              <NavItem>
                <NavLink
                  className={activeTab === "6" ? "active" : ""}
                  onClick={() => toggle("6")}
                >
                  Framework
                </NavLink>
              </NavItem>

            ) : (
              ""
            )}
          </span>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="px-3 py-0">
            <DetailsTab />
          </TabPane>
          <TabPane tabId="2" className="p-3">
            <RoleCompanyTabs sharedType="project" />
          </TabPane>
          <TabPane tabId="3" className="updates-tab p-3">
            <UpdateTab activeTab={activeTab} />
          </TabPane>
          <TabPane tabId="4" className="p-3">
            <ActivitiesTab sharedType="project" objectId={objectId} />
          </TabPane>
          <TabPane tabId="5" className="p-0">
            <MapDirections mapDirectionFlag={mapDirectionFlag} />
          </TabPane>
          {project && project.detailsPage.project_framework_description ? (
            <TabPane tabId="6" className="p-3">
              <Framework tab={activeTab} />
            </TabPane>
          ) : (
            ""
          )}
        </TabContent>
      </Col>
    </Row>
  );
};

export default ProjectDetail;
