import React, { useEffect, useState } from "react";
import {
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import TeamsItem from "./teams";
import Users from "./users";
import { fetchShareTeams } from "../login/apiclient";
import "./myteams.scss";

const TeamsDetails = () => {
    const { hash } = useLocation()
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState(hash.replace('#', ''));
    const dispatch = useDispatch();

    const toggle = (tab) => {
        navigate("/app/teams#" + tab)
    };

    useEffect(() => {
        if (hash)
            setActiveTab(hash.replace('#', ''))
    }, [hash])

    useEffect(() => {
        fetchShareTeams(dispatch);

        // To update document title
        document.title = 'Barbour ABI'
    }, []);

    return (
        <Row className="pt-2 pb-2 ms-0 me-0 mb-3 teams-tab-panel">
            <Col className="p-0 company-tab-wrapper">
                <Row className="header-section flex-row-reverse pb-2">
                    <Col lg="12" md="12" sm="12" className="pt-2">
                        <h2 className="h5 pt-1 ps-4">My teams</h2>
                    </Col>
                </Row>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === "teams" ? "active" : ""}
                            onClick={() => toggle("teams")}
                        >
                            Teams
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "users" ? "active" : ""}
                            onClick={() => toggle("users")}
                        >
                            Users
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="teams" className="px-3">
                        <TeamsItem activeTab={activeTab} />
                    </TabPane>
                    <TabPane tabId="users" className="px-3">
                        <Users activeTab={activeTab} />
                    </TabPane>
                </TabContent>
            </Col>
        </Row>
    );
};

export default TeamsDetails;
