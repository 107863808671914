import React, { useState, useEffect } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Input, Label, DropdownMenu, DropdownToggle, DropdownItem, Dropdown } from 'reactstrap'
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchProjectsForRoleId, updateSelectorControlTags, updateSelectorFavUnfav } from "../../../apiclient"
import SearchListRow from '../../../list/searchListRow';
import SearchSelector from '../../../list/searchSelector';
import TagsModal from '../../../list/tagsModal';
import { updateListSelection, updateProjectDataForRoleId, updateSelectedTagDetails, updateSelectorControlSelectionCount, updateShowMoreOffsetLimit } from '../../../redux/reducer';
import { updateRemoveFilterToggle } from '../../../../search/redux/reducer';
import ColoredScrollbars from '../../../../common/js/coloredScrollbars';
import CommonPlaceholder from '../../../framework/commonPlaceholder';
import { selectorControllerArchive } from '../../../../search/apiclient';
import { createPayloadForProjectsForRoleId, fetchSortValue } from '../../../../../utils/commonFunctions';
import _ from 'lodash';

const ProjectTab = () => {
    const location = useLocation().pathname;
    const [activeTab, setActiveTab] = useState(0);
    const details = useSelector((state) => state.project)
    const [roles, setRoles] = useState([])
    const [projectsForRoleData, setProjectsForRoleData] = useState(details && details.projectDataForRoleId)
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const [activeSort, setActiveSort] = useState('Latest')
    const [modal, setModal] = useState(false)
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    const [totalSelectedCount, setTotalSelectedCount] = useState(0)
    const [buttonClicked, setButtonClicked] = useState(false)
    const [roleID, setRoleId] = useState()
    const userData = useSelector(state => state.user.user)
    const search = useSelector(state => state.search)
    const settings = useSelector((state) => state.settings)
    const searchPersist = useSelector(state => state.searchPersist)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();

    const toggle = (tab) => {
        dispatch(updateProjectDataForRoleId({ projects: [] }))
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        dispatch(updateListSelection({ count: 0, entityType: 'companyProjects' }))
        dispatch(updateSelectorControlSelectionCount(0))
        let roleId = []
        if (tab.role_code_display === 'Any Role') {
            roles && roles.map(role => {
                if (role.role_code !== 0) {
                    roleId.push(role.role_code)
                }
            })
        }
        else {
            roleId.push(tab.role_code)
            roleId = parseInt(roleId.toString())
        }
        setRoleId(roleId)
        setShowMoreClicked(true)
        setActiveTab(tab.role_code)
        handleDropDownItemClick(0)
    }

    useEffect(() => {
        let roleId = []
        if (activeTab === 0) {
            if (roles && roles.length > 0) {
                roles && roles.map(role => {
                    if (role.role_code !== 0) {
                        roleId.push(role.role_code)
                    }
                })
                let query = createPayloadForProjectsForRoleId(roleId, location?.split('/')[4], search.removeFilterToggle, searchPersist)
                setRoleId(roleId)
                fetchProjectsForRoleId(query, fetchSortValue(activeSort.toLowerCase(), 'project'), dispatch, details)
            }
        }
    }, [roles])

    useEffect(() => {
        setProjectsForRoleData(details && details.projectDataForRoleId)
    }, [details && details.projectDataForRoleId, activeTab])

    useEffect(() => {
        setRoles(details && details.companyProjectRoles)
    }, [details.companyProjectRoles])

    const handleCommonSort = (sort) => {
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        setActiveSort(sort === 'project_start_min,project_id' ? 'Start Date' : sort === '-project_value' ? 'Value' : sort === 'project_finish_max,project_id' ? 'End Date' : 'Latest')
        dispatch(updateListSelection({ count: 0, entityType: 'companyProjects' }))
        setShowMoreClicked(true)
    }

    const getTotalRows = () => {
        let totalCount = 0
        if (projectsForRoleData && projectsForRoleData.length > 0) {
            totalCount = details && details.projectDataForRoleIdCount
        }
        return totalCount
    }

    const handleTagsSelection = () => {
        setModal(!modal)
    }

    const handleTagsCancel = () => {
        dispatch(updateSelectedTagDetails({}))
        setModal(!modal)
        handleDropDownItemClick(0)
    }

    const getUniqueSelectedList = () => {
        let objectIds = []
        projectsForRoleData && projectsForRoleData.map((entity, index) => {
            if (entity.is_selected) {
                objectIds.push(entity.company_id)
            }
        })
        objectIds = [...new Set(objectIds)]
        return objectIds
    }

    useEffect(() => {
        setTotalSelectedCount(details && details.selectorControlSelectionCount === undefined ? totalSelectedCount : details && details.selectorControlSelectionCount)
    }, [details && details.selectorControlSelectionCount])

    const handleDownloadPDFSelection = () => {

    }

    const handleDropDownItemClick = (count) => {
        dispatch(updateListSelection({ count, entityType: 'companyProjects' }))
        if (count) {
            setDropDownOpen(false)
        }
    }

    const handleFavChangeSelection = (action) => {
        let objectIds = getUniqueSelectedList()
        if (objectIds.length > 0) {
            const payload = {
                object_type: 'project',
                favourite: action === "Favourite" ? true : false,
                offset: 0,
                role: roleID
            }
            if (details && details.selectorControlSelectionCount) {
                payload['selected_records'] = parseInt(details && details.selectorControlSelectionCount)
            }
            if (details.includeIds && details.includeIds.length > 0) {
                payload['selected_records'] = parseInt(payload['selected_records']) - details && details.includeIds.length
                payload['project_id'] = details && details.includeIds
            }
            if (details.excludeIds && details.excludeIds.length > 0) {
                payload['selected_records'] = parseInt(payload['selected_records']) + details && details.excludeIds.length
                payload['exclude_ids'] = details && details.excludeIds
            }
            payload['identifier'] = 'company_projects_role'
            if (activeSort) {
                payload['sort'] = fetchSortValue(activeSort.toLowerCase(), 'project')
            }
            if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
                delete payload['selected_records']
            }
            let query = createPayloadForProjectsForRoleId(roleID, details.detailsPage.company_id, search.removeFilterToggle, searchPersist)
            payload['query'] = query
            updateSelectorFavUnfav('projects', details.detailsPage.company_id, payload, dispatch, details)
            handleDropDownItemClick(0)
        }
    }

    const handleTagsSubmit = () => {
        let objectIds = getUniqueSelectedList()
        let id = details && details.detailsPage && details.detailsPage.company_id
        if (objectIds.length > 0) {
            let payload = {
                object_type: 'project',
                offset: 0,
                role: roleID,
                listName: 'company_projects_role'
            }
            if (details?.selectedTagsDetails?.tagIds?.length > 1 && details?.selectedTagsDetails?.tagIds?.includes(0)) {
                let tag_name = []
                let tag_id = []
                _.map(details?.selectedTagsDetails?.tagIds, (tagId, index) => {
                    if (tagId === 0) {
                        tag_name.push(details.selectedTagsDetails.tagDescriptions[index])
                    } else {
                        tag_id.push(details.selectedTagsDetails.tagIds[index])
                    }
                })
                if (tag_name.length) {
                    payload['tag_name'] = tag_name
                }
                if (tag_id.length) {
                    payload['tag_id'] = tag_id
                }
            } else if (details?.selectedTagsDetails?.tagIds?.includes(0)) {
                payload['tag_name'] = details.selectedTagsDetails.tagDescriptions
            } else {
                payload['tag_id'] = details.selectedTagsDetails.tagIds
            }
            if (details && details.selectorControlSelectionCount) {
                payload['selected_records'] = parseInt(details && details.selectorControlSelectionCount)
                if ('selected_records' in payload && payload['selected_records'] !== 0) {
                    payload['query'] = {
                        'project_id': { 'operator': '=', 'value': id },
                    }
                }
                if (details.includeIds && details.includeIds.length > 0) {
                    payload['selected_records'] = parseInt(payload['selected_records']) - details.includeIds.length
                    payload['project_id'] = details.includeIds
                }
                if (details.excludeIds && details.excludeIds.length > 0) {
                    payload['selected_records'] = parseInt(details && details.selectorControlSelectionCount) + details.excludeIds
                    payload['exclude_ids'] = details.excludeIds
                }
                if (activeSort) {
                    payload['sort'] = fetchSortValue(activeSort.toLowerCase(), 'project')
                }
                if ('selected_records' in payload && payload['selected_records'] == 0) {
                    delete payload['selected_records']
                }
                let query = createPayloadForProjectsForRoleId(roleID, details.detailsPage.company_id, search.removeFilterToggle, searchPersist)
                payload['query'] = query
            }
            handleTagsCancel()
            handleDropDownItemClick(0)
            updateSelectorControlTags('projects', details.detailsPage.company_id, payload, dispatch, details)
        }
    }

    const toggleSortOrder = () => {
        setDropDownOpen(!dropdownOpen)
    }

    const handleToggleChange = () => {
        dispatch(updateShowMoreOffsetLimit({ offset: 0, records: 50 }));
        setButtonClicked(!buttonClicked)
        dispatch(updateListSelection({ count: 0, entityType: 'companyProjects' }))
        dispatch(updateRemoveFilterToggle(!search.removeFilterToggle))
        setShowMoreClicked(true)
    }

    useEffect(() => {
        if (showMoreClicked) {
            let query = createPayloadForProjectsForRoleId(roleID, location?.split('/')[4], search.removeFilterToggle, searchPersist)
            fetchProjectsForRoleId(query, fetchSortValue(activeSort.toLowerCase(), 'project'), dispatch, details)
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);

    const handleShowMore = () => {
        setShowMoreClicked(true)
        let newOffset = details.showMoreOffsetLimit.offset + details.showMoreOffsetLimit.records;
        dispatch(updateShowMoreOffsetLimit({ offset: newOffset, records: details.showMoreOffsetLimit.records }));
    }

    const handleArchiveChangeSelection = (action) => {
        const payload = {
            offset: 0,
            object_type: 'project',
            archive: action ? true : false
        }
        if (details && details.selectorControlSelectionCount) {
            payload['selected_records'] = parseInt(details?.selectorControlSelectionCount)
        }
        if (details.includeIds && details.includeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] - details && details.includeIds.length
            payload['project_id'] = details && details.includeIds
        }
        if (details.excludeIds && details.excludeIds.length > 0) {
            payload['selected_records'] = payload['selected_records'] + details && details.excludeIds.length
            payload['exclude_ids'] = details && details.excludeIds
        }
        payload['identifier'] = 'company_projects_role'
        if (activeSort) {
            payload['sort'] = fetchSortValue(activeSort.toLowerCase(), 'project')
        }
        if (('selected_records' in payload && payload['selected_records'] === 0) || isNaN(payload['selected_records'])) {
            delete payload['selected_records']
        }
        let query = createPayloadForProjectsForRoleId(roleID, details.detailsPage.company_id, search.removeFilterToggle, searchPersist)
        payload['query'] = query
        selectorControllerArchive(payload, '', '', dispatch, {
            settings: settings,
            details: details,
            userData: user
          })
        handleDropDownItemClick(0)
    }
    return (
        <>
            <div className="tab-pane active">
                <div className="box-view">
                    <Row className="m-0">
                        
                        <Col sm="12" md="12" lg="3" className="p-0 nav-col">
                            <div className="height-400">
                            <ColoredScrollbars>
                            <div className="select-col p-3"><p className="m-0 col-heading text-truncate">Select a role to view projects</p>
                            </div>
                            <Nav tabs vertical className="mb-5">
                                {roles && roles.length > 0 &&
                                    roles.map(function (role, index) {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    className={classnames({ active: parseInt(activeTab) === role.role_code })}
                                                    onClick={() => { toggle(role); }}
                                                >
                                                    {role.role_code_display} {role.role_code_display === 'Any Role' ? role.project_count ? <span>({role.project_count})</span> : <span>(0)</span> : role.count ? <span>({role.count})</span> : <span>(0)</span>}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>
                            </ColoredScrollbars>
                            </div>
                        </Col>
                        
                        <Col sm="12" md="12" lg="9" className="p-0 detail-col">
                            <TabContent activeTab={activeTab} className="p-0 pb-3">
                                <div className={`selected-col p-3 ${projectsForRoleData !== null && projectsForRoleData?.length === 0 ? 'd-none' : ""}`}><p className="m-0 col-heading">Click on a project to view the details</p> </div>
                                {roles?.length > 0 && roles.map(function (role, index) {
                                    if (parseInt(activeTab) === role.role_code && projectsForRoleData?.length > 0) {
                                        return (
                                            <TabPane tabId={role.role_code} key={`${role.role_code}${index}`}>

                                                <div className="selector-container no-show-more">
                                                    <div className="search-selector justify-content-between d-flex">
                                                        <SearchSelector
                                                            isProjectList={true}
                                                            handleFavChangeSelection={handleFavChangeSelection}
                                                            handleTagsSelection={handleTagsSelection}
                                                            handleDownloadPDFSelection={handleDownloadPDFSelection}
                                                            handleDropDownItemClick={handleDropDownItemClick}
                                                            totalSelectedCount={totalSelectedCount}
                                                            totalRows={getTotalRows()}
                                                            user={userData}
                                                            entitiesCount={getTotalRows()}
                                                            activeSort={activeSort}
                                                            getUniqueSelectedList={getUniqueSelectedList}
                                                            handleArchiveChangeSelection={handleArchiveChangeSelection}
                                                        >
                                                        </SearchSelector>
                                                        <div className="d-flex pt-3 remove-filter-container">
                                                            {projectsForRoleData && projectsForRoleData.length > 0 &&
                                                                <div className="switch-panel-project-tab">
                                                                    <div className="switch-project-tab d-flex align-items-center">
                                                                        <span className="me-2">Remove filters</span>
                                                                        <Input id="switch-apply-filter" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                                                            checked={search.removeFilterToggle}
                                                                            onClick={handleToggleChange}
                                                                        />
                                                                        <Label for="switch-apply-filter" className="mb-0"></Label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                projectsForRoleData && projectsForRoleData.length > 0 &&
                                                                <div className="px-3 toggle-role-dropdown sort-results other-pro-sort">
                                                                    <Dropdown isOpen={dropdownOpen} toggle={toggleSortOrder}>
                                                                        <DropdownToggle caret>
                                                                            {activeSort}
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem onClick={() => handleCommonSort('-project_last_published')} key={1} value={'Letter mail merge'}>
                                                                                Latest
                                                                            </DropdownItem>
                                                                            <DropdownItem divider />
                                                                            <DropdownItem onClick={() => handleCommonSort('-project_value')} key={3} value={'Letter mail merge'}>
                                                                                Value
                                                                            </DropdownItem>
                                                                            <DropdownItem divider />
                                                                            <DropdownItem onClick={() => handleCommonSort('project_start_min,project_id')} key={3} value={'Letter mail merge'}>
                                                                                Start Date
                                                                            </DropdownItem>
                                                                            <DropdownItem divider />
                                                                            <DropdownItem onClick={() => handleCommonSort('project_finish_max,project_id')} key={2} value={'Letter mail merge'}>
                                                                                End Date
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-wrapper-other-pro height-400 pb-3 mt-3 ps-3">
                                                    <ColoredScrollbars className="scroll-wrapper">
                                                        <Row className="result-list-container content-panel">
                                                            <Col xl={12} lg={12} md={12} className="ps-0">
                                                                {projectsForRoleData.map((entity, index) => {
                                                                    const newEntity = Object.assign({
                                                                        objectId: entity.project_id,
                                                                        objectType: "project"
                                                                    }, entity);

                                                                    return (<SearchListRow
                                                                        prevNextList='companyProjects'
                                                                        entity={newEntity}
                                                                        role={role}
                                                                        roleId={roleID}
                                                                        sort={activeSort}
                                                                        key={`resultrow${index}`}
                                                                        index={index} />)
                                                                })}
                                                            </Col>
                                                        </Row>
                                                        {
                                                            details?.isProjectDataForRoleIdShowMoreActive ? (
                                                                <div className='d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                                                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                                                </div>
                                                            ) : ''
                                                        }
                                                    </ColoredScrollbars>
                                                </div>
                                                <TagsModal
                                                    handleTagsCancel={handleTagsCancel}
                                                    handleTagsSubmit={handleTagsSubmit}
                                                    selectedTags={details && details.selectedTagsDetails}
                                                    showModal={modal}
                                                >
                                                </TagsModal>
                                            </TabPane>
                                        )
                                    } else if (projectsForRoleData !== null && projectsForRoleData?.length === 0) {
                                        return (
                                            <TabPane tabId={role.role_code} key={`${role.role_code}${index}`}>
                                                <div className="selected-col p-3"><p className="m-0 col-heading"><strong>{details?.detailsPage?.name}</strong> does not work on other projects as <strong>{role.role_code_display}</strong></p></div>
                                                <div className="d-flex justify-content-between align-items-start p-3">
                                                    <span>Projects hidden due to filters applied</span>
                                                    <div className="d-flex remove-filter-container">
                                                        <div className="switch-panel-project-tab">
                                                            <div className="switch-project-tab d-flex align-items-center">
                                                                <span className="me-2">Remove filters</span>
                                                                <Input id="switch-apply-filter" type="checkbox" className="cmn-toggle cmn-toggle-round"
                                                                    checked={search.removeFilterToggle}
                                                                    onClick={handleToggleChange}
                                                                />
                                                                <Label for="switch-apply-filter" className="mb-0"></Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        )
                                    } else {
                                        <CommonPlaceholder />
                                    }
                                })
                                }
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ProjectTab;
