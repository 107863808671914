import axios from 'axios';
import ES6Promise from 'es6-promise';

ES6Promise.polyfill();

export default  function getAxios() {
    axios.defaults.baseURL = window.location.origin
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.get['Content-Type'] = 'application/json';
    axios.defaults.headers.delete['Content-Type'] = 'application/json';

    axios.defaults.xsrfCookieName = '__Secure-csrfToken';
    axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
    if (csrfToken) {
        axios.defaults.headers['X-CSRFToken'] = csrfToken;
    }

    return axios;
}

