import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../../common/js/icon';
import { deleteSaveSearch, editDefaultSavedSearch, fetchJAskConversationResults } from '../apiclient';
import ColoredScrollbars from '../../common/js/coloredScrollbars';
import DeleteModal from '../deleteModal';
import ShareModal from '../shareModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import FeedsModal from '../feedsModal';
import { updateSavedConversationRequest, updateSavedSearchOffsetLimitValue } from '../redux/reducer';
import { TOURSAVEDSEARCH } from '../../../utils/tour';
import _ from 'lodash';
import { addMessage, clearMessagesAfterIndex, resetAll, updatedSelectedSavedSearchJA, updateErrrorInApi, updateFilterQuery, updateJustAskQuery } from '../../justask/search/redux/searchPeristReducer';
import { fetchSearchListForAllObjectType, resetAllSearch, resetIncludeExcludeIds, rolesForProject, updateBackObjectId, updateDisplayContent, updateEmoji, updateHierarchyBack, updateIdsForShowRelatedEntities, updateIsShowProjectsOrCompanySelected, updatelistOfSelectedObjectIds, updateNavigationBackJA, updateNoResult, updateObjectId, updatePeopleProjectsList, updateProjectsForCompanyId, updateRemoveFilterToggleJA, updateSelectedSelectorControlValue, updateShowDetailPeople, updateShowHierarchyForJustAsk, updateShowSelectedCompaniesJA, updateShowSelectedProjectsJA } from '../../justask/search/redux/reducer';
import { updateContextType, updateOffsetLimit, updateSelectedSearchType } from '../../justask/search/filters/redux/reducer';

function MySavedConversations() {
    let navigate = useNavigate()
    const savedsearch = useSelector((state) => state.savedsearch)
    const userData = useSelector(state => state.user.user)
    const searchPersist = useSelector(state => state.justAskPersist)
    const justAsk = useSelector((state) => state.justAsk)
    const dispatch = useDispatch();
    const [savesearch, setsaveSearch] = useState(savedsearch?.detailsPage);
    const [isOpen, setIsOpen] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [deleteName, setDeleteName] = useState('')
    const [label, setLabel] = useState('')
    const [shareDetail, setShareDetail] = useState({})
    const [activeSavedSearch, setActiveSavedSearch] = useState({})
    const [defaultPayload, setDefaultPayload] = useState({})
    const [defaultApiCall, setDefaultApiCall] = useState(false)
    const [isDeleteApiCall, setIsDeleteApiCall] = useState(false)
    const [showMoreClicked, setShowMoreClicked] = useState(false)
    const [defaultSaveSearchId, setDefaultSaveSearchId] = useState('')
    const [customerProfile, setCustomerProfile] = useState(searchPersist?.customerProfile)
    const settings = useSelector((state) => state.settings)
    const user = useSelector((state) => state.user)

    useEffect(() => {
        setsaveSearch(savedsearch?.detailsPage)
        if (document.getElementsByClassName("saved-search-tag-text").length > 0) {
            for (var i = 0; i < document.getElementsByClassName("saved-search-tag-text").length; i++) {
                document.getElementsByClassName("saved-search-tag-text")[i].title = document.getElementsByClassName("saved-search-tag-text")[i].innerText
            }
        }
    }, [savedsearch.detailsPage])

    useEffect(() => {
        if (showMoreClicked) {
            fetchJAskConversationResults(dispatch, savedsearch)
            setShowMoreClicked(false)
        }
    }, [showMoreClicked]);

    useEffect(() => {
        setCustomerProfile(searchPersist?.customerProfile)
    }, [searchPersist?.customerProfile])


    useEffect(() => {
        if (isDeleteApiCall) {
            deleteSaveSearch(deleteId, deleteName, dispatch, savedsearch)
            setIsDeleteApiCall(false)
            setDeleteId('')
            setDeleteName('')
            setIsOpen(false)
            setLabel("")
        }
    }, [isDeleteApiCall])

    useEffect(() => {
        if (defaultApiCall) {
            editDefaultSavedSearch(defaultPayload, defaultSaveSearchId, dispatch, savedsearch, user);
            setDefaultApiCall(false)
        }
    }, [defaultApiCall])


    /**** Delete  ******/
    const handleDelete = (id, name) => {
        setLabel("delete")
        setIsOpen(true)
        setDeleteId(id)
        setDeleteName(name)
    }

    const handleCancelDelete = () => {
        setIsOpen(false)
        setDeleteId('')
        setDeleteName('')
        setLabel("")
        setShareDetail({})
    }

    const handleDeleteYes = () => {
        if (deleteId) {
            dispatch(updateSavedSearchOffsetLimitValue({ offset: 0, records: 21 }));
            setIsDeleteApiCall(true)
        }
    }

    const handleShowMore = () => {
        let newOffset = savedsearch?.offsetLimitValue?.offset + 20;
        dispatch(updateSavedSearchOffsetLimitValue({ offset: newOffset, records: savedsearch?.offsetLimitValue?.records }));
        setShowMoreClicked(true)
    }
    const renderDetails = (savedSearch) => {
        return (
            <div className="d-flex flex-wrap">
                <div className="saved-conversation-tag">
                    <div className="saved-conversation-tag-text">
                        {savedSearch?.just_ask_conversation?.rephrased_sentence}
                    </div>
                </div>
            </div>
        )
    }

    const handleSaveSearchClick = (e, item) => {
        e.stopPropagation();
        dispatch(clearMessagesAfterIndex(-1));
        dispatch(updatedSelectedSavedSearchJA({}));
        let conversations = item?.just_ask_conversation?.conversation
        _.map(conversations.slice(0, -1), conversation => {
            dispatch(addMessage({ text: conversation.request, type: 'user' }));
            dispatch(addMessage({ text: conversation.narrative, type: 'ai' }));
        })
        dispatch(updateSavedConversationRequest(conversations[conversations.length - 1]?.request))
        let clonedObj = { ...item}
        clonedObj['object_type'] = item?.just_ask_conversation?.search_context === "projects" ? 'project' : item?.just_ask_conversation?.search_context === "companies" ? 'company' : 'people'
        dispatch(updatedSelectedSavedSearchJA(clonedObj))

        // This code is similar to new search click
        dispatch(updateErrrorInApi(false))
        dispatch(resetIncludeExcludeIds([]))
        dispatch(updateSelectedSearchType(searchPersist.appliedContext))
        dispatch(updateOffsetLimit({ offset: 0, records: 50 }));
        dispatch(fetchSearchListForAllObjectType({leadsFlags: [], count: 0, filterType: searchPersist.appliedContext}));
        dispatch(resetAll());
        dispatch(resetAllSearch());
        if (justAsk.showSelectedCompanies || justAsk.showSelectedProjects) {
            searchPersist.appliedContext === "project"
                ? dispatch(updateShowSelectedCompaniesJA(false))
                : dispatch(updateShowSelectedProjectsJA(false));
            dispatch(updateIsShowProjectsOrCompanySelected(false))
            dispatch(updateSelectedSelectorControlValue(justAsk?.selectedLeadsFlag?.length));
        } else {
            dispatch(updateSelectedSelectorControlValue(0));
        }
        dispatch(updateShowDetailPeople(false));
        dispatch(updateHierarchyBack(true));
        dispatch(updateNoResult(false))
        dispatch(updateIdsForShowRelatedEntities([]));
        dispatch(rolesForProject([]));
        dispatch(updatePeopleProjectsList([]));
        dispatch(updateRemoveFilterToggleJA(true));
        dispatch(updateBackObjectId(undefined));
        dispatch(updateShowHierarchyForJustAsk({showProjectHierarchy: false, showCompanyHierarchy: false}));
        dispatch(updateShowSelectedCompaniesJA(false));
        dispatch(updateShowSelectedProjectsJA(false));
        dispatch(updatelistOfSelectedObjectIds({ leadsFlags: [], count: 0 }));
        dispatch(updateObjectId(undefined));
        dispatch(updateNavigationBackJA(""));
        dispatch(updateProjectsForCompanyId([]));
        dispatch(updateContextType({context: searchPersist.appliedContext, searchMode: "basic"}));
        dispatch(updateFilterQuery({}));
        dispatch(updateJustAskQuery({}))
        dispatch(updateEmoji(''))
        dispatch(updateDisplayContent(true))

        navigate("/app/justask")
    }

    const renderLoader = () => {
        return (
            <div className="pt-3 main-container container saved-search-page">
                <div className="content-wrapper">
                    <div className="result-panel-scrollbar d-block placeholder" >
                        <div class="top-margin">
                            <div className="save-container">
                                <div className="column-block ">
                                    <a className="save-block-wrapper" >
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="column-block ">
                                    <a className="save-block-wrapper">
                                        <div className="save-block">
                                            <div className="d-flex justify-content-between">
                                                <button className="save-title-placeholder btn btn-secondary"></button>
                                                <div className="search-button">
                                                    <button type="button" data-tip="true" data-for="edit" className="edit-button-placeholder btn btn-secondary">
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                            <div className="save-content">
                                                <span></span>
                                                <p></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    return (
        <>
            <div className="content-wrapper custom-one">
                <ColoredScrollbars className="result-panel-scrollbar">
                    <div className='save-container'>
                        {
                            savesearch?.length > 0 ?
                                savesearch && savesearch.map((item, index) =>
                                    <div className='column-block' key={index}>
                                        <a className='save-block-wrapper' onClick={(e) => handleSaveSearchClick(e, item)}>
                                            <div className='save-block'>
                                                <div className='row mt-2'>
                                                    <div className="col-6">
                                                        <div className='save-title mb-3'>
                                                            {item?.just_ask_conversation?.search_context === 'projects' ? (
                                                                <Icon color="#053874" size={25} icon="icon-gen-project-search" className="me-1" data-intro={TOURSAVEDSEARCH.title.intro} data-position={TOURSAVEDSEARCH.title.position} data-class={TOURSAVEDSEARCH.title.class} />
                                                            ) : item?.just_ask_conversation?.search_context === 'companies' ? (
                                                                <Icon color="#053874" size={25} icon="icon-gen-comp-search" className="me-1" data-intro={TOURSAVEDSEARCH.title.intro} data-position={TOURSAVEDSEARCH.title.position} data-class={TOURSAVEDSEARCH.title.class} />
                                                            ) : (
                                                                <Icon color="#053874" size={25} icon="icon-gen-ppl-search" className="me-1" />
                                                            )
                                                            }

                                                            <span>{item.saved_search_name}</span>

                                                        </div>
                                                    </div>


                                                    {index == 0 ?
                                                        (<div className="hori-placeholder-tour left-zero" data-intro={TOURSAVEDSEARCH.filteroptions.intro} data-position={TOURSAVEDSEARCH.filteroptions.position} data-class={TOURSAVEDSEARCH.filteroptions.class} >&nbsp;</div>) :
                                                        ('')
                                                    }
                                                </div>
                                                <ReactTooltip id="delete" place="top" type='dark' effect="solid">
                                                    <p className="tool-tip-sidebar">Delete</p>
                                                </ReactTooltip>
                                                <div className="saved-search-tag-listing">
                                                    {renderDetails(item)}

                                                </div>
                                            </div>
                                        </a>

                                        <div className="save-search-action">
                                            <div className="d-flex justify-content-end">
                                                {index == 0 ?
                                                    (<div className="hori-placeholder-tour left-right-zero bottom-zero" data-intro={TOURSAVEDSEARCH.actionbutton.intro} data-position={TOURSAVEDSEARCH.actionbutton.position} data-class={TOURSAVEDSEARCH.actionbutton.class} >&nbsp;</div>) :
                                                    ('')
                                                }

                                                <ul className='search-button d-flex p-0'>
                                                    {userData.user_id === item.created_by ? <li className='p-1' data-tooltip-id="delete" data-tooltip-hidden={!settings.preferences.hint_text}><Icon size={25} icon="icon-gen-trash"
                                                        onClick={() => handleDelete(item.saved_search_id, item.saved_search_name)} /></li> : ''}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                ) :

                                savedsearch?.isLoaderActive ? renderLoader() :
                                    savedsearch?.isMyJAskConversationActive ?
                                        (<div className="no-saved-search">
                                            <p>You have no saved conversations</p>
                                            <span>To create one, go to Just Ask and click "Save conversation"</span>
                                        </div>)
                                        :
                                        (<div className="no-saved-search">
                                            <p>You have no saved conversations shared with you</p>
                                        </div>)


                        }
                        {
                            savedsearch?.isLoaderActive === false && savesearch?.length > 0 && savedsearch?.isSavedSearchShowMoreActive ? (
                                <div className='show d-flex justify-content-center pt-3 pb-3 showmore-result'>
                                    <button className="btn btn-block btn-primary" onClick={handleShowMore}>Show more</button>
                                </div>
                            ) : ''
                        }

                    </div>
                </ColoredScrollbars>
            </div>
            {
                label === 'delete' && (
                    <DeleteModal
                        isOpen={isOpen}
                        title={deleteName}
                        handleDeleteYes={handleDeleteYes}
                        handleCancelDelete={handleCancelDelete}
                    />
                )
            }

            {
                label === 'share' && (
                    <ShareModal
                        isOpen={isOpen}
                        shareDetail={shareDetail}
                        handleCancelDelete={handleCancelDelete}
                    />
                )
            }
            {
                label === 'feeds' && (
                    <FeedsModal
                        isOpen={isOpen}
                        handleCancelDelete={handleCancelDelete}
                        activeSavedSearch={activeSavedSearch}

                    />
                )
            }

        </>
    );
}

export default MySavedConversations;