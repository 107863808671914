import _, { cloneDeep } from 'lodash';
import getAxios from '../../utils/baseApi';
import { createCommonQuery, updateSearchListForFav, updateSearchListForTag } from '../../utils/commonFunctions';
import { handleError } from '../../utils/errorHandler';
import { COMPANY_SEARCH_FIELDS, ROLES_SEARCH_FIELDS } from '../../utils/settings';
import { updateLeagueOffsetLimitValue, updateLeagueTable } from '../search/redux/searchPeristReducer';
import { resetIncludeExcludeIds, resetRelationalEntititySelectionCount, updateLeagueNoResult, updateLeagueRemoveFilterToggle, updateLeagueSearchListCount, updateProcessId, updateSearchListSelection, updateSearchlist, updateSelectedSelectorControlValue, updateShowMoreSearchList, updateSearchQuery } from './reducer';
import { messageAlert } from '../../utils';
import { updateIfMoreCompanyProjectsAvailable, updateProjectsForCompanyId, updateShowMoreProjectsForCompanyId } from '../search/redux/reducer';
import { setDataForProjectDetails } from '../details/postprocessing';
import { fetchSearchQuery } from '../search/utils';
var axios = getAxios()

const createPayload = (searchPersist, id, removeFilterToggle, dispatch) => {
    let query = {};

    for (const key in searchPersist?.leagueTable) {
        if (key === 'selectedRolesValue' && searchPersist?.leagueTable?.[key]?.value !== null) {
            let rolesQuery = createCommonQuery({
                key: "project_roles",
                operator: "=",
                value: searchPersist?.leagueTable?.[key]?.value
            })
            query = Object.assign(query, rolesQuery)
        }
        else if (key === 'selectedCategoriesValue' && searchPersist?.leagueTable?.[key]?.value !== null) {
            let categoriesQuery = createCommonQuery({
                key: "project_categories",
                operator: "=",
                value: searchPersist?.leagueTable?.[key]?.value
            })
            query = Object.assign(query, categoriesQuery)
        }
        else if (key === 'selectedRegionsValue' && searchPersist?.leagueTable?.[key]?.value !== null) {
            let regionsQuery = createCommonQuery({
                key: "company_geocode",
                operator: "=",
                value: searchPersist?.leagueTable?.[key]?.value
            })
            query = Object.assign(query, regionsQuery)
        }
        else if (key === 'selectedTimeValue' && searchPersist?.leagueTable?.[key]?.value !== null) {
            let timeQuery = {
                "project_last_published": {
                    "operator": "..",
                    "value1": searchPersist?.leagueTable?.[key]?.value,
                    "value2": 0
                }
            }
            query = Object.assign(query, timeQuery)
        }
        else if (key === 'selectedSavedSearch' && searchPersist?.leagueTable?.[key]?.query !== null) {
            const savedSearchQuery = searchPersist?.leagueTable?.selectedSavedSearch?.query;

            if (savedSearchQuery && typeof savedSearchQuery === 'object') {
                for (let subKey in savedSearchQuery) {
                    if (!query?.hasOwnProperty(subKey)) {
                        query[subKey] = savedSearchQuery[subKey];
                    }
                }
            }
        }
    }

    if (id && !removeFilterToggle) {
        let companyId = createCommonQuery({
            key: "company_id",
            operator: "=",
            value: id
        })
        query = Object.assign(query, companyId)
        return query
    } else if (id && removeFilterToggle) {
        let removeFilterToggleQuery = {}
        let companyId = createCommonQuery({
            key: "company_id",
            operator: "=",
            value: id
        })
        removeFilterToggleQuery = Object.assign(removeFilterToggleQuery, companyId)
        query = removeFilterToggleQuery
        return query
    } else {
        dispatch(updateSearchQuery(query))
        return query
    }
}

export const fetchLeagueTableResults = (searchPersist, dispatch, userData) => {
    const query = createPayload(searchPersist, null, null, dispatch)

    const url = !_.isEmpty(query) ? `/companies?&query=${JSON.stringify(query)}&offset=${searchPersist?.leagueOffsetLimitValue?.offset}&limit=${searchPersist?.leagueOffsetLimitValue?.records}&sort=${searchPersist?.leagueTable?.selectedSortValue === 'Projects' ? '-count_project_id' : '-sum_project_value'}&fields=count_project_id,${COMPANY_SEARCH_FIELDS}`
        :
        `/companies?offset=${searchPersist?.leagueOffsetLimitValue?.offset}&limit=${searchPersist?.leagueOffsetLimitValue?.records}&sort=${searchPersist?.leagueTable?.selectedSortValue === 'Projects' ? '-count_project_id' : '-sum_project_value'}&fields=count_project_id,${COMPANY_SEARCH_FIELDS}`

    axios.get(url)
        .then((response) => {
            if (response.status === 200) {
                dispatch(resetRelationalEntititySelectionCount())
                dispatch(updateLeagueRemoveFilterToggle(false))
                if (searchPersist?.leagueOffsetLimitValue?.offset === 0) {
                    dispatch(updateSearchlist([]))
                    dispatch(updateLeagueSearchListCount(0))
                    dispatch(updateSelectedSelectorControlValue(0))
                }
                var leadsFlags = response?.data?.companies
                var count = response.data?.aggregation?.company_count
                if (leadsFlags.length > 0) {
                    if (searchPersist?.leagueOffsetLimitValue?.offset > 0) {
                        dispatch(updateShowMoreSearchList(response.data.companies))
                        dispatch(updateLeagueSearchListCount(count))
                    } else {
                        dispatch(updateSearchlist(response.data.companies))
                        dispatch(updateLeagueSearchListCount(count))
                        dispatch(updateLeagueNoResult(false))
                    }
                }
                let isLoadMore = ((searchPersist?.leagueOffsetLimitValue?.offset + searchPersist?.leagueOffsetLimitValue?.records) < count) ? true : false
                if (isLoadMore && searchPersist?.leagueTable?.selectedTopSearch?.label === 'Show all') {
                    dispatch(updateLeagueTable({ dropdown: 'isShowMoreActive', value: true }))
                } else {
                    dispatch(updateLeagueTable({ dropdown: 'isShowMoreActive', value: false }))
                }
            } else if (response.status === 204) {
                dispatch(updateSearchlist([]))
                dispatch(updateLeagueSearchListCount(0))
                dispatch(updateLeagueNoResult(true))
            }
            if (!userData.isMasqueradeUser) {
                window.Intercom('trackEvent', 'League table')
            }
        }).catch((error) => {
            handleError(error, dispatch)
        })
}

export const updateSelectorControlAction = (payload, leagueTable, searchPersist, dispatch, relatedEntity) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    var jsonData = JSON.stringify(payload);
    try {
        axios.put(`${objectType}`, jsonData)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    let message = payload.favourite ? `Selected ${objectType} have been favourited` : `Selected ${objectType} have been unfavourited`
                    messageAlert(dispatch, message, 'success')
                    updateSearchListForFav(payload, leagueTable, dispatch, 'leagueTableFav')
                    dispatch(resetRelationalEntititySelectionCount())
                    if (leagueTable?.selectedSelectorControlValue !== 0 && !relatedEntity) {
                        dispatch(updateSelectedSelectorControlValue(0))
                        dispatch(updateSearchListSelection(0))
                    }
                    if (leagueTable?.countOfSelectedObjectIds !== 0) {
                        dispatch(updateSearchListSelection({ count: 0, isSelectionRelatedEntities: true }))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export const updateLeagueSelectorControlTags = (payload, leagueTable, searchPersist, dispatch) => {
    let objectType = payload.object_type === 'project' ? 'projects' : payload.object_type === "company" ? "companies" : "people";
    let payload1 = cloneDeep(payload)
    try {
        axios.post(`${objectType}/tags`, payload1)
            .then((resp) => {
                if (resp.status === 200 || resp.status === 201) {
                    dispatch(updateProcessId(resp.data.process_id))
                    messageAlert(dispatch, `Selected ${objectType} have been tagged.`, 'success')
                    dispatch(updateLeagueOffsetLimitValue({ offset: 0, records: searchPersist?.leagueOffsetLimitValue?.records }));
                    dispatch(updateSearchlist([]))
                    updateSearchListForTag(payload, leagueTable, dispatch, 'leagueTableFav')
                    dispatch(resetRelationalEntititySelectionCount())
                    if (leagueTable.selectedSelectorControlValue !== 0) {
                        dispatch(updateSearchListSelection(0))
                    }
                    dispatch(resetIncludeExcludeIds([]))
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}

export function fetchLeagueRolesForCompanyId(id, search, searchPersist, dispatch, removeFilterToggle) {
    const searchQuery = fetchSearchQuery(search, searchPersist, id);
    const leagueQuery = createPayload(searchPersist, id, removeFilterToggle, dispatch)

    let query = {
        ...searchQuery,
        query: leagueQuery
    };

    let projectSearchVal = query.project_search !== undefined ? query.project_search : ""
    let url = `projects?query=${JSON.stringify(query.query)}&fields=${ROLES_SEARCH_FIELDS}&event_type=${query.event_type}&offset=${query.offset}&limit=${query.records}&project_search=${projectSearchVal}`

    if (query.sort != "R") {
        url = url + "&sort=" + query.sort
    }
    try {
        axios.get(url)
            .then(async (response) => {
                if (response.status === 200) {
                    if (response.data.projects && response.data.projects.length > 0) {
                        if (response.data && response.data.aggregation.project_count > (parseInt(query?.offset) + parseInt(query?.records))) {
                            dispatch(updateIfMoreCompanyProjectsAvailable(true))
                        } else {
                            dispatch(updateIfMoreCompanyProjectsAvailable(false))
                        }
                        _.map(response.data.projects, project => {
                            setDataForProjectDetails(project)
                        })
                        if (query.offset > 0) {
                            dispatch(updateShowMoreProjectsForCompanyId(response.data))
                        } else {
                            dispatch(updateProjectsForCompanyId(response.data))
                        }
                    } else {
                        dispatch(updateProjectsForCompanyId([]))
                    }
                }
            })
    } catch (error) {
        handleError(error, dispatch)
    }
}