import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, InputGroup, Row } from 'reactstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { checkIfProjectIsNew, getProjectAddress, isValidText, getSectorNo, getFormatedDate } from '../../../utils';
import DetailCard from '../DetailCard';
import NavigationTab from './navigationTab';
import { favourite, fetchUserDetailsActivity, pushToChub, sendFeedback } from '../apiclient';
import ArchiveComponent from '../../archive';
import Icon from '../../common/js/icon';
import PlanningPortalBadge from '../../common/images/planningPortalBadge.png';
import { summaryActivityDetail, updateActivityIsClicked } from '../redux/reducer';
import { PROJECTDETAILS } from '../../../utils/tour'


const ProjectMain = (props) => {
    const location = useLocation().pathname;
    const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
    const [feedbackmodal, toggleFeedbackModal] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState('')
    const [summaryActivity, setSummaryActivity] = useState(null)
    const user = useSelector((state) => state.user.user);
    const userData = useSelector((state) => state.user);
    const project = useSelector((state) => state.project)
    const settings = useSelector((state) => state.settings)
    const dispatch = useDispatch();

    useEffect(() => {
        fetchUserDetailsActivity(location.split('/')[3], location.split('/')[2], dispatch)
    }, [])

    const toggleModal = () => {
        toggleFeedbackModal(!feedbackmodal)
        setFeedbackValue('')
    }

    const handleChange = (event) => {
        setFeedbackValue(event.target.value)
    }

    const handleFavourite = (event) => {
        event.preventDefault()
        let entity = {
            objectId: project.detailsPage.project_id,
            objectType: "project",
            favourite: project.detailsPage.favourite ? false : true,
            objectName: project.detailsPage.project_title,
            url: location,
        }
        favourite(entity, dispatch, project, userData, settings)
    }

    const handleSendFeedback = () => {
        let sectorDetails = getSectorNo(window.location)
        let description = `Sector:${sectorDetails && sectorDetails.sectorName}\nProduct:${sectorDetails && sectorDetails.productName}\nProject: ${project && project.detailsPage.project_id}\nDetails:${window.location.href}\nType: Feedback \n====================== \n ${feedbackValue}`;
        toggleFeedbackModal(false)
        let userNo = user.user_id
        let payload = {
            object_type: "project", object_id: project && project.detailsPage.project_id,
            user_id: userNo, description
        }
        sendFeedback(payload, dispatch)
    }

    const onArchiveIconClick = (e) => {
        e.preventDefault()
        setIsArchiveModalOpen(!isArchiveModalOpen)
    }

    const handleClose = () => {
        setIsArchiveModalOpen(false)
    }

    const showMoreTagsCount = () => {
        var Count = 0;
        var parentItem = document.getElementById("tag-lable-listing")
        var showMoreButton = document.getElementById("label-show-more")
        if (showMoreButton != null) {
            showMoreButton.classList.add("hide")
        }
        if (parentItem != undefined && parentItem.childNodes.length > 0) {
            var topOffset = parentItem.childNodes[0].offsetTop
            for (var i = 0; i < parentItem.childNodes.length; i++) {
                if (((parentItem.childNodes[i].classList + "").indexOf("labels-list-wrapper") == 0) && topOffset != parentItem.childNodes[i].offsetTop) {
                    break
                }
                else {
                    Count++
                }
            }
            if (parentItem != null && parentItem.childNodes[Count - 1] != undefined && showMoreButton != null) {
                showMoreButton.style.left = parentItem.childNodes[Count - 1].offsetLeft + parentItem.childNodes[Count - 1].offsetWidth + "px"
            }
            if (showMoreButton != null) {
                showMoreButton.classList.remove("hide")
            }
            return parentItem.childNodes.length - Count;
        }
        if (showMoreButton != null) {
            showMoreButton.classList.remove("hide")
        }
        return 0
    }

    useEffect(() => {
        showMoreTagsCount();
    }, [project && project.labelTagsDetail])

    const handlePushToChub = () => {
        pushToChub(project && project.detailsPage.project_id, dispatch)
    }

    const enablePrint = () => {
        props.handlePrint();
    }
    const handleSummary = (event, activityName) => {
        event.preventDefault()
        setSummaryActivity(activityName)
        dispatch(summaryActivityDetail(activityName))
        dispatch(updateActivityIsClicked(true))
    }
    return (
        <>
            <div className="project-overview mt-2">
                <Row className="justify-content-between project-info">
                    <Col lg="3" md="12" sm='12' className="ps-0">
                        <NavigationTab></NavigationTab>
                    </Col>
                    <Col lg="8" md='6' sm="12" className="project-details pe-0">
                        <h2><Icon color="#053874" size={40} icon="icon-gen-project-list" className="me-2 project-icon" />
                            <strong>{project && project.detailsPage && project.detailsPage.project_title}
                                {checkIfProjectIsNew(project && project.detailsPage && project.detailsPage.project_last_published, project && project.detailsPage && project.detailsPage.project_first_published) ?
                                    <div className="new-pro-flag ms-2">
                                        New
                                    </div> : ('')
                                }
                                {project && project.detailsPage && project && project.detailsPage.project_stopped_display ?
                                    <div className="project-build-phase-pro-flag on-hold-flag ms-2">{project && project.detailsPage && project && project.detailsPage.project_stopped_display}</div> :
                                    project && project.detailsPage && project && project.detailsPage.project_build_phase && (project && project.detailsPage && project && project.detailsPage.project_build_phase === 'on_site' || project && project.detailsPage && project && project.detailsPage.project_build_phase === "active_on_site") ?
                                        <div className="project-build-phase-pro-flag ms-2">
                                            {(project && project.detailsPage && project && project.detailsPage.project_build_phase === "active_on_site" ? "Confirmed On-site" : "Estimated On-site")}
                                        </div> : ('')
                                  
                                }
                            </strong>

                            {
                                isValidText(project?.detailsPage?.project_planning_portal_submitted) &&
                                    project?.detailsPage && new Date(`${project?.detailsPage?.project_last_published}`) >= new Date("2023-04-11") &&
                                    !project?.detailsPage?.project_geocode?.startsWith('SC') &&
                                    !project?.detailsPage?.project_geocode?.startsWith('WS') &&
                                    !project?.detailsPage?.project_geocode?.startsWith('EI') ? (
                                    <img
                                        className="planning-portal-badge"
                                        src={PlanningPortalBadge}
                                    />
                                ) : ("")
                            }
                        </h2>
                        {isValidText(project && project.detailsPage && project && project.detailsPage.project_site1) || isValidText(project && project.detailsPage && project && project.detailsPage.project_site2) || isValidText(project && project.detailsPage && project && project.detailsPage.project_site3) || isValidText(project && project.detailsPage && project && project.detailsPage.project_site4) || isValidText(project && project.detailsPage && project && project.detailsPage.project_postcode) ?
                            (<div className="address mb-2">
                                <span>Address: </span>
                                {getProjectAddress(project && project.detailsPage && project && project.detailsPage)}
                            </div>) : ('')}
                    </Col>

                </Row>

                <DetailCard></DetailCard>
                {project && project.labelTagsDetail ?
                    <Row className="tag-listing  mb-3">
                        <Col className="tag-listing-container px-0 d-flex">
                            <div className={`label-details-wrapper ${showMoreTagsCount() > 0 ? "show-less-lables" : ""}`} id="tag-lable-listing">
                                {
                                    project && project.labelTagsDetail && project && project.labelTagsDetail.map(function (label, index) {
                                        return (
                                            <span key={`label-list-${index}`} className={`${label.is_shared ? 'shared-labels-list-wrapper' : 'labels-list-wrapper'}`}>
                                                <a onClick={(e) => { handleSummary(e, "tags"); }} className={`${label.is_shared ? 'shared-labels-list' : 'labels-list'}`} id={`label-${index}`}>
                                                    <Icon color="#053874" size={15} icon="icon-gen-tag" className="me-1" /> {label.tag_name}
                                                </a>

                                            </span>)
                                    })
                                }
                            </div>
                            {(showMoreTagsCount() > 0) &&
                                <span id="label-show-more" onClick={(e) => { handleSummary(e, "tags"); }}>+{showMoreTagsCount()} more</span>
                            }
                        </Col>
                    </Row>
                    : ""}
                <Row className="d-flex justify-content-between pb-2">
                    <div className="pe-3 ps-0 action-button-list mt-2">
                        <Row className="label-sharing-cta justify-content-between">
                            <Col className="position-relative">
                                <Row className="justify-content-start">
                                    <Col className="d-inline-flex" xs="auto">
                                        <p className="mb-1 label-count-icon user-ctrl-btn" data-tooltip-id="my-tag" data-tooltip-hidden={!settings.preferences.hint_text}><Link onClick={(e) => { handleSummary(e, "tags"); }}><Icon color="#053874" size={21} icon="icon-gen-tag" /> {project && project.userActivityCount && project.userActivityCount.tags_count > 0 ? project && project.userActivityCount && project.userActivityCount.tags_count : ""}</Link></p>
                                        <p className="mb-1 share-count-icon user-ctrl-btn" data-tooltip-id="my-shares" data-tooltip-hidden={!settings.preferences.hint_text}><Link onClick={(e) => { handleSummary(e, "shares"); }}><Icon color="#053874" size={21} icon="icon-gen-share" />{project && project.userActivityCount && project.userActivityCount.shares_count > 0 ? project && project.userActivityCount && project.userActivityCount.shares_count : ""}</Link></p>
                                        <p className="mb-1 notes-count-icon user-ctrl-btn" data-tooltip-id="my-notes" data-tooltip-hidden={!settings.preferences.hint_text}><Link onClick={(e) => { handleSummary(e, "notes"); }}><Icon color="#053874" size={21} icon="icon-gen-notes" />{project && project.userActivityCount && project.userActivityCount.notes_count > 0 ? project && project.userActivityCount && project.userActivityCount.notes_count : ""}</Link></p>
                                        {user && user.licenceFlag && user.licenceFlag.isCalendarLicence === true ?
                                            <p className="mb-1 label-calendar-icon user-ctrl-btn" data-tooltip-id="my-calendar" data-tooltip-hidden={!settings.preferences.hint_text}><Link onClick={(e) => { handleSummary(e, "calendar_events"); }}><Icon color="#053874" size={21} icon="icon-gen-calendar" />{project && project.userActivityCount && project.userActivityCount.calendar_events_count > 0 ? project && project.userActivityCount && project.userActivityCount.calendar_events_count : ""}</Link></p>
                                            : ""}
                                        {
                                            ((project && project.detailsPage && project.detailsPage.mail) && (project && project.detailsPage && project.detailsPage.mailed_on)) ?
                                                (<p className="mb-1 mail-date-icon user-ctrl-btn" data-tooltip-id="Mailed-on" data-tooltip-hidden={!settings.preferences.hint_text}><Icon color="#053874" size={21} icon="icon-gen-email" />{getFormatedDate(project && project.detailsPage && project.detailsPage.mailed_on, 'DD-MMM-YYYY')}</p>)
                                                : ("")
                                        }

                                    </Col>
                                    <Col className="d-inline-flex">
                                        <a data-tooltip-id="favourite-project" data-tooltip-hidden={!settings.preferences.hint_text} onClick={handleFavourite} className='user-ctrl-btn label-favourite-icon'>
                                            <Icon color="#053874" size={21} icon={project && project.detailsPage && project.detailsPage.favourite ? 'icon-gen-star-active' : 'icon-gen-star-inactive'} />
                                        </a>
                                        {settings && settings.preferences && settings.preferences.archiving_enabled ?

                                            (<p className="mb-1 unarchive-icon user-ctrl-btn" data-tooltip-id={project && project.detailsPage && project.detailsPage.archive ? "unarchive" : "archive"} data-tooltip-hidden={!settings.preferences.hint_text}><Link onClick={(e) => onArchiveIconClick(e)}><Icon color="#053874" size={21}
                                                icon={project && project.detailsPage && project.detailsPage.archive ? "icon-gen-archive-active" : "icon-gen-archive-inactive"} />{isArchiveModalOpen === true ? <ArchiveComponent handleClose={handleClose} isArchiveModalOpen={isArchiveModalOpen} /> : ''}</Link></p>)

                                            : ('')
                                        }
                                    </Col>
                                </Row>
                                <div className="hori-placeholder-tour left-right-zero bottom-zero" data-intro={PROJECTDETAILS.useractivities.intro} data-position={PROJECTDETAILS.useractivities.position} data-class={PROJECTDETAILS.useractivities.class}>
                                    &nbsp;
                                </div>
                                {user && user.licenceFlag && user.licenceFlag.isCalendarLicence === true ?
                                    <ReactTooltip id="my-calendar" place="bottom" type='dark' effect="solid">
                                        <p className="tool-tip-sidebar">Calendar</p>
                                    </ReactTooltip>
                                    : ""}
                                <ReactTooltip id="favourite-project" place="bottom" type='dark' effect="solid">
                                    <p className="tool-tip-sidebar">Favourite</p>
                                </ReactTooltip>
                                <ReactTooltip id="my-tag" place="bottom" type='dark' effect="solid">
                                    <p className="tool-tip-sidebar">My Tags</p>
                                </ReactTooltip>
                                <ReactTooltip id="my-shares" place="bottom" type='dark' effect="solid">
                                    <p className="tool-tip-sidebar">My Shares</p>
                                </ReactTooltip>
                                <ReactTooltip id="my-notes" place="bottom" type='dark' effect="solid">
                                    <p className="tool-tip-sidebar">My Notes</p>
                                </ReactTooltip>
                                <ReactTooltip id="Mailed-on" place="bottom" type='dark' effect="solid">
                                    <p className="tool-tip-sidebar">Mailed On</p>
                                </ReactTooltip>
                                {
                                    project && project.detailsPage && project && project.detailsPage.archive ?
                                        <ReactTooltip id="unarchive" place="bottom" type='dark' effect="solid">
                                            <p className="tool-tip-sidebar">Unarchive</p>
                                        </ReactTooltip>
                                        :
                                        <ReactTooltip id="archive" place="bottom" type='dark' effect="solid">
                                            <p className="tool-tip-sidebar">Archive</p>
                                        </ReactTooltip>
                                }
                            </Col>
                            <Col className="d-inline-flex justify-content-end detail-action-buttons">
                                {user && user.licenceFlag && user.licenceFlag.isConstructionHubLicence === true ?
                                    <button className="user-ctrl-btn print-btn ml-2" onClick={handlePushToChub} data-intro={PROJECTDETAILS.printview.intro} data-position={PROJECTDETAILS.printview.position} data-class={PROJECTDETAILS.printview.class}><Icon color="#053874" size={21} icon="icon-gen-chub" className="me-1" /> Push to CHUB</button>
                                    : ''}
                                <button className="user-ctrl-btn print-btn ml-2" onClick={enablePrint} data-intro={PROJECTDETAILS.printview.intro} data-position={PROJECTDETAILS.printview.position} data-class={PROJECTDETAILS.printview.class}><Icon color="#053874" size={21} icon="icon-gen-print" className="me-1" />Print view</button>
                                <div className="feedback-cta ml-2" data-intro={PROJECTDETAILS.enquiry.intro} data-position={PROJECTDETAILS.enquiry.position} data-class={PROJECTDETAILS.enquiry.class}>
                                    <button className="user-ctrl-btn feedback-btn" onClick={toggleModal}><Icon color="#053874" size={21} icon="icon-gen-enquiry" className="me-1" />Project enquiry</button>
                                    <div className={`feedback-panel ${feedbackmodal ? 'show' : 'hide'}`}>
                                        <div className="note-list-wrapper">
                                            <InputGroup className="note-form">
                                                <div className="note-input">
                                                    <textarea maxLength="1834" rows="4" placeholder="Submit your enquiry to our research team" value={feedbackValue} onChange={handleChange}></textarea>
                                                </div>
                                            </InputGroup>
                                        </div>
                                        {feedbackValue.length <= 0 ?
                                            (<button className="primary-btn btn-send" disabled="disabled">Send</button>) :
                                            (<button className="primary-btn btn-send" onClick={handleSendFeedback}>Send</button>)}
                                        <button className="outline-btn btn-feedback-cancel" onClick={toggleModal}>Cancel</button>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div>
                </Row>
            </div>

        </>
    );
}

export default ProjectMain;