import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { checkAvailabilityOfLimit, checkAvailabilityOfOption, checkIfShowProjectsCompanies, selectorControlValue } from '../../../utils/commonFunctions'
import { MAX_PDF_DOWNLOAD_LIMIT } from '../../../utils/settings'
import Icon from '../../common/js/icon'
import ArchiveModal from './archiveModal'
import { checkIfTrialCustomerUserExportedDataForToday } from '../../../utils'
import { createDownloadPdfPayload } from '../../export/utils'
import { createNewExport } from '../../export/apiclient'
import { useNavigate } from 'react-router-dom'

const SearchSelector = (props) => {
    const exports = useSelector((state) => state.export)
    const searchPersist = useSelector((state) => state.searchPersist)
    const project = useSelector((state) => state.project)
    const user = useSelector((state) => state.user.user);
    const userData = useSelector((state) => state.user);
    const [dropdownOpen, setDropDownOpen] = useState(false)
    const [actionsDropDownOpen, setActionsDropDownOpen] = useState(false)
    const [defaultPageSize, setDefaultPageSize] = useState(25)
    const [isPdfActive] = useState(user.licenceFlag?.isExportLicence)
    const [isExportEnabled] = useState(checkIfTrialCustomerUserExportedDataForToday(exports?.exportList?.exports, user))
    const [isOpen, setIsOpen] = useState(false)
    const [isArchive, setIsArchive] = useState(false)
    const settings = useSelector((state) => state.settings.preferences)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropDownOpen(!dropdownOpen)
    }
    const handleItemSelect = (count) => {
        props.handleDropDownItemClick(count)
        setDropDownOpen(false)
    }

    const handleArchiveSelect = () => {
        setIsArchive(true)
        setIsOpen(true)
    }

    const handleUnArchiveSelect = () => {
        setIsArchive(false)
        setIsOpen(true)
    }

    const handleCancelArchive = () => {
        setIsOpen(false)
    }

    const handleArchive = () => {
        props.handleArchiveChangeSelection(isArchive)
        handleCancelArchive()
    }

    const handleUnArchive = () => {
        props.handleArchiveChangeSelection(isArchive)
        handleCancelArchive()
    }

    const getUniqueSelectedList = () => {
        let objectIds = []
        if ( props?.isRolesAndCompanies && project?.fetchRolesForProjects?.roleGroups) {
            project?.fetchRolesForProjects?.roleGroups.map(roleGroup => {
                roleGroup.roles.map(role => {
                    role.companies.map(company => {
                        if (company.is_selected == true) {
                            objectIds.push(company.company_id)
                        }
                    })
                })
            })
        }
        else if (props?.isProjectList && project?.projectDataForRoleId?.length > 0) {
            project?.projectDataForRoleId.map((entity, index) => {
                if (entity.is_selected) {
                    objectIds.push(entity.project_id)
                }
            })
        }
        else if (props?.isWorkWithCompanies && project?.worksWithCompanies?.companies?.length > 0) {
            project?.worksWithCompanies?.companies.map(roleGroup => {
                if (roleGroup.is_selected == true) {
                    objectIds.push(roleGroup.company_id)
                }
            })
        }

        else if (props?.isGroupCompanies && project?.groupCompanies && Object.keys(project?.groupCompanies)?.length > 0) {
            Object.keys(project?.groupCompanies).map((role, index) => {
                project?.groupCompanies[role].map((entity, index) => {
                    if (entity.is_selected) {
                        objectIds.push(entity.company_id)
                    }
                })
            })
        }
        else if (project?.isJointProjectViewed || project?.jointProjects?.projects?.length > 0) {
            project?.jointProjects?.projects.map((role, index) => {
                if (role.is_selected) {
                    objectIds.push(role.project_id)
                }
            })

        }
        else if (!props?.isProjectList && project?.companyRelatedCompanies?.length > 0) {
            project?.companyRelatedCompanies.map((role, index) => {
                if (role.is_selected) {
                    objectIds.push(role.company_id)
                }
            })

        }
        objectIds = [...new Set(objectIds)]
        return objectIds
    }

    const handleSelectorControlBatchPrintClick = () => {
        let objectIds = getUniqueSelectedList()
        let type =  (props?.isRolesAndCompanies && project?.fetchRolesForProjects?.roleGroups?.length > 0) || (project?.worksWithCompanies?.companies?.length > 0 && props?.isWorkWithCompanies) || (props?.isGroupCompanies && project?.groupCompanies && Object.keys(project?.groupCompanies)?.length > 0 )|| (project?.companyRelatedCompanies?.length > 0 && !props?.isProjectList) ? 'Company' : project?.projectDataForRoleId?.length > 0 || project?.jointProjects?.projects?.length > 0 ? 'Project' : searchPersist.appliedContext
        let feedname = type.toLowerCase() === "project" ? "Projects PDF" : "Companies PDF"
        let payload = createDownloadPdfPayload(feedname, objectIds, type, user)
        props.handleDropDownItemClick(0)
         createNewExport(payload, dispatch, navigate, userData)
    }

    const pagedDataRows = [1, 2, 3, 4, 5]
    let selectorControlLimit = null
    let pagedOptions = [];
    pagedDataRows.map(row => {
        if ((props.totalRows && props.totalRows) >= (defaultPageSize * row)) {
            pagedOptions.push((<DropdownItem onClick={() => handleItemSelect(defaultPageSize * row)} toggle={false} className={(defaultPageSize * row) == props.totalSelectedCount ? "active" : ""}> Top {defaultPageSize * row} </DropdownItem>))
        } else {
            pagedOptions.push((''))
        }
    })
    selectorControlLimit = selectorControlValue(props.entitiesCount)

    return (
        <div className="search-selector">
            <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="select-drop-count search-selector-position" >
                <DropdownToggle className={`${(props.totalSelectedCount > 0) ? 'active' : ''}`} caret>
                    {
                        props.totalSelectedCount > 0 ? (
                            <p> <Icon size={22} icon='icon-gen-tick-filled-skyblue' className="me-1 ms-2" /> {props.totalSelectedCount} selected</p>
                        ) : <Icon size={22} icon='icon-tick-filled-grey' className="me-1 ms-2" />
                    }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-right select-drop-list">
                    <DropdownItem onClick={() => handleItemSelect(0)} toggle={false} className={parseInt(props.totalSelectedCount) === 0 ? "active" : ""}><Icon size={22} icon={`${parseInt(props.totalSelectedCount) === 0 ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" /> None</DropdownItem>
                    {(props.activeTab != "history" && props.activeTab != "updates") ?
                        props.entitiesCount ? (
                            selectorControlLimit && selectorControlLimit.map((keyr, val) => (
                                keyr && Object.entries(keyr).map(([key, value]) => {
                                    return checkAvailabilityOfLimit(props.entitiesCount, key) ? <DropdownItem className={`${props.totalSelectedCount === key ? 'active' : ''}`} onClick={() => handleItemSelect(key)}> <Icon size={22} icon={`${props.totalSelectedCount === key ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />{value} </DropdownItem> : ""
                                })
                            ))) : ('')
                        : pagedOptions && pagedOptions.map(row => row)}
                    {checkAvailabilityOfOption(props.entitiesCount) ?
                        (<DropdownItem onClick={() => handleItemSelect(-1)} toggle={false} className={((props.activeTab === "history" || props.activeTab === "updates") && props.totalRows === props.totalSelectedCount) || (props.totalSelectedCount === props.entitiesCount) ? "active" : ""}> 
                        <Icon size={22} icon={`${((props.activeTab === "history" || props.activeTab === "updates") && props.totalRows === props.totalSelectedCount) || (props.totalSelectedCount === props.entitiesCount) ? 'icon-gen-tick-filled-skyblue' : 'icon-tick-filled-grey'}`} className="me-1 ms-2" />
                            All </DropdownItem>
                        ) : ('')}
                </DropdownMenu>
            </ButtonDropdown>
            {props.totalSelectedCount > 0 ? (
                <ButtonDropdown className="select-actions search-selector-position" isOpen={actionsDropDownOpen} toggle={() => { setActionsDropDownOpen(!actionsDropDownOpen) }}>
                    <DropdownToggle caret className="active">
                        <Icon size={20} icon='icon-gen-threedot-ver' />
                    </DropdownToggle>
                    {
                        props.isArchiveTab ?
                            (<DropdownMenu className="dropdown-menu-left action-list">
                                <DropdownItem onClick={handleUnArchiveSelect}>Unarchive</DropdownItem>
                            </DropdownMenu>) :
                            (<DropdownMenu className="dropdown-menu-left action-list">
                                {
                                    !props.isFavouriteTab ? (
                                        <DropdownItem onClick={() => props.handleFavChangeSelection('Favourite', props.totalSelectedCount)}>Favourite</DropdownItem>) :
                                        ('')
                                }
                                <DropdownItem onClick={() => props.handleFavChangeSelection('Unfavourite', props.totalSelectedCount)}>Unfavourite</DropdownItem>
                                <DropdownItem onClick={() => props.handleTagsSelection()}>Tags</DropdownItem>
                                {
                                        isPdfActive ? (
                                            parseInt(props.totalSelectedCount) <= 50 ?
                                                (
                                                    <DropdownItem disabled={!isExportEnabled} onClick={() => handleSelectorControlBatchPrintClick()}>Download PDF</DropdownItem>
                                                ) : (
                                                    <DropdownItem disabled={!isExportEnabled} className="disabled">Download PDF{(props.totalSelectedCount <= MAX_PDF_DOWNLOAD_LIMIT) ? "" : " (max " + MAX_PDF_DOWNLOAD_LIMIT + ")"}</DropdownItem>
                                                )
                                        ) : ('')
                                    }
                               
                                {
                                    props.isGroupCompanies ?
                                        (<DropdownItem onClick={() => props.handleShowGroupProjects()}>Show selected projects</DropdownItem>) : ('')
                                }
                                {
                                    props.isProjectList && settings?.archiving_enabled ?
                                        <DropdownItem onClick={handleArchiveSelect}>Archive</DropdownItem> : ""
                                }
                                {
                                    props.isProjectList && settings?.archiving_enabled ?
                                        <DropdownItem onClick={handleUnArchiveSelect}>Unarchive</DropdownItem> : ""
                                }
                            </DropdownMenu>
                            )
                    }
                </ButtonDropdown>) : ('')
            }
            <ArchiveModal
                isOpen={isOpen}
                isArchive={isArchive}
                multiple={props.totalSelectedCount && props.totalSelectedCount !== 1}
                handleArchive={handleArchive}
                handleUnArchive={handleUnArchive}
                handleCancelArchive={handleCancelArchive}
                isSelectorController={true}
            />
        </div>
    )
}

export default SearchSelector