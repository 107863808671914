import _ from 'lodash';
import getAxios from '../../utils/baseApi';
import { messageAlert } from "../../utils"
import { handleError } from '../../utils/errorHandler';
import { fetchAllRecipients, fetchShareTeams } from '../login/apiclient';
import { updateSelectedTeamId, updateTeamAlreadyExists, updateTeamMemberAdded } from './reducer';
var axios = getAxios()

export function addNewTeams(payload, userData, dispatch, user) {
    var jsonData = JSON.stringify(payload)
    axios.post('/teams', jsonData)
        .then(function (response) {
            fetchShareTeams(dispatch, userData);
            if (response.status === 201 && response.data.isDuplicate !== 'true') {
                messageAlert(dispatch, response.data.message, 'success')
                dispatch(updateTeamAlreadyExists(false))
                if (!user.isMasqueradeUser) {
                    window.Intercom('trackEvent', 'Create team', { 'team_name': payload.team_name })
                }
            }
            else {
                dispatch(updateTeamAlreadyExists(true))
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function editTeamMember(payload, userData, dispatch) {
    var jsonData = JSON.stringify(payload)
    axios.put(`/teams/${payload.teamno}`, jsonData)
        .then(function (response) {
            fetchShareTeams(dispatch, userData);
            if (response.data.isDuplicate !== 'true') {
                messageAlert(dispatch, response.data.message, 'success')
                dispatch(updateTeamAlreadyExists(false))
                dispatch(updateSelectedTeamId(null))
            }
            else {
                dispatch(updateSelectedTeamId(payload['teamno']))
                dispatch(updateTeamAlreadyExists(true))
            }
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function addMemberToTeam(payload, userData, dispatch) {
    var jsonData = JSON.stringify(payload)
    axios.post(`/teams/${payload.team_no}/users/${payload.user_no}`)
        .then(function (response) {
            fetchShareTeams(dispatch, userData);
            messageAlert(dispatch, `User  "${payload['member_name']}" added successfully`, 'success')
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}

export function removeMemberFromTeam(payload, userData, dispatch) {
    axios.delete(`/teams/${payload.team_no}/users/${payload.user_no}`)
        .then(function (response) {
            fetchShareTeams(dispatch, userData);
            messageAlert(dispatch, `User "${payload['member_name']}"  deleted successfully`, 'success')
        })
        .catch(function (error) {
            handleError(error, dispatch)
        })
}