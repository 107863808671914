import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import {
    Button, Input, ModalHeader, Modal, ModalFooter, Dropdown, DropdownMenu, DropdownItem,
    DropdownToggle, Label, Col, Row
} from 'reactstrap';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { EVENT_TYPE, EVENT_PRIORITY, EVENT_STATUS, EVENT_REMINDER } from '../../../utils/settings'
import { capitalizeFirstLetter } from '../../../utils/commonFunctions';
import { addEvent, updateEvent } from "../../details/apiclient";


const { utcToZonedTime } = require("date-fns-tz");
const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
};

const CalendarModal = (props) => {
    const [subject, setSubject] = useState('')
    const [description, setDescription] = useState('')
    const [location, setLocation] = useState('')
    const [object_type, setObjectType] = useState(null)
    const [object_id, setObjectId] = useState(null)
    const [dropDownOpen, setDropDownOpen] = useState({ typeDropdown: false, priorityDropdown: false, statusDropdown: false })
    const [dropDownValueType, setDropDownValueType] = useState(EVENT_TYPE[0].label)
    const [dropDownValuepriority, setDropDownValuepriority] = useState(EVENT_PRIORITY[1])
    const [dropdownValueStatus, setDropdownValueStatus] = useState(EVENT_STATUS[0].label)
    const [dropdownValueReminder, setDropdownValueReminder] = useState(EVENT_STATUS[4].label)
    const [startDate, setStartDate] = useState(new Date().setHours(8, 30, 0))
    const [endDate, setEndDate] = useState(new Date().setHours(9, 30, 0))
    const [isDisableReminder, setIsDisableReminder] = useState(false)
    const [isDisableNotification, setIsDisableNotification] = useState(false)
    const [notificationbyemail, setNotificationbyemail] = useState(true)
    const [reminder, setReminder] = useState('')
    const [subjectError, setSubjectError] = useState('')
    const [dateError, setDateError] = useState('')
    const [eventId, setEventId] = useState(0)
    const [currentStartDateValid, setCurrentStartDateValid] = useState(true)
    const [currentEndDateValid, setCurrentEndDateValid] = useState(true)
    const [reminder_val, setReminderVal] = useState(15)

    const dispatch = useDispatch();
    const linkLocation = useLocation().pathname;
    const userData = useSelector(state=> state.user.user)
    const user = useSelector(state=> state.user)
    const calendarEventState = useSelector(state => state.calendar)

    useEffect(() => {

        if (props?.isEditEvent) {
            handleEdit(props?.eventDetails)
        } else {
            resetInput()
        }
    }, [props])

    const resetInput = () => {
        setEventId(0)
        setSubject('')
        setDescription('')
        setLocation('')
        setStartDate(new Date().setHours(8, 30, 0))
        setEndDate(new Date().setHours(9, 30, 0))
        setDropDownValueType(EVENT_TYPE[0].label)
        setDropDownValuepriority(EVENT_PRIORITY[1])
        setDropdownValueStatus(EVENT_STATUS[0].label)
        setNotificationbyemail(true)
        setIsDisableNotification(false)
        setIsDisableReminder(false)
        setDropdownValueReminder(EVENT_REMINDER[4].label)
        setSubjectError('')
        setDateError('')
        setCurrentEndDateValid(true)
        setCurrentStartDateValid(true)
        setReminder('')
        setReminderVal(15)
        setObjectType(linkLocation.split('/')[2])
        setObjectId(linkLocation.split('/')[2]==="company"?linkLocation.split('/')[4]:linkLocation.split('/')[3])
    }

    const handleEdit = (event) => {
        let currentDate = moment(new Date())
        let start = event.start_on ? moment(event.start_on) : event.end_on ? moment(event.end_on) : "";
        let duration = event.reminder_on ? start.diff(moment(event.reminder_on), 'minutes') : 'none';
        setSubjectError('')
        setDateError('')
        if (event) {
            setSubject(event.subject)
            setDescription(event.description ? event.description : "")
            setLocation(event.location ? event.location : "")
            setDropDownValueType(EVENT_TYPE[EVENT_TYPE.findIndex(p => p?.label === capitalizeFirstLetter(event.type))]?.label)
            setDropDownValuepriority(EVENT_PRIORITY[EVENT_PRIORITY.indexOf(capitalizeFirstLetter(event.priority))])
            setDropdownValueStatus(EVENT_STATUS[EVENT_STATUS.findIndex(p => p?.label === capitalizeFirstLetter(event.status))]?.label)
            setDropdownValueReminder(event.reminder_on ? EVENT_REMINDER[EVENT_REMINDER.findIndex(object => { return object.value === (duration).toString() })]?.label : EVENT_REMINDER[0]?.label)
            setStartDate(event.start_on ? utcToZonedTime(event.start_on, 'UTC') : null)
            setEndDate(event.end_on ? utcToZonedTime(event.end_on, 'UTC') : null)
            setEventId(event.event_id)
            setReminder(event.reminder_on ? utcToZonedTime(event.reminder_on, 'UTC') : "none")
            setNotificationbyemail(event.notificationbyemail ? event.notificationbyemail : false)
            setIsDisableReminder(start ? moment(start).isSameOrAfter(currentDate) ? false : true : true)
            setIsDisableNotification(event.reminder_on && !(start ? moment(start).isSameOrAfter(currentDate) ? false : true : true) ? false : true)
            setReminderVal(event.reminder_on && event.reminder_on !== 'none' ? duration : 'none')
            setObjectId(event.object_id)
            setObjectType(event.object_type)
        }
    }

    const toggleDropdown = (value) => {
        setDropDownOpen({ ...dropDownOpen, [value]: !dropDownOpen[value] })
    }

    const handleDropdownLabel = (type, value, keyvalue) => {
        if (type === 'typeDropdown') {
            setDropDownValueType(value)
        }
        else if (type === 'priorityDropdown') {
            setDropDownValuepriority(value)
        }
        else if (type === 'statusDropdown') {
            setDropdownValueStatus(value)
        }
        else if (type === 'reminderDropdown') {
            setDropdownValueReminder(value)
            setReminder(setReminderValue(keyvalue))
            if (value === 'None') {
                setIsDisableNotification(true)
                setNotificationbyemail(false)
            }
            else {
                setIsDisableNotification(false)
                setNotificationbyemail(true)
            }
        }

    }

    const handleSubmit = () => {
        setSubjectError('')
        setDateError('')
        let reminder_val = "";
        if (((startDate || endDate) || (endDate && startDate === null)) && dropdownValueReminder === '15 mins before') {
            reminder_val = setReminderValue(15);
        }
        else if (dropdownValueReminder === 'None') {
            reminder_val = ""
        }
        let formValid = false;
        let isDateRangeValid = true;
        let payload = {
            subject: subject,
            description: description ? description : "",
            location: location ? location : "",
            object_type: object_type,
            object_id: object_id,
            start_on: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : "",
            end_on: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : "",
            reminder_on: reminder && reminder !== 'none' ? moment(reminder).format('YYYY-MM-DD HH:mm:ss') : reminder_val,
            type: dropDownValueType,
            priority: dropDownValuepriority,
            status: dropdownValueStatus,
            notificationbyemail: notificationbyemail
        }
     
        if (payload.start_on || payload.end_on) {
            isDateRangeValid = checkDateValidation(payload.start_on, payload.end_on)
        }
        formValid = formValidation() && isDateRangeValid && (currentEndDateValid && currentStartDateValid)

        if (formValid) {
            if (eventId !== 0) {
                props?.prevNextList === "calendarPage" ? updateEvent(payload, eventId, userData, dispatch, calendarEventState) : updateEvent(payload, eventId, userData, dispatch)
                toggleModal()
                setEventId(0)
                setCurrentEndDateValid(true)
                setCurrentStartDateValid(true)
                setReminder('')
                setReminderVal(15)
            } else {
                addEvent(payload, user, dispatch)
                toggleModal()
                setReminder('')
                setReminderVal(15)
            }
        }
    }

    const handleOnClose = () => {
        setReminder(setReminderValue(reminder_val))
    }

    const handleDelete = () => {
        props.handleDelete({ eventId, object_id, object_type })
    }

    const clearDate = (dateToClear) => {
        if (dateToClear === 'startDate') {
            setStartDate(null)
            setCurrentStartDateValid(true)
            if (endDate === "" || endDate == null || endDate === undefined) {
                setIsDisableNotification(true)
                setIsDisableReminder(true)
                setDropdownValueReminder(EVENT_REMINDER[0].label)
                setNotificationbyemail(false)
            }
        }
        else {
            setEndDate(null)
            setCurrentEndDateValid(true)
            if (startDate === "" || startDate === null || startDate === undefined) {
                setIsDisableNotification(true)
                setIsDisableReminder(true)
                setDropdownValueReminder(EVENT_REMINDER[0].label)
                setNotificationbyemail(false)
            }
        }
        setTimeout(() => {
            setReminder(setReminderValue(reminder_val))
        }, 200);
    }


    const handleStartDateChange = (startdate) => {
        let currentDate = moment(new Date())
        let endDateVal = new Date(startdate);
        endDateVal.setHours(endDateVal.getHours() + 1);
        if (startdate) {
            setIsDisableReminder(false)
            setCurrentStartDateValid(moment(startdate).isValid() && moment(startdate).isSameOrAfter(currentDate) ? true : false)
            setIsDisableNotification(false)
            setCurrentEndDateValid(moment(endDateVal).isValid() && moment(endDateVal).isSameOrAfter(currentDate) ? true : false)
            setStartDate(startdate)
            setEndDate(endDateVal)
        }
        else {
            setStartDate(null)
            setIsDisableReminder(true)
            setIsDisableNotification(true)
            setCurrentStartDateValid(true)
        }
    }

    const handleEndDateChange = (endDate) => {
        let currentDate = moment(new Date())
        if (endDate) {
            setEndDate(endDate)
            setIsDisableReminder(false)
            setIsDisableNotification(false)
            setCurrentEndDateValid(moment(endDate).isValid() && moment(endDate).isSameOrAfter(currentDate) ? true : false)
        }
        else {
            setEndDate(null)
            setIsDisableReminder(true)
            setIsDisableNotification(true)
            setCurrentEndDateValid(true)
        }
    }

    const handleNotification = (event) => {
        setNotificationbyemail(event.target.checked)
    }

    const checkDateValidation = (start, end) => {
        var mStart = start !== '' ? moment(start) : '';
        var mEnd = end !== '' ? moment(end) : '';
        let isValid = true;
        if ((mStart !== '' && mEnd !== '' && !mStart.isBefore(mEnd)) && (mStart !== '' && mEnd !== '' && !mStart.isSame(mEnd))) {
            isValid = false;
            setDateError("Select correct date range and time")
        }
        if (eventId === 0) {
            var currentDate = moment(new Date())
            if (mStart !== '' && !mStart.isSameOrAfter(currentDate)) {
                isValid = false;
                setCurrentStartDateValid(false)
            } else if (mEnd !== '' && !mEnd.isSameOrAfter(currentDate)) {
                isValid = false;
                setCurrentEndDateValid(false)
            }
        }
        return isValid;
    }

    const handleInputChange = (event) => {
        if (event.target.name === 'subject') {
            setSubject(event.target.value)
            setTimeout(() => {
                formValidation()
            }, 100);
        } else if (event.target.name === 'description') {
            setDescription(event.target.value)
        } else {
            setLocation(event.target.value)
        }
    }

    const formValidation = () => {
        let isValidate = true;
        if (subject.trim().length === 0 || subject.trim() === "") {
            isValidate = false;
            setSubjectError("This field is required")
        }
        else {
            isValidate = true;
            setSubjectError("")
        }
        return isValidate;
    }

    const setReminderValue = (reminderVal) => {
        let eventStartDate;
        let reminderBefore;
        setReminder('')
        setReminderVal(reminderVal)
        if (reminderVal !== 'none') {
            if (startDate !== undefined && startDate !== null && startDate !== "") {
                eventStartDate = startDate;
            }
            else if ((startDate === undefined || startDate === "" || startDate === null) && (endDate !== undefined && endDate !== null && endDate !== "")) {
                eventStartDate = endDate;
            }
            reminderBefore = moment(eventStartDate).subtract(parseInt(reminderVal), 'minutes').format('YYYY-MM-DD HH:mm:ss');
        }
        else {
            reminderBefore = "";
        }
        return reminderBefore;
    }

    const toggleModal = () => {
        props.toggleModal()
        setCurrentEndDateValid(true)
        setCurrentStartDateValid(true)
    }

    return (
        <Modal isOpen={props.isOpen} toggle={toggleModal} id="addEeventsModal">
            <ModalHeader className="blueHeaderBg mb-3">
                {!props.isEditEvent ? ('Add Events') : ('Edit Event')}
                <button type="button" className="close" aria-label="Close" onClick={() => { toggleModal() }}><span aria-hidden="true">×</span></button>
            </ModalHeader>
            <div className="addEventFormCnt">
                <form>
                    <div className="event-form">
                        <Row className="mb-3">
                            <Col className="event-input mb-3" md="12">
                                <label>Subject</label>
                                <Input type="text" name="subject" value={subject} maxLength="60" onChange={handleInputChange} />
                                {subjectError ? <p className="error-text">{subjectError}</p> : ""}
                            </Col>
                            <Col className="event-input mb-3" md="12">
                                <label>Description <span>(optional)</span></label>
                                <Input type="textarea" value={description} name="description" maxLength="4000" onChange={handleInputChange} />
                            </Col>
                            <Col className="event-input mb-3" md="8">
                                <label>Location <span>(optional)</span></label>
                                <Input type="text" value={location} name="location" maxLength="60" onChange={handleInputChange} />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="event-input">
                                <label>Type</label>
                                <Dropdown isOpen={dropDownOpen['typeDropdown']} toggle={() => toggleDropdown('typeDropdown')}>
                                    <DropdownToggle caret>{dropDownValueType}</DropdownToggle>
                                    <DropdownMenu>
                                        {EVENT_TYPE.map((optionItem) => {
                                            return <DropdownItem onClick={() => handleDropdownLabel('typeDropdown', optionItem.label)} >{optionItem.label}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col className="event-input">
                                <label>Priority</label>
                                <Dropdown isOpen={dropDownOpen['priorityDropdown']} toggle={() => toggleDropdown('priorityDropdown')}>
                                    <DropdownToggle caret>{dropDownValuepriority}</DropdownToggle>
                                    <DropdownMenu>
                                        {EVENT_PRIORITY.map((optionItem) => {
                                            return <DropdownItem onClick={() => handleDropdownLabel('priorityDropdown', optionItem)} >{optionItem}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                            <Col className="event-input">
                                <label>Status</label>
                                <Dropdown isOpen={dropDownOpen['statusDropdown']} toggle={() => toggleDropdown('statusDropdown')}>
                                    <DropdownToggle caret>{dropdownValueStatus}</DropdownToggle>
                                    <DropdownMenu>
                                        {EVENT_STATUS.map((optionItem) => {
                                            return <DropdownItem onClick={() => handleDropdownLabel('statusDropdown', optionItem.label)} >{optionItem.label}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>

                        <div className="col-md-12 event-input">
                            <label>Timing <span>(optional)</span></label>
                        </div>
                        <Row className="mb-3">
                            <Col className="event-input" md="4">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => handleStartDateChange(date)}
                                    onCalendarClose={handleOnClose}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    className="startDate"
                                    minDate={new Date()}
                                    filterTime={filterPassedTime}
                                    placeholderText="dd-MMM-yyyy HH:mm"
                                    dateFormat="dd-MMM-yyyy HH:mm" />
                                {startDate !== null ? <button className="clearButton" onClick={() => clearDate('startDate')}></button> : ("")}
                                {!currentStartDateValid ? <p className="error-text">Enter correct start date</p> : ("")}
                            </Col>

                            <Col className="event-input" md="4">
                                <div className="auto-width event-input align-self-center">
                                    <label>To</label>
                                </div>
                                <DatePicker selected={endDate}
                                    onChange={(date) => handleEndDateChange(date)}
                                    onCalendarClose={handleOnClose}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    className="endDate"
                                    minDate={new Date()}
                                    filterTime={filterPassedTime}
                                    placeholderText="dd-MMM-yyyy HH:mm"
                                    dateFormat="dd-MMM-yyyy HH:mm" />
                                {endDate !== null ? <button className="clearButton" onClick={() => clearDate('endDate')}></button> : ("")}
                                {!currentEndDateValid ? <p className="error-text">Enter correct end date</p> : ("")}
                            </Col>
                            <Col md="4">&nbsp;</Col>
                            {dateError ?
                                <div className="col-md-12 mt-1">
                                    <p className="error-text">{dateError}</p>
                                </div>
                                : ("")}

                        </Row>
                        <Row className="mb-3">
                            <Col className="event-input" md="4">
                                <label>Reminder <span>(optional)</span></label>
                                <Dropdown isOpen={dropDownOpen['reminderDropdown']} disabled={isDisableReminder} toggle={() => toggleDropdown('reminderDropdown')}>
                                    <DropdownToggle caret>{dropdownValueReminder ?
                                        dropdownValueReminder : EVENT_REMINDER[4].label}</DropdownToggle>
                                    <DropdownMenu>
                                        {EVENT_REMINDER.map((optionItem) => {
                                            return <DropdownItem key={optionItem.value} onClick={() => handleDropdownLabel('reminderDropdown', optionItem.label, optionItem.value)}>{optionItem.label}</DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="event-input">
                                <label>Notifications</label>
                                <div className="check-box-button">
                                    <Input
                                        id={'get-notified-by-email'}
                                        type="checkbox"
                                        checked={notificationbyemail}
                                        disabled={isDisableNotification}
                                        onChange={handleNotification}
                                    />
                                    <Label className="form-check-label" for={'get-notified-by-email'}>Get notified by email</Label>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </form>
            </div>
            <ModalFooter className="d-flex justify-content-between">
                <div className="d-inline-flex justify-content-start">
                    <Button id="primaryBtn" className="primary-btn btn-save-cancel me-3" type="submit" onClick={handleSubmit} autoFocus={true}>Save</Button>
                    <Button className="outline-btn btn-save-cancel" onClick={() => { toggleModal() }}>Cancel</Button>
                </div>
                {props.isEditEvent ? <Button className="outline-btn btn-delete" onClick={handleDelete}>Delete</Button> : ('')}

            </ModalFooter>
        </Modal>
    )
}
export default CalendarModal;